import React, { ReactElement, useEffect, useState } from 'react';
import { RiExternalLinkFill, RiCloseFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Button from '../../../../../../components/Button';
import { customerService } from '../../../../../../services';
import { CustomerResponseFull } from '../../../../../../types/customer';
import { getErrorMessage } from '../../../../../../utils/errorCodes';
import { formatCPF, formatDateWithHour, formatPhone } from '../../../../../../utils/formatters';

import {
  Container,
  Content,
  ContentTitle,
  ContentLeft,
  Datas,
  ItemDatas,
  Divider,
  ContentRight,
  BoxImages,
  Box,
  Image,
  Status,
  LastStatus,
  LastUpdate,
} from './styles';

interface Props {
  customerId: number;
}

const CustomerData = ({ customerId }: Props): ReactElement => {
  const [customer, setCustomer] = useState<CustomerResponseFull>();
  const user = customer?.attributes?.user?.data?.attributes;
  const address = customer?.attributes?.address?.data?.attributes;
  const customerReview = customer?.attributes?.customerReview?.data?.attributes;
  const frontDocument = customer?.attributes?.frontDocument?.data?.attributes;
  const backDocument = customer?.attributes?.backDocument?.data?.attributes;
  const selfie = customer?.attributes?.selfie?.data?.attributes;

  // Redirect to datailed version in a new tab
  function handleAttachDetail(url?: string): void {
    window.open(url);
  }

  useEffect((): void => {
    async function fetchData(): Promise<void> {
      try {
        const { data } = await customerService.getOne(customerId);
        setCustomer(data);
      } catch (err: any) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Content>
        <ContentLeft>
          <ContentTitle>Dados do cliente</ContentTitle>
          <Datas>
            <ItemDatas>
              <strong>Nome completo: </strong>
              <p>{customer?.attributes?.user?.data?.attributes?.name}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>CPF: </strong>
              <p>{formatCPF(customer?.attributes?.user?.data?.attributes?.cpf)}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Telefone: </strong>
              <p>{formatPhone(customer?.attributes?.user?.data?.attributes?.phone)}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>E-mail: </strong>
              <p>{user?.email}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>CEP: </strong>
              <p>{address?.postalCode}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Endereço: </strong>
              <p>{address?.street}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Número: </strong>
              <p>{address?.number}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Complemento: </strong>
              <p>{address?.complement}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Bairro: </strong>
              <p>{address?.neighborhood}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Cidade: </strong>
              <p>{address?.city}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Estado: </strong>
              <p>{address?.state}</p>
            </ItemDatas>
          </Datas>
        </ContentLeft>
        <Divider />
        <ContentRight>
          <ContentTitle>Anexos</ContentTitle>
          <BoxImages>
            <Box>
              <p>Selfie com documento:</p>
              <Image>
                <img
                  src={selfie?.formats?.thumbnail?.url}
                  alt="background-black"
                />
                <Button
                  type="button"
                  message="Abrir anexo"
                  icon={<RiExternalLinkFill />}
                  onClick={() => handleAttachDetail(selfie?.url)}
                />
              </Image>
            </Box>
            <Box>
              <p>Frente do documento com foto:</p>
              <Image>
                <img
                  src={frontDocument?.formats?.thumbnail?.url}
                  alt="background-black"
                />
                <Button
                  type="button"
                  message="Abrir anexo"
                  icon={<RiExternalLinkFill />}
                  onClick={() => handleAttachDetail(frontDocument?.url)}
                />
              </Image>
            </Box>
            <Box>
              <p>Verso do documento com foto:</p>
              <Image>
                <img
                  src={backDocument?.formats?.thumbnail?.url}
                  alt="background-black"
                />
                <Button
                  type="button"
                  message="Abrir anexo"
                  icon={<RiExternalLinkFill />}
                  onClick={() => handleAttachDetail(backDocument?.url)}
                />
              </Image>
            </Box>
          </BoxImages>
        </ContentRight>
      </Content>
      <Status>
        <LastStatus>
          Cliente cadastrado em:
          {' '}
          {formatDateWithHour(customer?.attributes?.createdAt)}
        </LastStatus>
        <LastUpdate>
          Atualizado em:
          {' '}
          {formatDateWithHour(customerReview?.updatedAt)}
        </LastUpdate>
      </Status>
    </Container>
  );
};

export default CustomerData;
