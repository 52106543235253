import { css } from 'styled-components';

export const titleHeadingXXL = css`
  font-weight: 600;
  font-size: 4.2rem;
  line-height: 4.2rem;
  letter-spacing: -1.3px;
`;

export const titleHeadingXXS = css`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

export const titleHeadingXL = css`
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.4rem;
  letter-spacing: -1px;
`;

export const titleHeadingLG = css`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: -0.8px;
`;

export const bodyParagraphXL = css`
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.4rem;
  letter-spacing: -0.2px;
`;

export const bodyParagraphLG = css`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  letter-spacing: 0;
`;

export const bodyParagraphMD = css`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0;
`;

export const bodyParagraphSM = css`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.2rem;
  letter-spacing: 0;
`;

export const bodyParagraphXS = css`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0;
`;

export const bodyParagraphXXS = css`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0;
`;

export const buttonTextMD = css`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0;
`;

export const buttonTextSM = css`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.1px;
`;

export const buttonTextXS = css`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: -0.1px;
`;

export const alternativeTextXXS = css`
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 1.4px;
  line-height: 1.7rem;
  text-transform: uppercase;
`;

export const alternativeTextXXXS = css`
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 1.4px;
  line-height: 1.7rem;
  text-transform: uppercase;
`;
