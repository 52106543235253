import styled, { css } from 'styled-components';

export const EmptyBox = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 501px;
    margin: 0 auto;
    text-align: center;
    padding: 54px 0;

    > img {
      width: 100%;
      max-width: 175px;
    }

    > h1 {
      color: #000000;
      font-size: 18px;
      margin: 32px 0 6px;

      ${theme.texts.buttonTextMD};

      @media (max-width: 480px) {
        max-width: 238px;
        margin: 36px auto 6px;
      }
    }

    > p {
      ${theme.texts.bodyParagraphMD};

      @media (max-width: 480px) {
        max-width: 238px;
        margin: 0 auto;
      }
    }
  `}
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    > p {
      margin: 0 18px;

      ${theme.texts.bodyParagraphMD};

      @media (max-width: 480px) {
        display: none;
      }
    }

    > button {
      &:first-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }

      @media (max-width: 480px) {
        &:last-child {
          display: none;
        }
      }
    }
  `}
`;
