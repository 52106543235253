const tableHeader: any[] = [
  {
    id: 1,
    name: 'Cliente',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 2,
    name: 'Valor da solicitação',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 3,
    name: 'Parcelamento',
    width: '15%',
    align: 'left',
    ordened: false,
  },
  {
    id: 4,
    name: 'Status',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 5,
    name: 'Data da criação',
    width: '17%',
    align: 'left',
    ordened: false,
  },
  {
    id: 6,
    name: 'Ações',
    width: '8%',
    align: 'center',
    ordened: false,
  },
];

const tableBody: any[] = [
  {
    id: 0,
    value: 'João Teste da Silva',
    parc: 'R$ 6.000,00',
    phone: '12x de R$ 500,00',
    status: 'pendingAnalysis',
    venc: '03/03/2022 às 14:03',
  },
  {
    id: 1,
    value: 'Juliano Teste da Silva',
    parc: 'R$ 4.000,00',
    phone: '2x de R$ 2.000,00',
    status: 'paid',
    venc: '05/03/2022 às 09:12',
  },
  {
    id: 2,
    value: 'André Teste da Silva',
    parc: 'R$ 3.000,00',
    phone: '10x de R$ 300,00',
    status: 'denied',
    venc: '08/05/2022 às 12:54',
  },
];

const requestStatus = {
  rejected: 'Negada',
  approved: 'Aprovada',
  pending: 'Análise pendente',
  liquidated: 'Paga',
};

export {
  requestStatus,
  tableHeader,
  tableBody,
};
