import React, { createContext, useEffect, useState } from 'react';

import {
  Route, Routes, useLocation, Outlet,
} from 'react-router-dom';

import {
  RiNotification3Line,
  RiCalendarLine,
  RiArrowUpDownLine,
} from 'react-icons/ri';
import { StepsProvider } from 'react-step-builder';
import { toast } from 'react-toastify';
import NavbarMobile from '../../components/NavbarMobile';
import { useAuth } from '../../contexts/AuthContext';

import SideBar from '../../components/SideBar';

import {
  Container,
  Content,
  Header,
  HeaderLeft,
  HeaderRight,
  Divider,
  MyPerson,
} from './styles';
import Request from './Operator/Request';

import { dateOptions, todayDate, routeSectionTitle } from './dashboard.spec';
import { orderService, tosVersionService, userService } from '../../services';
import { UserCore, UserResponse } from '../../types/user';
import RequestModal from '../../components/RequestModal';
import SimulatorModal from '../../components/SimulatorModal';
import AlertModal from '../../components/AlertModal';
import { errorCodes, getErrorMessage } from '../../utils/errorCodes';
import Loading from '../../components/Loading';
import TermsOfUseModal from '../../components/TermsOfUseModal';
import { BillDDAResponse, DDAResponse } from '../../types/dda';
import AxiosInterceptor from '../../utils/AxiosInterceptors';
import { CountResponse } from '../../types/core';

export const DashboardContext = createContext({} as DashboardContextValue);

interface DashboardContextValue {
  isOpenMenu: boolean;
  setIsOpenMenu: React.Dispatch<boolean>;
  isOpenSimulator: boolean;
  setIsOpenSimulator: React.Dispatch<boolean>;
  isOpenRequest: boolean;
  setIsOpenRequest: React.Dispatch<boolean>;
  updateTableSignal: Record<string, unknown>;
  updateTable: () => void;
  isOpenOnlineModal: boolean;
  setIsOpenOnlineModal: React.Dispatch<boolean>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<boolean>;
  isOpenGridBoletos: boolean;
  setIsOpenGridBoletos: React.Dispatch<boolean>;
  countDDA: number;
  setCountDDA: React.Dispatch<number>;
  isOpenTOSModal: boolean;
  setIsOpenTOSModal: React.Dispatch<boolean>;
}

const Dashboard: React.FC = () => {
  const currentRouterLocation = useLocation()?.pathname;
  const [toggled, setToggled] = useState<boolean>(false);
  const { user } = useAuth();

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenSimulator, setIsOpenSimulator] = useState<boolean>(false);
  const [isOpenRequest, setIsOpenRequest] = useState<boolean>(false);
  const [isOpenOnlineModal, setIsOpenOnlineModal] = useState<boolean>(false);
  const [isOpenGridBoletos, setIsOpenGridBoletos] = useState<boolean>(false);
  const [isOpenTOSModal, setIsOpenTOSModal] = useState<boolean>(false);

  const [updateTableSignal, setUpdateTableSignal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countDDA, setCountDDA] = useState<number>(0);

  function updateTable(): void {
    setUpdateTableSignal({});
  }

  const handleOpen = async (): Promise<void> => {
    try {
      await orderService.verifyHour();
      setIsOpenRequest(true);
    } catch (err: any) {
      if (err.response?.data?.error?.details?.serverCode === 'OFF_BUSINESS_HOURS') {
        setIsOpenOnlineModal(true);
      } else if (!err.intercepted) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
      }
    }
  };

  function handleModalClose(): void {
    document.body.style.overflow = 'auto';
    setIsOpenOnlineModal(false);
  }

  const name = user?.name;

  return (
    <Container>
      <DashboardContext.Provider
        value={{
          isOpenMenu,
          setIsOpenMenu,
          isOpenSimulator,
          setIsOpenSimulator,
          isOpenRequest,
          setIsOpenRequest,
          updateTableSignal,
          updateTable,
          isOpenOnlineModal,
          setIsOpenOnlineModal,
          isLoading,
          setIsLoading,
          isOpenGridBoletos,
          setIsOpenGridBoletos,
          countDDA,
          setCountDDA,
          isOpenTOSModal,
          setIsOpenTOSModal,
        }}
      >
        <AxiosInterceptor>
          <NavbarMobile handleToggleSimulator={() => setIsOpenSimulator(true)} />

          <SideBar toggled={toggled} handleToggled={() => setToggled(!toggled)} />
          <Content toggled={toggled}>
            <Header>
              <HeaderLeft>
                <h2>
                  {
                  routeSectionTitle[
                    currentRouterLocation as keyof typeof routeSectionTitle
                  ]
                }
                </h2>
              </HeaderLeft>
              <HeaderRight>
                <RiArrowUpDownLine />
                {/* <button type="button">
                  <RiNotification3Line />
                </button> */}
                <span>
                  <RiCalendarLine />
                  {todayDate.toLocaleDateString(undefined, dateOptions)}
                </span>
                <Divider />
                <MyPerson>
                  <span>
                    Olá,
                    <strong>
                      {name || 'Usuário'}
                      {' '}
                    </strong>
                  </span>
                  {user?.avatar ? (
                    <img src={user?.avatar?.formats?.thumbnail?.url} alt="" />
                  ) : (
                    <p>{name?.[0] || '?'}</p>
                  )}
                </MyPerson>
              </HeaderRight>
            </Header>

            <Outlet />
          </Content>

          {isOpenRequest && (
          <StepsProvider>
            <RequestModal handleClose={() => setIsOpenRequest(false)} />
          </StepsProvider>
          )}

          {isOpenSimulator && (
          <SimulatorModal
            handleClose={() => setIsOpenSimulator(false)}
            nextPage={() => {
              setIsOpenSimulator(false);
              handleOpen();
            }}
          />
          )}

          {isOpenOnlineModal && (
          <AlertModal
            title="Volte no próximo dia útil."
            text={errorCodes.OFF_BUSINESS_HOURS}
            typeAlert="timeNotAllowed"
            onClick={() => handleModalClose()}
            messageButton="Fechar"
          />
          )}

          {isLoading && (
          <Loading />
          )}

          {isOpenTOSModal && (
          <TermsOfUseModal />
          )}
        </AxiosInterceptor>
      </DashboardContext.Provider>
    </Container>
  );
};

export default Dashboard;
