import React, {
  createContext, ReactElement, useEffect, useState,
} from 'react';
import SearchInput from '../../../../components/SearchInput';
import { useAuth } from '../../../../contexts/AuthContext';

import ValidationModal from '../Components/ValidationModal';
import ViewModal from '../Components/ViewModal';
import GridRequest from './GridRequest';

import {
  Container, Breadcrumb, InputControl, SelectControl,
} from './styles';

export const RequestContext = createContext({} as RequestContextValue);

interface RequestContextValue {
  id: number;
  setId: React.Dispatch<number>;
  isVisibleValidationModal: boolean;
  setIsVisibleValidationModal: React.Dispatch<boolean>;
}

interface Props {
  orderId?: string;
}

const Request = ({ orderId }: Props): ReactElement => {
  const { role } = useAuth();
  const [isVisibleValidationModal, setIsVisibleValidationModal] = useState<boolean>(false);
  const [isVisibleViewModal, setIsVisibleViewModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [status, setStatus] = useState<string>(() => {
    if (role === 'manager') {
      return 'approved';
    }
    return '';
  });
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    if (orderId && +orderId) {
      setId(+orderId);
      setIsVisibleValidationModal(true);
    }
  }, [orderId]);

  return (
    <Container>
      <Breadcrumb>
        <SearchInput value={searchQuery} onChange={setSearchQuery} />
        <SelectControl>
          <small>Status</small>
          <select
            name="status"
            value={status}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            {role !== 'manager' && (
              <>
                <option value="">Todas</option>
                <option value="rejected">Rejeitadas</option>
                <option value="pending">Análise Pendente</option>
              </>
            )}
            <option value="approved">Aprovadas</option>
            <option value="liquidated">Pagas</option>
          </select>
        </SelectControl>
      </Breadcrumb>
      <RequestContext.Provider value={{
        id, setId, setIsVisibleValidationModal, isVisibleValidationModal,
      }}
      >
        <GridRequest
          handleVisibleValidation={() => setIsVisibleValidationModal(!isVisibleValidationModal)}
          handleVisibleView={() => setIsVisibleViewModal(!isVisibleViewModal)}
          searchQuery={searchQuery}
          status={status}
        />
        {isVisibleValidationModal && (
        <ValidationModal
          handleClose={() => {
            setIsVisibleValidationModal(false);
          }}
          type="RD"
          id={id}
        />
        )}
        {isVisibleViewModal && (
        <ViewModal
          handleClose={() => {
            setIsVisibleViewModal(false);
          }}
          type="RD"
          id={id}
        />
        )}
      </RequestContext.Provider>
    </Container>
  );
};

export default Request;
