import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { DashboardContext } from '../pages/Dashboard';

import api from '../services/api';
import { errorLabels } from './errorCodes';

const AxiosInterceptor: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { setIsOpenTOSModal, ...rest } = useContext(DashboardContext);
  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error?.response?.data?.error?.details?.serverCode) {
          case errorLabels.TOS_ACCEPT_UPDATED:
            _.set(error, 'intercepted', true);
            setIsOpenTOSModal(true);
            break;
          default:
            break;
        }
        return Promise.reject(error);
      },
    );
  }, []);

  return <>{children}</>;
};

export default AxiosInterceptor;
