import React, { ReactElement, useEffect, useState } from 'react';

import {
  RiArrowRightSLine,
  RiBillLine,
  RiUser4Line,
  RiLogoutBoxRLine,
  RiQuestionLine,
  RiBankCard2Line,
  RiSettings2Line,
} from 'react-icons/ri';

import BaruLogo from '../../assets/BaruLogo.svg';
import { useAuth } from '../../contexts/AuthContext';

import {
  Container,
  Brand,
  MenuItems,
  Item,
  ItemUrl,
  ToggleButton,
  BottomItems,
} from './styles';

interface SidebarProps {
  toggled: boolean;
  handleToggled: React.MouseEventHandler<HTMLButtonElement>;
}

const Sidebar = ({
  toggled = true,
  handleToggled,
}: SidebarProps): ReactElement => {
  const { logout, role } = useAuth();

  return (
    <Container toggled={toggled}>
      <Brand src={BaruLogo} alt="Logo" />

      <MenuItems>
        {role !== 'customer' && (
          <>
            <Item to="/portal/operador/solicitacoes" className="isActive">
              <RiBillLine />
              <span>Solicitações</span>
            </Item>
            <Item to="/portal/operador/clientes" className="isActive">
              <RiUser4Line />
              <span>Clientes</span>
            </Item>
          </>
        )}

        {role === 'customer' && (
          <>
            <Item to="/portal/cliente/solicitacoes" className="isActive">
              <RiBillLine />
              <span>Minhas solicitações</span>
            </Item>
            <Item to="/portal/perfil" className="isActive">
              <RiUser4Line />
              <span>Meu perfil</span>
            </Item>

            <Item to="/portal/cliente/meus-cartoes" className="isActive">
              <RiBankCard2Line />
              <span>Meus cartões</span>
            </Item>

            {/* <Item to="/portal/cliente/configuracoes" className="isActive">
              <RiSettings2Line />
              <span>Configurações</span>
            </Item> */}
          </>
        )}

        <BottomItems>
          <ItemUrl href="https://wa.me/555135007992" target="_blank" className="isActive">
            <RiQuestionLine />
            <span>Ajuda</span>
          </ItemUrl>

          <Item to="https://www.barupay.com.br/" className="isActive" onClick={() => logout()}>
            <RiLogoutBoxRLine />
            <span>Sair</span>
          </Item>
        </BottomItems>
      </MenuItems>

      <ToggleButton onClick={handleToggled}>
        <RiArrowRightSLine />
      </ToggleButton>
    </Container>
  );
};

export default Sidebar;
