import React, {
  createContext, useState, useEffect, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { authService, userService } from '../services';
import { SignInInfo } from '../types/auth';
import { User } from '../types/user';

type Props = { children: React.ReactNode };

interface AuthContextData {
  authenticated: boolean;
  user: User | null;
  role: string | null;
  login(userData: SignInInfo): Promise<void>;
  logout(): void;
  setToken(data: SetToken): void;
  fetchUser(): Promise<void>;
}

interface SetToken {
  jwt: string;
  user: User;
  keepConnected?: boolean;
}

const APP_KEEP_CONNECTED = '@App:keepConnected';
const APP_USER = '@App:user';
const APP_TOKEN = '@App:token';

const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: Props): any => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<string | null>(null);

  function getStorage(): Storage {
    return localStorage.getItem(APP_KEEP_CONNECTED) === 'true'
      ? localStorage
      : sessionStorage;
  }

  useEffect(() => {
    const storagedUser = getStorage().getItem(APP_USER);
    const storagedToken = getStorage().getItem(APP_TOKEN);
    const decodedToken = localStorage.getItem(APP_TOKEN) as any;

    if (storagedToken && storagedUser) {
      const userData = JSON.parse(storagedUser) as User;
      setUser(userData);
      setRole(userData?.role?.type || null);
      api.defaults.headers.common.Authorization = `Bearer ${storagedToken}`;

      const jwtPayload = JSON.parse(window.atob(decodedToken?.split('.')[1]));
      const isExpired = Date.now() >= jwtPayload.exp * 1000;
      if (isExpired) {
        logout();
      }
    }
  }, []);

  async function login({
    keepConnected,
    ...signInInfo
  }: SignInInfo): Promise<void> {
    const { jwt, user: userData } = await authService.login(signInInfo);

    setToken({
      jwt,
      user: userData,
      keepConnected,
    });
  }

  function setToken({ jwt, user: userData, keepConnected }: SetToken): void {
    api.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    setUser(userData);
    setRole(userData?.role?.type || null);

    if (keepConnected) {
      localStorage.setItem(APP_KEEP_CONNECTED, true.toString());
    } else {
      localStorage.removeItem(APP_KEEP_CONNECTED);
    }
    getStorage().setItem(APP_USER, JSON.stringify(userData));
    getStorage().setItem(APP_TOKEN, jwt);
  }

  function logout(): void {
    delete api.defaults.headers.common.Authorization;
    setUser(null);
    setRole(null);

    localStorage.removeItem(APP_KEEP_CONNECTED);
    localStorage.removeItem(APP_USER);
    localStorage.removeItem(APP_TOKEN);
  }

  async function fetchUser(): Promise<void> {
    const userData = await userService.getMe();
    const jwt = getStorage().getItem(APP_TOKEN);

    if (jwt && userData) {
      setToken({
        jwt,
        user: userData,
        keepConnected: localStorage.getItem(APP_KEEP_CONNECTED) === 'true',
      });
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated: Boolean(user),
        user,
        login,
        logout,
        setToken,
        role,
        fetchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const authContext = useContext(AuthContext);

  return authContext;
}

export default AuthContext;
