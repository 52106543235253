import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

interface InputMaskProps extends MaskedInputProps {
  field?: Record<string, unknown>;
}

const InputMask = ({ field, ...inputProps }: InputMaskProps): any => (
  <MaskedInput inputMode="numeric" {...inputProps} {...field} />
);

InputMask.defaultProps = {
  field: {},
};

export default InputMask;
