import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const MyCardsBox = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;

    > h3 {
      ${theme.texts.titleHeadingXXS};
      color: ${theme.colors.primaryBlack00};
    }
  `};
`;

export const RowItems = styled.div`
  margin: 18px 0 0 0;
`;

export const RowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    > button {
      background-color: white;

      font-size: 1.3rem;
      line-height: 2.2rem;
      font-weight: 500;

      color: ${theme.colors.primaryBlack00};
    }
  `};
`;

export const CardView = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 12px 18px 12px 12px;
    margin-top: 24px;

    display: flex;
    align-items: center;

    border-radius: 6px;
    background-color: ${theme.colors.primary10};

    .checkbox {
      background: transparent;
      border: 1px ${theme.colors.primary} solid;
      margin-right: 12px;
      border-radius: 3px;
      cursor: pointer;
      border-radius: 50px;

      display: flex;
      align-items: center;
    }

    .checkbox--active {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }
  `}
`;
export const ImageCard = styled.img`
  width: 100%;
  max-width: 62px;
  margin-right: 12px;
  height: 41px;
`;
export const CardInfo = styled.div`
  margin-right: auto;
`;

export const CardNumber = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphSM};
    color: ${theme.colors.primaryBlack00};
  `}
`;
export const CardName = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphXS};
    color: ${theme.colors.primaryBlack40};
  `}
`;

export const FinalValueInformations = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: 24px 0 0 0;
    margin: auto 0 0 0;
    border-top: 1px dashed ${theme.colors.primaryBlack20};
    position: relative;

    overflow: hidden;

    > img {
      border-radius: 0 6px 6px 0;

      position: absolute;
      right: 0;

      @media (max-width: 480px) {
        right: -20%;
      }
    }
  `};
`;

export const FinalValueBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary05};
    border-radius: 6px;
    width: 100%;

    display: flex;
    align-items: center;
    padding: 16px;
  `};
`;

export const FinalValueBrand = styled.div`
  ${({ theme }) => css`
    width: 66px;
    height: 66px;
    background-color: ${theme.colors.primary20};
    border-radius: 6px;
    margin: 0 20px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${theme.colors.primary};
      font-size: 3rem;
    }
  `};
`;

export const FinalValueTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextXS};
    }

    > b {
      ${theme.texts.buttonTextMD};
      letter-spacing: -0.2px;
      color: ${theme.colors.primaryBlack00};
      margin: 4px 0;

      > span {
        ${theme.texts.buttonTextXS};
        margin-left: 4px;
      }
    }

    > span {
      ${theme.texts.buttonTextXS};
      color: ${theme.colors.primaryBlack50};
    }
  `};
`;

export const WarningBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    padding: 16px 70px 16px 16px;
    margin: 24px 0;

    display: flex;
    align-items: center;

    @media(max-width: 480px) {
      padding: 16px;
      }

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 1.6rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    > svg {
      color: ${theme.colors.yellow};
      margin-right: 10px;
      font-size: 2rem;

      @media(max-width: 480px) {
        width: 100%;
        max-width: 20px;
      }
    }
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${theme.colors.white};

    /* position: sticky; */
    /* bottom: 0; */
    padding: 12px 0 0 0;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;
