import axios from 'axios';
import { useContext } from 'react';
import { DashboardContext } from '../pages/Dashboard';
import { errorLabels } from '../utils/errorCodes';

const baseURL = process.env.REACT_APP_BASE_URL || '';

const api = axios.create({
  baseURL,
});

export default api;
