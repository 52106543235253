import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { OnboardingContext } from '..';

import FooterLogo from '../../../assets/FooterLogo.svg';
import MailIcon from '../../../assets/MailIcon.svg';
import { authService } from '../../../services';

import {
  Container,
  Content,
  Divider,
  NotGetEmail,
  Seconds,
  Footer,
} from './styles';

const WAIT_TIME = 6;

export const EmailConfirmation = (): ReactElement => {
  const {
    onboardingData: {
      info: { email },
    },
  } = useContext(OnboardingContext);
  const [seconds, setSeconds] = useState<number>(WAIT_TIME);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds((sec) => sec - 1), 1000);
    }
  }, [seconds]);

  const resendEmail = async (): Promise<void> => {
    await authService.sendEmailConfirmation({ email });
    setSeconds(WAIT_TIME);
  };

  return (
    <Container>
      <Content>
        <img src={MailIcon} alt="Mail Icon" />
        <h2>Verifique sua caixa de entrada</h2>
        <p>
          Nós enviamos um link de acesso para
          <b>
            {' '}
            {email}
          </b>
          . Clique no link do e-mail para finalizar a
          criação da sua conta.
        </p>

        <Divider />

        <NotGetEmail>
          <p>Não recebeu o e-mail? </p>
          <button type="button" disabled={seconds > 0} onClick={resendEmail}>

            Reenviar e-mail

            {seconds > 0 && (
              ` em ${seconds} segundos`
            )}
          </button>
          .
        </NotGetEmail>

      </Content>
      <Footer>
        <img src={FooterLogo} alt="" />

        <p>
          Precisa de ajuda? Entre em contato com nosso
          <strong> suporte via e-mail</strong>
          .
        </p>
      </Footer>
    </Container>
  );
};

export default EmailConfirmation;
