import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};  
`;

export const StepFormFields = styled.div`
  margin: 0 0 24px 0;
`;

export const WarningBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    padding: 16px 18px 16px 16px;
    margin: 24px 0;
    margin-top: auto;

    display: flex;
    align-items: center;

    @media(max-width: 480px) {
      padding: 16px;
      }

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 2.2rem;
      font-size: 1.3rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    > svg {
      color: ${theme.colors.yellow};
      margin-right: 10px;
      font-size: 2rem;

      @media(max-width: 480px) {
        width: 100%;
        max-width: 20px;
      }
    }
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    width: 100%;

    > button {
      
      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;
