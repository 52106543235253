import React, {
  ReactElement, useState, useEffect, useContext,
} from 'react';

import {
  RiCloseLine,
  RiMailCheckFill,
  RiSecurePaymentFill,
  RiCheckLine,
} from 'react-icons/ri';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../..';

import Button from '../../../../../components/Button';
import LiquidationModal from '../../../../../components/LiquidationModal';
import { useAuth } from '../../../../../contexts/AuthContext';
import { customerService, orderService } from '../../../../../services';
import { CustomerResponseFull } from '../../../../../types/customer';
import { OrderResponse, OrderResponseFull } from '../../../../../types/order';
import { getErrorMessage } from '../../../../../utils/errorCodes';
import { formatMoney } from '../../../../../utils/formatters';
import { RequestContext } from '../../Request';

import CustomerData from './CustomerData';
import RequestData from './RequestData';

import {
  Container,
  Content,
  Header,
  Brand,
  Tag,
  Breadcrumb,
  ContentLeft,
  SecurePaymentIcon,
  ValueRequest,
  ContentRight,
  Divider,
  DynamicContent,
  FeedbackModal,
  FeedbackModalContent,
  TextAreaControl,
} from './styles';

import { ValidationModalProps } from './validationModal.spec';

const ValidationModal = ({
  handleClose,
  id,
  type,
}: ValidationModalProps): ReactElement => {
  const { updateTable } = useContext(DashboardContext);

  const { role } = useAuth();

  const [currentStep, setCurrentStep] = useState<'CD' | 'RD'>('CD');
  const [isVisibleLiquidationModal, setIsVisibleLiquidationModal] = useState<boolean>(false);
  const [isVisibleFeedbackModal, setIsVisibleFeedbackModal] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderResponseFull>();
  const [customer, setCustomer] = useState<CustomerResponseFull>();
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const customerId = order
    ? order?.attributes?.customer?.data?.id
    : customer?.id;
  const orderId = order?.id;

  const finalValue = order
    ? order?.attributes?.paymentValue + order?.attributes?.serviceFee
    : 0;
  const installmentValue = order
    ? finalValue / order?.attributes?.numberOfInstallments
    : 0;

  const stepTexts: any = {
    CD: 'cliente',
    RD: role === 'manager' ? 'liquidação' : 'solicitação',
  };

  const stepsOrders: any = {
    CD:
      customerId ? (
        <CustomerData customerId={customerId} />
      ) : (
        <></>
      ),
    RD: <RequestData order={order} />,
  };

  useEffect((): void => {
    async function fetchData(): Promise<void> {
      try {
        if (type === 'RD') {
          const { data } = await orderService.getOne(id);
          setOrder(data);
        } else {
          const { data } = await customerService.getOne(id);
          setCustomer(data);
        }
      } catch (err: any) {
        toast.error(
          getErrorMessage(err.response?.data?.error?.details?.serverCode)
            || 'Não foi possível realizar operação',
        );
        handleClose();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    handleStepRedirect();
  }, [order]);

  // TO DO: Get status of request or customer and redirect to correct step
  async function handleStepRedirect(): Promise<void> {
    try {
      if (
        order?.attributes?.customer?.data?.attributes?.status === 'approved'
      ) {
        setCurrentStep('RD');
      } else {
        setCurrentStep('CD');
      }
    } catch {
      toast.warning('Não foi possível realizar a operação');
    }
  }

  function handleVisibleLiquidationModal(): void {
    if (currentStep === 'RD') {
      setIsVisibleLiquidationModal(true);
    }
  }

  const approve = async (): Promise<void> => {
    setIsLoading(true);
    try {
      switch (currentStep) {
        case 'CD':
          if (!customerId) {
            throw Error();
          }
          try {
            await customerService.approve(customerId);

            toast.success('Enviado');
            updateTable();
            if (type === 'CD') {
              handleClose();
            } else {
              setCurrentStep('RD');
            }
          } catch (err: any) {
            toast.warning(
              getErrorMessage(err.response?.data?.error?.details?.serverCode)
                || 'Não foi possível realizar operação',
            );
          } finally {
            setIsLoading(false);
          }
          break;
        case 'RD':
          setIsLoading(false);
          // if (role === 'manager') {
          //   handleVisibleLiquidationModal();
          //   setIsLoading(false);
          // } else if (orderId) {
          //   try {
          //     await orderService.approve(orderId);
          //     toast.success('Solicitação aprovada com sucesso!');

          //     handleClose();
          //     updateTable();
          //   } catch (err: any) {
          //     toast.warning(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
          //   } finally {
          //     setIsLoading(false);
          //   }
          // }
          handleVisibleLiquidationModal();
          break;
        default:
          throw Error();
      }
    } catch (err: any) {
      setIsLoading(false);
      toast.warning(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível aprovar!',
      );
    }
  };

  const reject = (): void => {
    try {
      switch (currentStep) {
        case 'CD':
          setIsVisibleFeedbackModal(true);
          break;
        case 'RD':
          setIsVisibleFeedbackModal(true);
          break;
        default:
          throw Error();
      }
    } catch (error) {
      toast.warning('Não foi possível rejeitar!');
    }
  };

  const submitReject = async (): Promise<void> => {
    setIsLoading(true);
    try {
      switch (currentStep) {
        case 'RD':
          if (!orderId) {
            throw Error();
          }
          if (role === 'manager') {
            await orderService.rejectManager(orderId, description);
          } else {
            await orderService.reject(orderId, description);
          }
          toast.success('Solicitação rejeitada com sucesso!');
          handleClose();
          updateTable();
          break;
        case 'CD':
          if (!customerId) {
            throw Error();
          }
          await customerService.reject(customerId, description);
          toast.success('Cliente rejeitado com sucesso!');
          handleClose();
          updateTable();
          break;
        default:
          throw Error();
      }
    } catch (error) {
      toast.warning('Não foi possível realizar a operação!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <Header>
          <Brand>
            <h2>
              <p>Cliente </p>
              {order?.attributes?.customer?.data?.attributes?.user?.data
                ?.attributes?.name
                || customer?.attributes?.user?.data?.attributes?.name}
            </h2>
            {/* <Tag>
              <RiMailCheckFill />
              {' '}
              Autenticação realizada
            </Tag> */}
          </Brand>
          <RiCloseLine onClick={handleClose} />
        </Header>
        <Breadcrumb>
          <ContentLeft>
            <SecurePaymentIcon>
              <RiSecurePaymentFill />
            </SecurePaymentIcon>
            <ValueRequest>
              <p>Valor da soliticação:</p>
              <p>
                <strong>{formatMoney(finalValue)}</strong>
                {' '}
                (
                {formatMoney(installmentValue)}
                {' '}
                em
                {' '}
                {order?.attributes?.numberOfInstallments
                  ? order?.attributes?.numberOfInstallments
                  : '1'}
                x )
              </p>
            </ValueRequest>
          </ContentLeft>
          <ContentRight>
            <Button
              message={`Negar ${stepTexts[currentStep]}`}
              onClick={reject}
            />
            <Button
              message={`Aprovar ${stepTexts[currentStep]}`}
              icon={<RiCheckLine />}
              onClick={approve}
              isLoading={isLoading}
            />
          </ContentRight>
        </Breadcrumb>
        <Divider />
        <DynamicContent>{stepsOrders[currentStep]}</DynamicContent>
      </Content>

      {isVisibleLiquidationModal && (
        <LiquidationModal
          handleClose={() => setIsVisibleLiquidationModal(false)}
          order={order}
        />
      )}

      {isVisibleFeedbackModal && (
        <FeedbackModal>
          <FeedbackModalContent>
            <h4>
              Parecer
              <RiCloseLine onClick={() => setIsVisibleFeedbackModal(false)} />
            </h4>
            <TextAreaControl>
              <small>Motivo da negativa:</small>
              <textarea
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </TextAreaControl>
            <Button
              type="button"
              message="Enviar"
              onClick={() => submitReject()}
              isLoading={isLoading}
            />
          </FeedbackModalContent>
        </FeedbackModal>
      )}
    </Container>
  );
};

export default ValidationModal;
