import api from './api';
import {
  SignInCredentials,
  SignInResponse,
  EmailConfirmationRequest,
  EmailConfirmationResponse,
  ForgotPasswordCore,
  ResetPasswordCore,
} from '../types/auth';

export default {
  async login({ identifier, password }: SignInCredentials): Promise<any> {
    return (
      await api.post<SignInResponse>('/auth/local', { identifier, password })
    )?.data;
  },

  async sendEmailConfirmation({
    email,
  }: EmailConfirmationRequest): Promise<any> {
    return (
      await api.post<EmailConfirmationResponse>(
        '/auth/send-email-confirmation',
        { email },
      )
    )?.data;
  },

  async forgotPassword({ email }: ForgotPasswordCore): Promise<any> {
    return (
      await api.post('/auth/forgot-password', {
        email,
      })
    )?.data;
  },

  async resetPassword({ code, password, passwordConfirmation }: ResetPasswordCore): Promise<any> {
    return (
      await api.post('/auth/reset-password', {
        code, password, passwordConfirmation,
      })
    )?.data;
  },
};
