export function createCustomWAMessage(name: string): string {
  const customText = `Olá, *${name}*!
  %0A
  %0A
Gostaria de agradecer por se cadastrar na plataforma BaruPay!%0A
Estamos felizes em tê-lo como nosso mais novo cliente.
  %0A
  %0A
Percebi que você ainda não utilizou nosso serviço. Podemos ajudar você de alguma forma sobre o funcionamento da plataforma?
  %0A
  %0A
Estou aqui para ajudar em caso de dúvidas ou dificuldades, para garantir a melhor experiência possível.  
  %0A
  %0A
Atenciosamente,
  %0A
Equipe BaruPay 🍀`;

  return customText;
}
