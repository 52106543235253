import styled, { css } from 'styled-components';

export const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    > button {
      width: 90px;
      height: 38px;

      border-radius: 4px;

      ${theme.texts.buttonTextXS};
    }

    .analyze {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.primary};
    }

    .view {
      border: 1px solid ${theme.colors.primaryBlack10};
      background-color: ${theme.colors.white};
      &:hover {
        opacity: 1;
        border-color: ${theme.colors.primaryBlack00};
      }
    }

    .whatsapp {
      border: 1px solid ${theme.colors.primaryBlack10};
      background-color: ${theme.colors.white};
      width: 38px;
      height: 38px;
      padding: 0;
      margin: 0 0 0 6px;

      &:hover {
        opacity: 1;
        border-color: ${theme.colors.primaryBlack00};
      }

      > svg {
        color: ${theme.colors.primaryBlack00};
        margin: 0;
        font-size: 1.4rem;
      }
    }
  `}
`;

export const BtnDelete = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.error} !important;

    > svg {
      color: ${theme.colors.white};
    }
  `}
`;

export const StatusTag = styled.span<{ status?: string }>`
  ${({ theme, status }) => css`
    padding: 6px 10px;
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: ${theme.colors.yellow}10;
    color: ${theme.colors.yellow};

    ${status === 'pending'
    && css`
      background-color: ${theme.colors.yellow}10;
      color: ${theme.colors.yellow};
    `};

    ${status === 'liquidated'
    && css`
      background-color: ${theme.colors.primary}10;
      color: ${theme.colors.primary};
    `};

    ${status === 'approved'
    && css`
      background-color:rgba(78, 171, 224, 0.1);
      color: #4EABE0;
    `};

    ${status === 'rejected'
    && css`
      background-color: ${theme.colors.red};
      color: ${theme.colors.error};
    `};
  `}
`;
