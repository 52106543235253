import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    /* position: relative; */

    overflow: auto;

    &::-webkit-scrollbar {
      visibility: hidden;
    }

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const RequestConfirmationBox = styled.section`
  ${({ theme }) => css`
    padding: 0 0 24px 0;

    > h3 {
      ${theme.texts.titleHeadingXXS};
      color: ${theme.colors.primaryBlack00};
    }
  `};
`;

export const RowItems = styled.div`
  margin: 18px 0 0 0;
`;

export const ItemValue = styled.div`
  ${({ theme }) => css`
    margin-top: 24px;
    ${theme.texts.bodyParagraphMD};

    > p {
      background-color: ${theme.colors.primary05};
      text-align: center;
      padding: 14px 0;
      margin: 24px 0;

      font-size: 2.4rem;
      line-height: 3.4rem;
      font-weight: 600;
      letter-spacing: -0.2px;

      border-radius: 6px;

      > span {
        color: ${theme.colors.primary};
      }
    }
  `}
`;

export const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  ${({ theme }) => css`
    ${theme.texts.buttonTextXS};
    color: ${theme.colors.primary};

    > small {
      display: flex;
      align-items: center;
      text-align: right;
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.buttonTextSM};

      > svg {
        margin-right: 4px;
        color: ${theme.colors.primary};
        font-size: 1.8rem;
        cursor: pointer;
      }
    }
  `}
`;

export const ItemInstallment = styled.b`
  ${({ theme }) => css`
    ${theme.texts.buttonTextMD};
    color: ${theme.colors.primaryBlack00};

    display: flex;

    cursor: pointer;

    > small {
      ${theme.texts.buttonTextSM};
      color: ${theme.colors.primaryBlack00};

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.6rem;
        margin-right: 8px;

        color: ${theme.colors.primary};
      }
    }
  `}
`;

export const ItemFinalValue = styled.b`
  ${({ theme }) => css`
    ${theme.texts.buttonTextXS};
    color: ${theme.colors.primaryBlack50};
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;
    border-bottom: 1px dashed ${theme.colors.primaryBlack20};
  `}
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    background-color: ${theme.colors.white};
    /* position: sticky;
    bottom: 0; */
    padding: 12px 0 0 0;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;

export const CardView = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 12px 18px 12px 12px;
    margin-top: 24px;

    display: flex;

    border-radius: 6px;
    background-color: ${theme.colors.primary10};
  `}
`;
export const ImageCard = styled.img`
  width: 100%;
  max-width: 62px;
  margin-right: 12px;
  height: 41px;
`;
export const CardInfo = styled.div``;
export const CardNumber = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphSM};
    color: ${theme.colors.primaryBlack00};
  `}
`;
export const CardName = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphXS};
    color: ${theme.colors.primaryBlack40};
  `}
`;
