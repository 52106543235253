import styled, { css, keyframes } from 'styled-components';

export const Container = styled.button`
  ${({ theme }) => css`
    padding: 19px 24px;
    max-height: 60px;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 600;
    position: relative;
    
    transition: ${theme.transitions.default};

    > svg {
      margin-left: 9px;
      font-size: 2rem;
    }

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
    }
  `};
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 16px;

    padding: 1.2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #fff 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 12px;
    height: 12px;
    /* background-color: ${theme.colors.primary}; */
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;
