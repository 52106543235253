import React, {
  ReactElement, useState, useEffect, useContext,
} from 'react';
import { toast } from 'react-toastify';
import { RiWhatsappLine } from 'react-icons/ri';
import { CostumerContext } from '..';

import ColumnName from '../../../../../components/ColumnName';
import TableEmpty from '../../../../../components/TableEmpty';
import {
  TableField,
  TableItem,
  TableHeader,
  TableBody,
  TableFooter,
} from '../../../../../components/TableItem';
import { customerService } from '../../../../../services';
import { CustomerResponse } from '../../../../../types/customer';
import {
  formatCPF,
  formatDateWithHour,
  formatPhone,
} from '../../../../../utils/formatters';
import TablePagination from '../../../../../components/Pagination';

import { tableHeader, requestStatus } from './gridCustomer.spec';

import { Table, ActionsButton, StatusTag } from './styles';
import Button from '../../../../../components/Button';
import { DashboardContext } from '../../..';
import { getErrorMessage } from '../../../../../utils/errorCodes';

import { createCustomWAMessage } from './waTemplate';

interface GridCustomerProps {
  handleVisibleValidation: () => void;
  handleVisibleView: () => void;
  onClick: () => void;
  searchQuery: string;
  status: string;
}

const GridCustomer = ({
  handleVisibleValidation,
  handleVisibleView,
  onClick,
  searchQuery,
  status,
}: GridCustomerProps): ReactElement => {
  const { setId } = useContext(CostumerContext);
  const { updateTableSignal, updateTable, setIsLoading } = useContext(DashboardContext);

  const [customers, setCustomers] = useState<CustomerResponse[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageCount, setPageCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [signal, setSignal] = useState({});

  async function fetchData(): Promise<void> {
    setIsLoading(true);
    try {
      const { data, meta } = await customerService.getList({
        page,
        pageSize,
        searchQuery,
        status,
      });
      setCustomers(data);
      setPage(meta.pagination.page);
      setPageSize(meta.pagination.pageSize);
      setPageCount(meta.pagination.pageCount);
      setTotal(meta.pagination.total);
    } catch (err: any) {
      toast.error(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect((): void => {
    setPage(1);
    setSignal({});
  }, [searchQuery, status, updateTableSignal]);

  useEffect((): void => {
    fetchData();
  }, [page, pageSize, signal]);

  function handleWhatsappRedirect(
    customerName: string,
    waPhoneRef: string,
  ): void {
    const [customCustomerName] = customerName?.split(' ');
    const capitalizedCustomerName = customCustomerName.charAt(0).toUpperCase() + customCustomerName.slice(1).toLowerCase();
    const onBoardingMessage = createCustomWAMessage(capitalizedCustomerName);

    const waURL = `https://web.whatsapp.com/send/?phone=%2B55${waPhoneRef}&text=${onBoardingMessage}&type=phone_number&app_absent=0`;
    window.open(waURL, 'whatsappWindow');
  }

  return (
    <Table>
      <TableHeader>
        {tableHeader.map((item) => (
          <ColumnName
            key={item.id}
            title={item.name}
            width={item.width}
            align={item.align}
            ordened={item.ordened}
          />
        ))}
      </TableHeader>
      <TableBody>
        {customers.length ? (
          <>
            {customers.map((item: any) => (
              <TableItem key={item.id}>
                <TableField width="20%" align="start">
                  {item.attributes?.user?.data?.attributes?.name}
                </TableField>
                <TableField width="20%" align="start">
                  {formatCPF(item.attributes?.user?.data?.attributes?.cpf)}
                </TableField>
                <TableField width="15%" align="start">
                  {formatPhone(item.attributes?.user?.data?.attributes?.phone)}
                </TableField>
                <TableField width="20%" align="start">
                  <StatusTag status={item?.attributes.status}>
                    {
                      requestStatus[
                        item?.attributes.status as keyof typeof requestStatus
                      ]
                    }
                  </StatusTag>
                </TableField>
                <TableField width="17%" align="start">
                  {formatDateWithHour(item.attributes?.createdAt)}
                </TableField>
                <TableField width="13%" align="center">
                  <ActionsButton>
                    {item.attributes.status === 'pending' ? (
                      <Button
                        message="Analisar"
                        type="button"
                        className="analyze"
                        onClick={() => {
                          handleVisibleValidation();
                          setId(item.id);
                        }}
                      >
                        Analisar
                      </Button>
                    ) : (
                      <Button
                        message="Visualizar"
                        type="button"
                        className="view"
                        onClick={() => {
                          handleVisibleView();
                          setId(item.id);
                        }}
                      >
                        Visualizar
                      </Button>
                    )}
                    <Button
                      message=""
                      icon={<RiWhatsappLine />}
                      type="button"
                      className="whatsapp"
                      onClick={() => handleWhatsappRedirect(
                        item.attributes?.user?.data?.attributes?.name,
                        item.attributes?.user?.data?.attributes?.phone,
                      )}
                    />
                  </ActionsButton>
                </TableField>
              </TableItem>
            ))}
          </>
        ) : (
          <TableEmpty
            onClick={onClick}
            operator
            title="cliente"
            text="Quando houver um novo cadastro, será exibido aqui."
          />
        )}
      </TableBody>
      <TableFooter>
        <TablePagination
          pagination={{
            page,
            pageSize,
            pageCount,
            total,
          }}
          onChange={({ page: newPage, pageSize: newPageSize }) => {
            setPage(newPage);
            setPageSize(newPageSize);
          }}
        />
      </TableFooter>
    </Table>
  );
};

export default GridCustomer;
