import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const RequireAuth: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authenticated } = useAuth();
  const { pathname, state, search } = useLocation();

  return authenticated === true ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" replace state={{ ...state, path: pathname + search }} />
  );
};
export default RequireAuth;
