import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};
`;

export const BarCodeScanner = styled.div`
  ${({ theme }) => css`
    ${theme.texts.buttonTextSM};
    background-color: ${theme.colors.primary10};
    border-radius: 6px;
    height: 72px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${theme.colors.primary};
      font-size: 2.4rem;
      margin: 0 8px 0 0;
    }
  `};
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;
    color: ${theme.colors.primaryBlack00};
    ${theme.texts.bodyParagraphMD};

    display: flex;
    align-items: center;
    text-align: center;

    @media (max-width: 480px) {
      margin: 16px 0;
    }

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid rgba(30, 30, 30, 0.1);
    }

    :not(:empty)::before {
      margin-right: 24px;
    }

    :not(:empty)::after {
      margin-left: 24px;
    }
  `};
`;

export const InputPasteControl = styled.div`
  ${({ theme }) => css`
    position: relative;

    > input {
      width: 100%;
    }

    > button {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: ${theme.colors.white};
      padding: 0 0 0 5px;
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextSM};
    }
  `};
`;

export const BoxBoleto = styled.div``;
export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    margin-bottom: 24px;

    > h3 {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    > a {
      > svg {
        color: ${theme.colors.primaryBlack00};
        font-size: 1.8rem;
      }
    }
  `};
`;

export const TableBody = styled.div`
  ${({ theme }) => css`
    > button {
      background-color: transparent;
      width: 100%;
      padding-top: 0;
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.buttonTextXS};
    }
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;

export const BackgroundRotate = styled.div`
${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    min-height: 100%;
    z-index: 1003;
    border-radius: 4px;
    text-align: center;
    padding: 36px;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

       > img {
      width: 100%;
      max-width: 72px;
     margin: auto 0 24px;
    }

    > span {
      ${theme.texts.buttonTextSM};
      max-width: 270px;
      color: ${theme.colors.white};
      z-index: 10;
      margin: 0 0 20px;
    }

    > button {
      background-color: transparent;
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextSM};
      margin: 0 0 auto;
      text-decoration: underline;
    }

    @media screen and (min-width: 320px) and (max-width: 896px) and (orientation: landscape) {
      position: fixed;
      background-color: black;

    }
  `};
`;
