import React, {
  ReactElement, useEffect, useState, useContext,
} from 'react';

import {
  RiDeleteBinLine,
  RiDownload2Line,
  RiDownloadLine,
  RiEyeLine,
  RiSearchLine,
} from 'react-icons/ri';

import { toast } from 'react-toastify';
import ColumnName from '../../../../../components/ColumnName';
import TablePagination from '../../../../../components/Pagination';
import TableEmpty from '../../../../../components/TableEmpty';
import {
  TableField,
  TableItem,
  TableItemMobile,
  TableFieldMobile,
  TableHeader,
  TableBody,
  TableFooter,
} from '../../../../../components/TableItem';
import { myOrdersService } from '../../../../../services';
import { MyOrdersResponse } from '../../../../../types/myOrders';
import { formatDate, formatDateString, formatMoney } from '../../../../../utils/formatters';
import {
  dateOptions,
  tableBody,
  tableHeader,
  requestStatus,
} from './gridMyOrders.spec';

import { DashboardContext } from '../../..';

import {
  Table, ActionsButton, BtnDelete, StatusTag, Section,
} from './styles';
import { getErrorMessage } from '../../../../../utils/errorCodes';

interface GridMyOrdersProps {
  onClick: () => void;
  searchQuery: string;
  status: string;
}

const GridMyOrders = ({
  onClick,
  searchQuery,
  status,
}: GridMyOrdersProps): ReactElement => {
  const { updateTableSignal, updateTable, setIsLoading } = useContext(DashboardContext);
  const [myOrders, setMyOrders] = useState<MyOrdersResponse[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageCount, setPageCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [signal, setSignal] = useState({});

  async function fetchData(): Promise<void> {
    setIsLoading(true);
    try {
      const { data, meta } = await myOrdersService.getList({
        page,
        pageSize,
        searchQuery,
        status,
      });
      setMyOrders(data);
      setPage(meta.pagination.page);
      setPageSize(meta.pagination.pageSize);
      setPageCount(meta.pagination.pageCount);
      setTotal(meta.pagination.total);
    } catch (err: any) {
      if (!err.intercepted) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect((): void => {
    setPage(1);
    setSignal({});
  }, [searchQuery, status, updateTableSignal]);

  useEffect((): void => {
    fetchData();
  }, [page, pageSize, signal]);

  return (
    <Table>
      <TableHeader>
        {tableHeader.map((item) => (
          <ColumnName
            key={item.id}
            title={item.name}
            width={item.width}
            align={item.align}
            ordened={item.ordened}
          />
        ))}
      </TableHeader>
      <TableBody>
        {myOrders.length > 0 ? (
          <>
            {myOrders.map((item: any) => (
              <Section key={item.id}>
                <TableItem>
                  <TableField width="26%" align="start">
                    {formatMoney(
                      item?.attributes?.paymentValue
                          + item?.attributes?.serviceFee,
                    )}
                  </TableField>
                  <TableField width="20%" align="start">
                    {` ${formatMoney(
                      (item?.attributes?.paymentValue
                        + item?.attributes?.serviceFee)
                        / item?.attributes?.numberOfInstallments,
                    )} (${item?.attributes?.numberOfInstallments}x)`}
                  </TableField>
                  <TableField width="24%" align="start">
                    <StatusTag status={item?.attributes?.status}>
                      {
                      requestStatus[
                        item?.attributes.status as keyof typeof requestStatus
                      ]
                    }
                    </StatusTag>
                  </TableField>
                  <TableField width="24%" align="start">
                    {` ${formatDate(item?.attributes?.createdAt)}`}
                  </TableField>
                  <TableField width="6%" align="center">
                    <ActionsButton>
                      <button type="button">
                        <RiEyeLine />
                      </button>
                      <button type="button">
                        <RiDownload2Line />
                      </button>
                      {/* <BtnDelete type="button">
                        <RiDeleteBinLine />
                      </BtnDelete> */}
                    </ActionsButton>
                  </TableField>
                </TableItem>

                <TableItemMobile>
                  <TableFieldMobile>
                    <StatusTag status={item?.attributes?.status}>
                      {
                      requestStatus[
                        item?.attributes.status as keyof typeof requestStatus
                      ]
                    }
                    </StatusTag>

                    <p>
                      {`Vencimento: ${formatDateString(item?.attributes?.createdAt)}`}
                    </p>
                  </TableFieldMobile>

                  <TableFieldMobile>
                    <label>
                      Valor da conta/boleto
                      {' '}
                      <p>
                        {formatMoney(
                          item?.attributes?.paymentValue
                          + item?.attributes?.serviceFee,
                        )}
                      </p>
                    </label>
                  </TableFieldMobile>

                  <TableFieldMobile>
                    <p>
                      {`Parcelamento: ${formatMoney(
                        (item?.attributes?.paymentValue
                        + item?.attributes?.serviceFee)
                        / item?.attributes?.numberOfInstallments,
                      )} (${item?.attributes?.numberOfInstallments}x)`}
                    </p>
                    <ActionsButton>
                      {/* <button type="button" disabled>
                        <RiDeleteBinLine />
                      </button> */}

                      <button type="button" disabled>
                        <RiDownload2Line />
                      </button>
                      <button type="button" disabled>
                        <RiEyeLine />
                      </button>
                    </ActionsButton>
                  </TableFieldMobile>
                </TableItemMobile>

              </Section>
            ))}
          </>
        ) : (
          <TableEmpty onClick={onClick} />
        )}
      </TableBody>
      <TableFooter>
        <TablePagination
          pagination={{
            page,
            pageSize,
            pageCount,
            total,
          }}
          onChange={({ page: newPage, pageSize: newPageSize }) => {
            setPage(newPage);
            setPageSize(newPageSize);
          }}
        />
      </TableFooter>
    </Table>
  );
};

export default GridMyOrders;
