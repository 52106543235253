import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;
  /* height: 100vh; */

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.form`
  ${({ theme }) => css`
    width: 100%;
    max-width: 414px;
    padding: 32px;

    display: flex;
    flex-direction: column;

    position: absolute;
    top: 30px;
    bottom: 30px;

    overflow-y: auto;
    background-color: white;

    @media (min-width: 480px) {
      max-width: 724px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    > button {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    @media (max-width: 480px) {
      padding: 32px 32px 56px;
      top: 0;
      bottom: 0;

      /* height: 100vh; */
      min-height: -webkit-fill-available;
    }

    @media (min-width: 480px) {
      border-radius: 6px;
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      ${theme.texts.titleHeadingLG};
    }

    > svg {
      font-size: 3.2rem;
      cursor: pointer;
    }
  `};
`;

export const Body = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;

    .markdown {
      > h1 {
        margin-bottom: 10px;
      }

      > h2 {
        margin: 10px 0;
      }

      > h3 {
        margin: 10px 0;
      }

      > p {
        ${theme.texts.bodyParagraphMD};
      }
    }
  `};
`;

export const AcceptTOSContent = styled.div`
  margin-bottom: 24px;
`;

export const AcceptTOS = styled.div`
  ${({ theme }) => css`
    padding: 16px;

    display: flex;
    align-items: center;

    background-color: ${theme.colors.primary10};

    > p {
      ${theme.texts.buttonTextXS};
      > b {
        color: ${theme.colors.primary};
      }
    }

    .checkbox {
      background: transparent;
      border: 1px ${theme.colors.primary} solid;
      border-radius: 3px;
      margin-right: 12px;
    }

    .checkbox--active {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }
  `};
`;
