import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    background-color: ${theme.colors.white};
    /* min-height: -webkit-fill-available; */

    display: flex;
    flex-direction: column;

    @media (min-width: 480px) {
      height: 100vh;
    }

    @media (max-width: 480px) {
      display: block;
    }
  `}
`;
interface ContentProps {
  toggled?: boolean;
}

export const Content = styled.div<ContentProps>`
  ${({ theme, toggled }) => css`
    margin-left: 93px;
    background-color: ${theme.colors.white};
    padding: 46px 46px 0;
    flex: 1;

    transition: ${theme.transitions.default};

    display: flex;
    flex-direction: column;
    overflow: auto;

    ${toggled
    && css`
      margin-left: 249px;
    `}

    @media (max-width: 480px) {
      margin-left: 0%;
      padding: 12px 32px 32px;
      height: -webkit-fill-available;
     
    }
  `};
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const HeaderLeft = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlack00};

    > span {
      opacity: 0.5;
    }

    > h2 {
      ${theme.texts.titleHeadingLG};

      @media (min-width: 481px) and (max-width: 768px) {
        font-size: 2rem;
      }
    }
  `}
`;
export const HeaderRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${theme.texts.bodyParagraphSM};

    > svg {
      font-size: 2rem;

      display: none;
      
    }

    > button {
      padding: 14.33px;

      background-color: white;
      border: 1px solid ${theme.colors.primaryBlack10};
      border-radius: 4px;

      @media (max-width: 480px) {
      display: none;
    }
    }

    > span {
      margin-left: 17.33px;

      display: flex;
      align-items: center;

      @media (max-width: 480px) {
      display: none;
    }

      > svg {
        margin-right: 11.33px;
      }
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: 12px;
    margin: 0 14px;
    background-color: ${theme.colors.primaryBlack00};

    opacity: 0.1;

    @media (max-width: 480px) {
      display: none;
    }
  `}
`;

export const MyPerson = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
      display: none;
    }

  ${({ theme }) => css`
    > span {
      > strong {
        margin-left: 3px;
      }
    }

    > p {
      width: 32px;
      height: 32px;
      margin-left: 14px;

      display: flex;
      justify-content: center;
      align-items: center;

      text-transform: uppercase;

      color: ${theme.colors.white};
      ${theme.texts.buttonTextSM};

      border-radius: 100px;
      background-color: ${theme.colors.primary};
    }

    > img {

      width: 32px;
      height: 32px;

      margin-left: 14px;

      border-radius: 50px;
      object-fit: cover;
    }
  `}
`;
