import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const MainContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    position: relative;
    height: 659px;
    border-radius: 0 0 24px 24px;
    margin-top: -8px;

    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      height: 532px;
    }
  `};
`;

export const NestedMainContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1720px;
  width: 100%;
  margin: 0 auto;
`;

export const LeftImage = styled.img`
  position: absolute;
  left: -180px;
  bottom: -80px;
  max-height: 503px;
  z-index: 1;

  @media (max-width: 1441px) {
    display: none;
  }
`;

export const RightImage = styled.img`
  position: absolute;
  top: 35px;
  right: -80px;
  max-height: 382px;

  @media (max-width: 1880px) {
    display: none;
  }
`;

export const LeftImageCropped = styled.img`
  position: absolute;
  left: 0;
  bottom: -133px;
  max-height: 503px;
  z-index: 1;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1441px) {
    display: none;
  }
`;

export const RightImageCropped = styled.img`
  position: absolute;
  top: -33px;
  right: 0;
  max-height: 382px;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1880px) {
    display: none;
  }
`;

export const MainContentTexts = styled.div`
  ${({ theme }) => css`
    max-width: 726px;
    margin: 0 auto;
    width: 100%;
    padding: 70px 0 160px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 565px) {
      max-width: 332px;
      padding: 70px 0;
    }

    @media (max-width: 1441px) {
      padding: 0 0 78px 0;
    }

    > small {
      color: ${theme.colors.primary};
    }

    > h1 {
      font-size: 6.2rem;
      font-weight: 600;
      line-height: 6.2rem;
      letter-spacing: -1.3px;
      margin: 24px 0;

      > b {
        color: ${theme.colors.primary};
      }

      @media (max-width: 565px) {
        ${theme.texts.titleHeadingXL};
      }
    }

    > p {
      font-size: 2rem;
      line-height: 3.2rem;
      font-weight: 400;

      @media (max-width: 1441px) {
        max-width: 514px;
      }

      @media (max-width: 480px) {
        ${theme.texts.bodyParagraphLG};
        max-width: 310px;
      }
    }

    > button {
      margin: 24px 0 0 0;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      box-shadow: 0px 6px 25px rgba(86, 224, 78, 0.2);

      @media (max-width: 480px) {
        margin: 37px 0 0 0;
      }
    }
  `};
`;

export const HowWorks = styled.section`
  ${({ theme }) => css`
    padding: 104px 0;
    background-color: ${theme.colors.white};

    display: flex;
    flex-direction: column;
    align-items: center;

    > button {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      box-shadow: 0px 6px 25px rgba(86, 224, 78, 0.2);
    }

    @media (max-width: 768px) {
      padding: 53px 0 48px 0;
      border-radius: 24px 24px 0 0;

      > button {
        width: 100%;
        max-width: 350px;
      }
    }
  `};
`;

export const HowWorksTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > h2 {
      margin-bottom: 18px;
      color: ${theme.colors.primaryBlack00};
    }

    > small {
      margin-bottom: 32px;
      color: ${theme.colors.primary};
    }

    @media (max-width: 768px) {
      max-width: 350px;
      text-align: center;

      > h2 {
        ${theme.texts.titleHeadingXL};
      }

      > small {
        margin-bottom: 18px;
      }
    }
  `};
`;

export const HowWorksBox = styled.div`
  margin: 64px 0;

  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 0 0 36px 0;
    max-width: 350px;
    width: 100%;
  }
`;

export const HowWorksImage = styled.img`
  max-width: 643px;
  border-radius: 6px;
  margin-right: 16px;
  width: 100%;

  @media (max-width: 1024px) {
    margin: 36px 0;
    height: 164px;
    object-fit: cover;
  }
`;

export const HowWorksListItems = styled.ul`
  max-width: 423px;
  width: 100%;
`;

export const HowWorksListItem = styled.li`
  ${({ theme }) => css`
    ${theme.texts.buttonTextMD};
    letter-spacing: -0.2px;
    padding: 24px;
    border-radius: 6px;
    background-color: ${theme.colors.primary10};

    display: flex;
    align-items: center;

    > svg {
      margin-right: 24px;
      color: ${theme.colors.primary};
      font-size: 2.4rem;
    }

    :not(:last-child) {
      margin-bottom: 14px;
    }

    @media (max-width: 480px) {
      ${theme.texts.buttonTextSM};
    }
  `};
`;

export const Purpose = styled.section`
  ${({ theme }) => css`
    padding: 135px 0 163.16px 0;
    background-color: ${theme.colors.primary10};

    display: flex;
    flex-direction: column;

    > img {
      margin: 0 auto 32px auto;
    }

    @media (max-width: 480px) {
      padding: 56px 0 130px 0;
    }
  `};
`;

export const PurposeTexts = styled.div`
  ${({ theme }) => css`
    max-width: 804px;
    margin: 0 auto 56px auto;
    text-align: center;

    > h2 {
      margin-bottom: 18px;
    }

    @media (max-width: 480px) {
      max-width: 308px;

      > h2 {
        ${theme.texts.titleHeadingXL};
      }
    }
  `};
`;

export const PurposeBoxes = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;

export const PurposeBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.white};
    box-shadow: 0px 6px 40px rgba(86, 224, 78, 0.05);
    border-radius: 24px;
    padding: 44px 32px;
    max-width: 324px;

    > h3 {
      ${theme.texts.buttonTextMD};
      letter-spacing: -0.2px;
      margin: 24px 0;
      color: ${theme.colors.primaryBlack00};
    }

    > p {
      ${theme.texts.bodyParagraphMD};
      color: ${theme.colors.primaryBlack00};
      line-height: 2.4rem;
    }

    @media (max-width: 480px) {
      max-width: 350px;
    }
  `};
`;

export const PurposeBoxIcon = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary10};
    border-radius: 10px;
    width: 60px;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      font-size: 2.8rem;
      color: ${theme.colors.primary};
    }
  `};
`;

export const SimulateBox = styled.section`
  ${({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.primary};
  `};
`;

export const Content = styled.div`
  max-width: 1720px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const SimulateBoxSection = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    max-width: 350px;
    width: 100%;
    margin: 46px 0 0 0;
    padding: 40px 0 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      ${theme.texts.bodyParagraphMD};
      margin: 40px 0 0 0;
      color: ${theme.colors.white};
      text-align: center;
    }

    > img {
      max-width: 160px;
      width: 100%;
    }

    @media (max-width: 480px) {
      max-width: 272px;
    }
  `};
`;

export const SimulateBoxTexts = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    max-width: 546px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > small {
      margin: 0 0 24px 0;
    }

    > p {
      margin: 24px 0;
    }

    > button {
      background-color: ${theme.colors.white};
      color: ${theme.colors.primaryBlack00};
      box-shadow: 0px 6px 25px rgba(86, 224, 78, 0.2);
    }

    @media (max-width: 565px) {
      max-width: 332px;
    }
  `};
`;

export const ServicesSection = styled.section`
  ${({ theme }) => css`
    padding: 124px 0;
    background-color: ${theme.colors.primary};
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      position: absolute;
      top: -80px;
    }

    @media (max-width: 768px) {
      padding: 124px 32px 54px 32px;
    }
  `};
`;

export const ServicesBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 75px 120px;
    max-width: 1082px;
    width: 100%;
    border-radius: 18px;

    display: flex;
    justify-content: space-between;

    @media (min-width: 1024px) {
      &:not(:nth-child(even)) {
        justify-content: flex-end;
      }

      > img {
        &:nth-child(even) {
          margin-left: auto;
        }

        &:not(:nth-child(even)) {
          margin-right: auto;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    > img {
      max-width: 317px;
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 150px 43px 43px 43px;
      flex-direction: column;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 80px;
      }

      &:not(:nth-child(even)) {
        flex-direction: column-reverse;
      }

      > img {
        max-width: 260px;

        position: absolute;
        top: -40px;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
      }
    }
  `};
`;

export const ServicesBoxTexts = styled.div`
  ${({ theme }) => css`
    max-width: 438px;

    > h4 {
      ${theme.texts.titleHeadingXL};
      margin: 16px 0;
      color: ${theme.colors.primaryBlack00};
    }

    > p {
      font-weight: 400;
      font-size: 2rem;
      line-height: 3.2rem;
      color: ${theme.colors.primaryBlack00};
    }

    > small {
      color: ${theme.colors.primary};
    }

    @media (max-width: 565px) {
      text-align: center;
      max-width: 264px;
      margin: 0 auto;

      > h4 {
        ${theme.texts.buttonTextMD};
      }

      > p {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
  `};
`;

export const SectionSubtitle = styled.p`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphLG};

    > b {
      color: ${theme.colors.primary};
      font-weight: normal;
    }
  `};
`;

export const SectionTitleHeading = styled.h2`
  ${({ theme }) => css`
    ${theme.texts.titleHeadingXXL};

    > strong {
      text-decoration-line: underline;
    }
  `};
`;

export const SectionAlternativeText = styled.small`
  ${({ theme }) => css`
    ${theme.texts.alternativeTextXXS};
  `};
`;

export const SimulateBoxContent = styled.div`
  position: relative;
  padding: 90px 0 32px 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    position: absolute;
    top: -80px;
  }
`;

export const SimulateLeftImage = styled.img`
  position: absolute;
  left: -190px;
  bottom: -80px;
  max-height: 503px;
  z-index: 1;

  @media (max-width: 1441px) {
    display: none;
  }
`;

export const SimulateRightImage = styled.img`
  position: absolute;
  right: -80px;
  bottom: -80px;
  max-height: 382px;
  z-index: 1;

  @media (max-width: 1880px) {
    display: none;
  }
`;

export const SimulateLeftImageCropped = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  max-height: 330px;
  z-index: 1;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1441px) {
    display: none;
  }
`;

export const SimulateRightImageCropped = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 382px;
  z-index: 1;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1880px) {
    display: none;
  }
`;

export const FloatWhats = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 32px;
    right: 32px;

    width: 64px;
    height: 64px;

    background-color: ${theme.colors.white};
    cursor: pointer;

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 100px;

    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;

   > a {
    > svg {
      font-size: 3.2rem;
      color: ${theme.colors.primary};
    }
   }
  `};
`;
