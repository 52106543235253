import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 480px) {
    padding: 24px 0 0;
  }

  > svg {
    margin-bottom: auto;
    font-size: 3.2rem;
  }
`;

export const Content = styled.div`
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: auto;

  @media (max-width: 480px) {
    max-width: 288px;
  }

  ${({ theme }) => css`
    > img {
      width: 100%;
      max-width: 115px;
      margin-bottom: 20px;
    }

    > h2 {
      ${theme.texts.buttonTextMD};
      max-width: 184px;
      margin: 0 auto 20px;
      padding: 0 10px;
      text-align: center;

      @media (max-width: 480px) {
         margin: 0 auto 38px;
      }
    }

    > p {
      ${theme.texts.bodyParagraphMD};

      > b {
        ${theme.texts.buttonTextSM};
      }
    }
  `}
`;

export const Divider = styled.div`
  margin: 24px 0;

  ${({ theme }) => css`
    background-color: ${theme.colors.primaryBlack00};
    height: 1px;
    opacity: 0.1;
  `}
`;

export const NotGetEmail = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin-bottom: 24px;
  }
  ${({ theme }) => css`
    > p {
      ${theme.texts.bodyParagraphMD};
    }

    > button {
      ${theme.texts.buttonTextSM};
      color: ${theme.colors.primary};
      background-color: white;
      margin-left: 5px;

      &:disabled {
        color: ${theme.colors.primaryBlack30};
      }
    }
  `}
`;

export const Seconds = styled.span`
  ${({ theme }) => css`
    ${theme.texts.buttonTextMD};
    color: ${theme.colors.primaryBlack00};
  `}
`;

export const Footer = styled.div`
  text-align: center;
  margin-top: auto;

  ${({ theme }) => css`
    > img {
      width: 100%;
      max-width: 161px;
      margin-bottom: 20px;
      
      @media (max-width: 480px) {
        margin-bottom: 28px;
      }
    }

    > p {
      width: 100%;
      
      margin: 0 auto;
      ${theme.texts.bodyParagraphSM};

      @media (max-width: 480px) {
        max-width: 279px;
      }

      > strong {
        ${theme.texts.buttonTextXS};
        color: ${theme.colors.primary};
      }
    }
  `}
`;
