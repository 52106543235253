import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;

    display: flex;
    flex-direction: column;
  `}
`;

export const Breadcrumb = styled.section`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    > button {
      width: 37%;
      margin-top: auto;
      margin-left: 16px;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      @media (max-width: 480px) {
        display: none;
      }

      @media (max-width: 912px) {
       display: none;
      }
    }
  `}
`;

export const BoxBoleto = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 16px;

    border-radius: 6px;
    background-color: ${theme.colors.primary};

    cursor: pointer;

    > span {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      background-color: ${theme.colors.white};
      color: ${theme.colors.primary};
      padding: 6px 10px;
      border-radius: 4px;
    }

    > p {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-left: auto;

      color: ${theme.colors.white};
    }

    > svg {
      font-size: 1.8rem;
      margin-left: 8px;

      color: ${theme.colors.white};
    }
  `}
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: 498px;

    @media (max-width: 480px) {
      &:first-child {
        display: none;
      }
    }

    > svg {
      position: absolute;
      left: 19.5px;
      top: 22.5px;

      font-size: 15px;
    }

    > input {
      width: 100%;
      height: 60px;
      padding-left: 48px;

      font-size: 1.4rem;
      line-height: 2.4rem;

      border: 1px solid ${theme.colors.primaryBlack10};
      border-radius: 6px;

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.primaryBlack30};
        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: ${theme.colors.black};
      }
    }
  `}
`;

export const Table = styled.div`
  width: 100%;
`;

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    > button {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.primaryBlack10};
      background-color: ${theme.colors.white};
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      > svg {
        font-size: 1.2rem;
      }
    }
  `}
`;

export const BtnDelete = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.error} !important;

    > svg {
      color: ${theme.colors.white};
    }
  `}
`;

export const DropDownPlus = styled.div`
  ${({ theme }) => css`
    padding: 12px 14px;

    position: fixed;

    bottom: 90px;
    right: 32px;

    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primaryBlack20};
    border-radius: 4px;

    @media (min-width: 913px) {
      top: 185px;
      bottom: unset;
      right: 46px;
    }

    > button {
      padding: 10px;

      color: ${theme.colors.primaryBlack00};
      background-color: ${theme.colors.white};
      border-radius: none;

      ${theme.texts.bodyParagraphMD};

      &:first-child {
        margin-bottom: 4px;
      }

      > svg {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  `}
`;

export const DropdownContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    > button {
      position: fixed;
      bottom: 30px;
      right: 32px;

      border-radius: 100px;

      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      font-weight: 500;
      font-size: 1.3rem;
      line-height: 2.2rem;

      padding: 10px 20px;

      @media (min-width: 913px) {
        display: none;
      }
    }
  `}
`;
