import React, {
  ReactElement, useState, useContext, useEffect,
} from 'react';
import {
  RiAddFill,
  RiArrowDropDownFill,
  RiArrowRightLine,
  RiBillLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import { toast } from 'react-toastify';
import { DashboardContext } from '../..';
import Button from '../../../../components/Button';
import SearchInput from '../../../../components/SearchInput';
import { ddaService, orderService } from '../../../../services';
import { DDAResponse } from '../../../../types/dda';

import { errorCodes, getErrorMessage } from '../../../../utils/errorCodes';
import { SelectControl } from '../../Operator/Customer/styles';

import GridMyRequest from './GridMyOrders';

import {
  Container,
  Breadcrumb,
  BoxBoleto,
  DropdownContent,
  DropDownPlus,
} from './styles';

const MyOrders = (): ReactElement => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  const {
    setIsOpenRequest,
    setIsOpenSimulator,
    setIsOpenOnlineModal,
    countDDA,
    setCountDDA,
  } = useContext(DashboardContext);

  const handleOpen = async (): Promise<void> => {
    try {
      await orderService.verifyHour();
      setIsOpenRequest(true);
    } catch (err: any) {
      if (
        err.response?.data?.error?.details?.serverCode === 'OFF_BUSINESS_HOURS'
      ) {
        setIsOpenOnlineModal(true);
      } else {
        toast.warning(
          getErrorMessage(err.response?.data?.error?.details?.serverCode)
            || 'Não foi possível realizar operação',
        );
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  // async function fetchData(): Promise<void> {
  //   try {
  //     const { count } = await ddaService.countDDA();

  //     setCountDDA(count);
  //   } catch (err: any) {
  //     toast.warning(
  //       getErrorMessage(err.response?.data?.error?.details?.serverCode)
  //         || 'Não foi possível realizar operação',
  //     );
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <Container>
      <Breadcrumb>
        <SearchInput value={searchQuery} onChange={setSearchQuery} />
        <SelectControl>
          <small>Status</small>
          <select
            name="status"
            value={status}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            <option value="">Todas</option>
            <option value="rejected">Rejeitadas</option>
            <option value="pending">Análise Pendente</option>
            <option value="approved">Aprovadas</option>
            <option value="liquidated">Pagas</option>
          </select>
        </SelectControl>
        <Button
          type="button"
          message="Nova solicitação"
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          icon={<RiArrowDropDownFill />}
        />
      </Breadcrumb>

      {countDDA > 0 && (
        <BoxBoleto onClick={handleOpen}>
          <span>Boletos em meu nome</span>
          <p>
            {countDDA}
            {' '}
            encontrados
          </p>
          <RiArrowRightLine />
        </BoxBoleto>
      )}

      <GridMyRequest
        onClick={() => setIsOpenRequest(true)}
        searchQuery={searchQuery}
        status={status}
      />

      <DropdownContent>
        <Button
          message="Nova solicitacao"
          icon={<RiAddFill />}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        />

        {isOpenDropdown && (
          <DropDownPlus>
            <Button
              message="Nova conta/boleto"
              onClick={handleOpen}
              left
              icon={<RiBillLine />}
            />
            <Button
              message="Simular parcelas"
              onClick={() => setIsOpenSimulator(true)}
              left
              icon={<RiMoneyDollarCircleLine />}
            />
          </DropDownPlus>
        )}
      </DropdownContent>
    </Container>
  );
};

export default MyOrders;
