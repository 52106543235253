import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    min-height: 100%;
    z-index: 1003;
    border-radius: 4px;
    text-align: center;
    padding: 36px;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      border-radius: 0;
    }

    > svg {
      margin-right: auto;
      font-size: 2.4rem;
      color: ${theme.colors.primary};
      z-index: 100;
      cursor: pointer;
    }

    > button {
      text-decoration: underline;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
      letter-spacing: -0.1px;
      color: ${theme.colors.white};
      z-index: 100;
      background-color: transparent;
      margin-bottom: 54px;
    }

    > h4 {
      ${theme.texts.buttonTextSM};
      max-width: 270px;
      color: ${theme.colors.white};
      z-index: 10;
      margin: 83px 0 auto 0;
    }

    > img {
      width: 100%;
      max-width: 72px;
     margin: auto 0 24px;
    }

    > span {
      ${theme.texts.buttonTextSM};
      max-width: 270px;
      color: ${theme.colors.white};
      z-index: 10;
      margin: 0 0 auto;
    }

    @media screen and (min-width: 320px) and (max-width: 896px) and (orientation: landscape) {
      position: fixed;
      background-color: black;

      > h4 {
        margin: 0 0 auto 0;
      }

      > button {
        margin: auto 0 0 0;
      }
    }
  `};
`;

export const Viewport = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;

    transform: translate(-50%, -50%);

    border-radius: 4px;
    height: 154px;
    width: 100%;
    border-radius: 14px;
    object-fit: cover;
    padding: 0 45px;

    @media screen and (min-width: 320px) and (max-width: 896) and (orientation: landscape) {
      top: 55%;
    }

    > canvas {
      display: none;
    }

    > video {
      height: 154px;
      width: 100%;
      object-fit: cover;
      border-radius: 14px;
    }

    &:after {
      content: ' ';
      display: block;
      height: 4px;
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${theme.colors.white};
      border-radius: 4px;
    }
  `};
`;
