import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LiquidationModalContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 6px;
    max-width: 550px;
    width: 100%;
    padding: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
      color: ${theme.colors.primaryBlack00};
      font-size: 4.4rem;
      margin: 0 0 24px 0;
    }

    > h4 {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.titleHeadingLG};
      text-align: center;
    }

    > button {
      width: 100%;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:last-child {
        background-color: transparent;
        color: ${theme.colors.primaryBlack00};
      }
    }
  `};
`;

export const LiquidationModalCustomerInfos = styled.div`
  ${({ theme }) => css`
    border: 2px dashed ${theme.colors.primaryBlack10};
    border-radius: 6px;
    width: 100%;
    margin: 24px 0;
    padding: 32px;
  `};
`;

export const LiquidationModalCustomerRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > small {
      margin: 0 0 8px 0;
      color: ${theme.colors.primaryBlack00};
      opacity: 0.5;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 2.2rem;
    }

    > h5 {
      font-size: 2.4rem;
      line-height: 2.2rem;
      font-weight: 600;
      color: ${theme.colors.primaryBlack00};
      text-align: center;
    }

    > h6 {
      color: ${theme.colors.primaryBlack00};
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;

      display: flex;
      align-items: center;

      > span {
        font-size: 1.2rem;
        line-height: 2.2rem;
        font-weight: 400;
        margin: 0 0 0 6px;
      }
    }

    &:first-child {
      margin: 0 0 18px 0;
      padding: 0 0 18px 0;
      border-bottom: 1px solid ${theme.colors.primaryBlack10};
    }
  `};
`;

export const WarningBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    margin-bottom: 24px;

    > svg {
      width: 100%;
      max-width: 20px;
      font-size: 2rem;
      margin-right: 10px;

      color: ${theme.colors.yellow};
    }

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 2.2rem;
      font-size: 1.3rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  `};
`;
