import React from 'react';
import ReactDOM from 'react-dom';
import { StepsProvider } from 'react-step-builder';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import GlobalStyle from './styles/themes/globalStyles';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>

    <GlobalStyle />
    <App />
    <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      closeOnClick
      pauseOnHover
      draggable
      theme="light"
    />

  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
