import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { Field, Formik } from 'formik';
import ReactMarkdown from 'react-markdown';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { customerService, tosVersionService } from '../../services';
import { TOSVersionResponse } from '../../types/tosVersion';
import { getErrorMessage } from '../../utils/errorCodes';
import Button from '../Button';
import Checkbox from '../Checkbox';
import ErrorMessage from '../ErrorMessage';
import { DashboardContext } from '../../pages/Dashboard';

import {
  Container, Content, Header, Body, AcceptTOSContent, AcceptTOS,
} from './styles';

const TermsOfUseModal = (): ReactElement => {
  const { setIsOpenTOSModal, updateTable } = useContext(DashboardContext);
  const [tosVersion, setTosVersion] = useState<TOSVersionResponse>();

  const initialValues = {
    acceptedTOS: false,
    enableDDA: true,
  };

  const validationSchema = Yup.object().shape({
    acceptedTOS: Yup.bool().isTrue('O aceite dos termos de uso é obrigatório.'),
  });

  const handleSubmit = async (values: any): Promise<void> => {
    try {
      await customerService.acceptTOS(values.acceptedTOS, values.enableDDA);
      setIsOpenTOSModal(false);
      updateTable();
    } catch (err: any) {
      toast.error(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação!',
      );
    }
  };

  async function fetchTerms(): Promise<void> {
    try {
      const { data } = await tosVersionService.getLatest();
      setTosVersion(data);
    } catch (err: any) {
      toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
    }
  }

  useEffect((): void => {
    fetchTerms();
  }, []);

  const text = tosVersion?.attributes?.content || '';

  return (
    <Container>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => handleSubmit(values)}>
        {(formikProps) => (
          <Content onSubmit={formikProps.handleSubmit}>
            <Header>
              <h2>Atualização de nossos termos de uso</h2>
            </Header>
            <Body>
              <ReactMarkdown className="markdown" components={{ h3: 'h2' }}>
                {text}
              </ReactMarkdown>
            </Body>
            <AcceptTOSContent>
              <AcceptTOS>
                <Field component={Checkbox} name="acceptedTOS" />
                <p>
                  Li e estou de acordo com os
                  {' '}
                  <b>termos de uso</b>
                  {' '}
                  da
                  plataforma.

                </p>
              </AcceptTOS>
              <ErrorMessage message={formikProps?.errors?.acceptedTOS} />
            </AcceptTOSContent>

            <Button message="Confirmar" type="submit" disabled={!formikProps.values.acceptedTOS} />
          </Content>
        )}
      </Formik>
    </Container>
  );
};

export default TermsOfUseModal;
