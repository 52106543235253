import React, { ReactElement, useState } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Navigate, Location } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiArrowLeftLine, RiCloseLine } from 'react-icons/ri';
import { StepsProvider } from 'react-step-builder';
import { useAuth } from '../../contexts/AuthContext';

import { InputControl } from '../../styles/components';
import Button from '../../components/Button';
import BaruLogo from '../../assets/BaruLogo.svg';
import FooterLogo from '../../assets/FooterLogo.svg';
import Checkbox from '../../components/Checkbox';

import {
  Container,
  BackgroundImage,
  MainContent,
  Header,
  SignInForm,
  Actions,
  Footer,
  StayConnected,
  ResendEmailContainer,
  ResendEmailContent,
} from './styles';
import PasswordInput from '../../components/PasswordInput';
import { SignInInfo } from '../../types/auth';
import { errorCodes, getErrorMessage } from '../../utils/errorCodes';
import OnboardingModal from '../../components/OnboardingModal';
import { authService } from '../../services';

interface Props {
  redirect?: string;
}

const SignInCustomer = ({ redirect }: Props): ReactElement => {
  const { authenticated, login, role } = useAuth();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleModalEmail, setIsVisibleModalEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: SignInInfo = {
    identifier: '',
    password: '',
    keepConnected: true,
  };

  const validationSchema = Yup.object().shape({
    identifier: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  async function handleLogin({
    identifier,
    password,
    keepConnected,
  }: SignInInfo): Promise<void> {
    setIsLoading(true);
    try {
      await login({
        identifier,
        password,
        keepConnected,
      });
      toast.success('Logado com sucesso!');
    } catch (err: any) {
      toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Erro ao tentar fazer login!');

      if (err.response?.data?.error?.details?.serverCode === 'EMAIL_NOT_CONFIRMED') {
        resendEmail();
        setEmail(identifier);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const resendEmail = async (): Promise<void> => {
    await authService.sendEmailConfirmation({ email });
  };

  if (authenticated) {
    if (redirect) {
      return <Navigate to={redirect} replace />;
    }
    return <Navigate to="/portal" replace />;
  }

  return (
    <Container>
      <BackgroundImage>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(state) => {
            handleLogin(state);
          }}
        >
          {() => (
            <MainContent>
              <Header>
                <img src={BaruLogo} alt="" />
                <span>Login</span>
                <a href="https://barupay.com.br">
                  Voltar ao site
                  <FiArrowUpRight />
                </a>
              </Header>
              <h1>
                Acompanhe e gerencie
                <strong> suas parcelas</strong>
              </h1>
              <Form>
                <SignInForm>
                  <InputControl>
                    <small>Seu e-mail</small>
                    <Field
                      name="identifier"
                      type="email"
                      placeholder="nome@empresa.com.br"
                      autoComplete="email"
                    />
                  </InputControl>

                  <Field
                    component={PasswordInput}
                    name="password"
                    autoComplete="password"
                  />

                  {/* <StayConnected>
                    <Field component={Checkbox} name="keepConnected" />
                    <small>Permanecer conectado</small>
                  </StayConnected> */}
                  <Button type="submit" message="Entrar" isLoading={isLoading} disabled={isLoading} />
                </SignInForm>
              </Form>
              <Actions>
                <button type="button" onClick={() => setIsVisibleModal(true)}>Criar conta</button>
                {/* <button type="button" onClick={() => setIsVisibleModalEmail(true)}>Reenviar e-mail</button> */}
                <a href="/esqueceu-senha">Esqueci minha senha</a>
              </Actions>
              <Footer>
                <img src={FooterLogo} alt="" />
              </Footer>
            </MainContent>
          )}
        </Formik>
      </BackgroundImage>

      {/* {isVisibleModalEmail && (
        <ResendEmailContainer>

          <ResendEmailContent>
            <RiCloseLine onClick={() => setIsVisibleModalEmail(false)} />
            <InputControl>
              <small>Seu e-mail</small>
              <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="nome@empresa.com.br" />
            </InputControl>
            <Button type="button" onClick={() => resendEmail()} message="Enviar" />
          </ResendEmailContent>
        </ResendEmailContainer>
      )} */}

      {isVisibleModal && (
        <StepsProvider>
          <OnboardingModal handleClose={() => setIsVisibleModal(false)} prevPage={() => setIsVisibleModal(false)} />
        </StepsProvider>
      )}
    </Container>
  );
};

export default SignInCustomer;
