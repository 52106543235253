import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    > h2 {
      ${theme.texts.buttonTextMD};
    }
  `}
`;

export const PictureContent = styled.div`
  display: flex;
  padding: 24px 0 44px;

  @media(max-width: 480px) {
    flex-direction: column;
  }
`;

export const Picture = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: 100%;
    max-width: 94px;
    height: 94px;
    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    > p {
      font-weight: 600;
      font-size: 4.3rem;
      line-height: 5.2rem;
      letter-spacing: -0.01em;

      color: ${theme.colors.white};
    }

    > img {
      width: 100%;
      max-width: 94px;
      border-radius: 50px;
      height: 94px;
      object-fit: cover;
    }
  `}
`;

export const PictureAction = styled.div`
  ${({ theme }) => css`
    margin-left: 24px;

    @media(max-width: 480px) {
    margin-left: 0;
    margin-top: 24px;
  }

    > h2 {
      ${theme.texts.buttonTextSM};
    }

    > p {
      ${theme.texts.bodyParagraphSM};
      margin: 6px 0 14px;
      line-height: 1.6rem;

      color: ${theme.colors.primaryBlack50};
    }

    > button {
      ${theme.texts.buttonTextXS};
      height: 38px;

      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      > svg {
        font-size: 1.4rem;
      }
    }
  `}
`;

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: pointer;

  > label {
    input[type='file'] {
    display: none;
  }

  > p {
    ${({ theme }) => css`
      background-color: ${theme.colors.primary};
      border-radius: 6px;
      color: ${theme.colors.white};
      ${theme.texts.buttonTextXS};
      padding: 10px 16px;

      display: flex;
      cursor: pointer;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

      > svg {
        font-size: 1.5rem;
        margin-left: 6.5px;
      }
    `};
  }
  }
`;

export const ActionContent = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`

    > button {
      background-color: transparent;
      padding: 0 24px;

      color: ${theme.colors.primaryBlack00};

      ${theme.texts.bodyParagraphSM};
    }

    > p {
      ${theme.texts.bodyParagraphSM};
      color: ${theme.colors.primaryBlack50};
      margin-left: 14px;
    }
  `};
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 36px;
    top: 36px;
    padding: 1.2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #fff 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    /* background-color: red; */
    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    /* background-color: ${theme.colors.primary}; */
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;
