import styled, { css } from 'styled-components';

export const Container = styled.div`
@media (min-width: 480px) {
  display: none;
}
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 60px;
    background-color: ${theme.colors.white};
    border-radius: 0 0 10px 10px;
    z-index: 10;
    max-height: 88px;

    position: sticky;
    top: 0;

    @media (max-width: 585px) {
      padding: 16px 32px;
    }
  `};
`;

export const NestedContainer = styled.div`
  max-width: 1720px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 585px) {
    display: none;
  }
`;

export const NavbarButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > a {
      > button {
        &:first-child {
          margin-right: 6px;
          background-color: transparent;
        }
      }
    }

    > button {
      height: 48px;

      &:first-child {
        margin-right: 6px;
        background-color: transparent;
      }

      &:last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }
    }

    @media (max-width: 585px) {
      display: none;
    }
  `};
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;

  cursor: default;

  @media (max-width: 585px) {
    display: none;
  }
`;

export const RouteTag = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    text-transform: uppercase;
    font-size: 1.2rem; 
    line-height: 15px;
    font-weight: 400;
    background-color: #EEFCED;
    padding: 6px 10px;
    border-radius: 4px;
    margin-left: 18px;
    width: 63px;
    text-align: center;
  `};
`;

export const BackText = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.primary};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    cursor: pointer;

    > svg {
      font-size: 15px;
      margin-left: 4px;
    }
  `};
`;

export const MobileNavbar = styled.div`
  width: 100%;
  display: none;

  > img {
    width: 40px;
    height: 40px;
  }


  
  @media (max-width: 585px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
      display: none;
  }

  }
`;

export const MobileNavbarButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > svg {
      font-size: 3.2rem;
      color: ${theme.colors.primary};
      cursor: pointer;
    }

    > button {
      margin-right: 24px;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      max-height: 48px;
      height: 100%;
    }
  `};
`;

interface MobileMenuProps {
  visible?: boolean;
}

export const MobileMenu = styled.div<MobileMenuProps>`
  ${({ theme, visible }) => css`
    background-color: ${theme.colors.white};
    width: 100%;
    padding: 22px 32px 44px 32px;
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0px 50px 50px rgba(86, 224, 78, 0.1);

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    transform: translateY(-120%);
    transition: transform ${theme.transitions.transform};

    > img {
      max-width: 160px;
      width: 100%;
      margin: 44px auto 0 auto;
    }

    ${visible && css`
      transform: translateY(0);
    `}
  `};
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 0 21px 0;
  cursor: pointer;

  > svg {
    font-size: 3rem;
  }
`;

export const Tag = styled.p`
  ${({ theme }) => css`
    margin: 0 auto 0 18px;
    background-color: ${theme.colors.primary10};
    border-radius: 4px;
    height: 30px;
    padding: 6px 10px;
    text-transform: uppercase;
    ${theme.texts.alternativeTextXXXS};
    color: ${theme.colors.primary};

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const MobileMenuContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > h3 {
      ${theme.texts.titleHeadingLG};
      margin: 24px 0;
    }

    > button {
      color: ${theme.colors.primaryBlack00};

      &:last-child {
        background-color: transparent;
      }

      :nth-child(2) {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }

      :nth-child(3) {
        background-color: transparent;
        height: 34px;

      }

      :last-child {
        height: 34px;
      }

      &:not(:last-child) {
        margin: 0 0 14px 0;
      }
    }

    > a {
      width: 100%;
      margin-bottom: 14px;
      
      > button {
        width: 100%;
        height: 34px;
        background-color: transparent;
        color: ${theme.colors.primaryBlack00};

        > svg {
          font-size: 1.6rem;
        }
      }
    }
  `};
`;
