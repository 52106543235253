import React, {
  ReactElement, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import _ from 'lodash';
import { RiUpload2Line } from 'react-icons/ri';
import { Button } from '../../../components/Button';
import InputMask from '../../../components/MaskedInput';
import {
  addressService,
  customerService,
  userService,
} from '../../../services';
import { InputControl } from '../../../styles/components';
import { CustomerResponseFull, CustomerCore } from '../../../types/customer';
import { getErrorMessage } from '../../../utils/errorCodes';
import {
  cepMask, cpfMask, phoneMask8, phoneMask9,
} from '../../../utils/masks';

import {
  Container, InfoBasic, Form, InputControlRow,
} from './styles';
import { AddressCore, UFList } from '../../../types/address';
import { AddressForm } from '../../../types/onboarding';
import { StrapiRequestData, WithNull } from '../../../types/core';
import { User, UserCore, UserPartial } from '../../../types/user';
import { useAuth } from '../../../contexts/AuthContext';
import ErrorMessage from '../../../components/ErrorMessage';
import ProfilePicture from '../../../components/ProfilePicture';
import { DashboardContext } from '..';

type FormType = Pick<UserCore, 'name' | 'phone' | 'cpf'> & AddressForm;

const Profile = (): ReactElement => {
  const { isLoading, setIsLoading } = useContext(DashboardContext);
  const { fetchUser, role } = useAuth();
  const [user, setUser] = useState<User>();

  const validationSchema = useMemo(() => {
    let shape: Record<string, Yup.StringSchema> = {
      name: Yup.string().required('O campo "Nome completo" é obrigatório.'),
      cpf: Yup.string()
        .matches(/\d{11}/, 'O formato de CPF é inválido.')
        .required('O campo "CPF" é obrigatório.'),
      phone: Yup.string()
        .matches(/\d{10,11}/, 'O formato de telefone é inválido.')
        .required('O campo "Telefone" é obrigatório.'),
    };
    if (role === 'customer') {
      shape = {
        ...shape,
        postalCode: Yup.string()
          .matches(/\d{8}/, 'Formato inválido')
          .required('Campo "CEP" é obrigatório'),
        street: Yup.string().required('Campo "Rua" é obrigatório'),
        number: Yup.string().required('Campo "Número" é obrigatório'),
        complement: Yup.string().optional(),
        neighborhood: Yup.string().required('Campo "Bairro" é obrigatório'),
        city: Yup.string().required('Campo "Cidade" é obrigatório'),
        state: Yup.string().required('Campo "UF" é obrigatório'),
      };
    }
    return Yup.object().shape(shape);
  }, [role]);

  async function fetchData(): Promise<void> {
    setIsLoading(true);
    try {
      const response = await userService.getMe();
      setUser(response);
    } catch (err: any) {
      toast.error(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  }
  useEffect((): void => {
    fetchData();
  }, []);

  const formik = useFormik<FormType>({
    initialValues: {
      name: user?.name || '',
      cpf: user?.cpf || '',
      phone: user?.phone || '',
      postalCode: user?.customer?.address?.postalCode || '',
      street: user?.customer?.address?.street || '',
      number: user?.customer?.address?.number || '',
      complement: user?.customer?.address?.complement || '',
      neighborhood: user?.customer?.address?.neighborhood || '',
      city: user?.customer?.address?.city || '',
      state: user?.customer?.address?.state || '',
    },
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values) {
      const {
        name, cpf, phone, state, ...address
      } = Object.entries(
        values,
      ).reduce((acc, [key, value]) => {
        if (formik.initialValues[key as keyof FormType] !== value) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {} as Partial<FormType>);

      const userData: UserPartial = {
        name,
        cpf,
        phone,
      };
      if (!_.isEmpty(address)) {
        userData.customer = {
          address: {
            ...address,
            state: state || undefined,
          },
        };
      }
      setIsLoading(true);
      try {
        const data = await userService.edit(userData);
        setUser(data);
        fetchUser();
        toast.success('Perfil atualizado com sucesso!');
      } catch (err: any) {
        toast.error(
          getErrorMessage(err.response?.data?.error?.details?.serverCode)
            || 'Não foi possível realizar operação',
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchCepV2 = useCallback(
    _.debounce(async (newPostalCode: string) => {
      setIsLoading(true);
      if (newPostalCode?.length === 8) {
        try {
          const {
            cep, state, city, neighborhood, street,
          } = await addressService.fetchCepV2(newPostalCode);
          formik.setFieldValue('postalCode', cep);
          formik.setFieldValue('state', state);
          formik.setFieldValue('city', city);
          formik.setFieldValue('neighborhood', neighborhood);
          formik.setFieldValue('street', street);
        } catch (error) {
          toast.warning('CEP inválido');
          formik.setFieldValue('postalCode', '');
        }
      }
      setIsLoading(false);
    }, 1000),
    [],
  );

  const { postalCode } = formik.values;

  useEffect(() => {
    if (postalCode) {
      fetchCepV2(postalCode);
    }
    return () => {
      fetchCepV2('');
    };
  }, [postalCode, fetchCepV2]);

  const handleNumberChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    formik.setFieldValue(name, value?.replace(/\D/g, ''));
  };

  return (
    <Container>
      {/* /// MVP 2.0 /// */}
      <ProfilePicture
        thumbnailURL={user?.avatar?.formats?.thumbnail?.url}
        onChange={() => fetchData()}
        name={user?.name}
      />
      <InfoBasic>
        <h2>Informações básicas</h2>

        <Form onSubmit={formik.handleSubmit}>
          <InputControlRow>
            <InputControl className={formik?.errors?.name ? 'error' : ''}>
              <small>Nome completo:</small>
              <input
                name="name"
                type="text"
                placeholder="João da Silva"
                autoComplete="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              <ErrorMessage message={formik.errors.name} />
            </InputControl>

            <InputControl className={formik?.errors?.cpf ? 'error' : ''}>
              <small>CPF:</small>
              <InputMask
                name="cpf"
                placeholder="000.000.000-00"
                mask={cpfMask}
                value={formik.values.cpf}
                guide={false}
                className={formik?.errors?.name ? 'error' : ''}
                onChange={({ target: { value } }) => formik.setFieldValue('cpf', value?.replace(/\D/g, ''))}
              />

              <ErrorMessage message={formik.errors.cpf} />
            </InputControl>
          </InputControlRow>

          <InputControlRow>
            <InputControl className={formik?.errors?.phone ? 'error' : ''}>
              <small>Telefone:</small>
              <InputMask
                name="phone"
                placeholder="(00) 0 0000-0000"
                mask={
                  formik.values.phone?.length > 10 ? phoneMask9 : phoneMask8
                }
                autoComplete="tel-national mobile"
                value={formik.values.phone}
                guide={false}
                onChange={({ target: { value } }) => formik.setFieldValue('phone', value?.replace(/\D/g, ''))}
              />

              <ErrorMessage message={formik.errors.phone} />
            </InputControl>

            {role === 'customer' && (
              <InputControl
                className={formik?.errors?.postalCode ? 'error' : ''}
              >
                <small>CEP:</small>
                <InputMask
                  name="postalCode"
                  value={postalCode}
                  onChange={handleNumberChange}
                  placeholder="00000-000"
                  mask={cepMask}
                  guide={false}
                  autoComplete="postal-code"
                />

                <ErrorMessage message={formik.errors.postalCode} />
              </InputControl>
            )}
          </InputControlRow>
          {role === 'customer' && (
            <>
              <InputControlRow>
                <InputControl className={formik?.errors?.street ? 'error' : ''}>
                  <small>Endereço:</small>
                  <input
                    name="street"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="Rua Brasil"
                    autoComplete="address-line1"
                    disabled={isLoading}
                  />

                  <ErrorMessage message={formik.errors.street} />
                </InputControl>

                <InputControl className={formik?.errors?.number ? 'error' : ''}>
                  <small>Número:</small>
                  <input
                    name="number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="10"
                    disabled={isLoading}
                  />

                  <ErrorMessage message={formik.errors.number} />
                </InputControl>
              </InputControlRow>

              <InputControlRow>
                <InputControl
                  className={formik?.errors?.complement ? 'error' : ''}
                >
                  <small>Complemento:</small>
                  <input
                    name="complement"
                    value={formik.values.complement}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="Apto, bloco"
                    autoComplete="address-line2"
                    disabled={isLoading}
                  />

                  <ErrorMessage message={formik.errors.complement} />
                </InputControl>

                <InputControl
                  className={formik?.errors?.neighborhood ? 'error' : ''}
                >
                  <small>Bairro:</small>
                  <input
                    name="neighborhood"
                    value={formik.values.neighborhood}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="São João"
                    autoComplete="address-level3"
                    disabled={isLoading}
                  />

                  <ErrorMessage message={formik.errors.neighborhood} />
                </InputControl>
              </InputControlRow>

              <InputControlRow>
                <InputControl className={formik?.errors?.city ? 'error' : ''}>
                  <small>Cidade:</small>
                  <input
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="Selecione"
                    autoComplete="address-level2"
                    disabled
                  />

                  <ErrorMessage message={formik.errors.city} />
                </InputControl>

                <InputControl className={formik?.errors?.state ? 'error' : ''}>
                  <small>Estado:</small>
                  <select
                    name="state"
                    value={formik.values.state || ''}
                    onChange={formik.handleChange}
                    autoComplete="address-level1"
                    disabled
                  >
                    <option value="">Selecione</option>
                    {UFList.map((value: string) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>

                  <ErrorMessage message={formik.errors.state} />
                </InputControl>
              </InputControlRow>
            </>
          )}

          <InputControlRow>
            <p>
              Precisa de ajuda?
              {' '}
              <a href="mailto:suporte@barupay.com.br?subject=[Ajuda] Editar perfil">
                Entre em contato com nosso suporte.
              </a>
            </p>
            <Button
              message="Salvar"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </InputControlRow>
        </Form>
      </InfoBasic>
    </Container>
  );
};

export default Profile;
