import React, { ReactElement, useState } from 'react';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';

import { Container } from './styles';
import { InputControl } from '../../styles/components';
import { PasswordInputProps } from './PasswordInput.spec';

function PasswordInput({ label, field, className }: PasswordInputProps): ReactElement {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Container>
      <InputControl className={className}>
        <small>{label}</small>

        <input
          type={isVisible ? 'text' : 'password'}
          placeholder="••••••••"
          {...field}
        />
      </InputControl>
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        {' '}
        {isVisible ? <RiEyeLine /> : <RiEyeCloseLine />}
      </button>
    </Container>
  );
}

PasswordInput.defaultProps = {
  label: 'Sua senha',
};

export default PasswordInput;
