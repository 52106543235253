import styled, { css, keyframes } from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255, 0.8);

  z-index: 99;
  
  left: 0px;
  @media (max-width: 480px) {
  }
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`

    /* padding: 1.2px; */
    border-radius: 50%;
    display: inline-block;
    /* background: conic-gradient(
      from 180deg at 50% 50%,
      #fff 0deg,
      rgba(255, 255, 255, 0) 360deg
    ); */
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${theme.colors.primary};
    /* background-color: ${theme.colors.primary}; */
    /* transition: ${theme.transitions.default}; */
    animation: ${spinAnimation} 0.6s linear infinite;
    width: 40px;
    height: 40px;
  `}
`;

export const Circle = styled.div`
  /* ${({ theme }) => css`
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `} */
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;
