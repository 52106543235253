import styled, { css } from 'styled-components';
// import { COLORS } from '../../styles/constants';

interface TableItemProps {
  borderColor?: string;
  width?: string;
  align?: string;
}

export const TableHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 4px;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary05};
  `}

  @media (max-width: 912px) {
    display: none;
  }
`;

export const TableBody = styled.div`
  margin: 0 0 24px 0; 
  @media (max-width: 480px) {
    height: calc(100vh - 300px);
    overflow: auto;
  }

  @media (max-width: 375px) {
    height: calc(100vh - 280px);
  }
`;

export const StatusTag = styled.span<{ status?: string }>`
  ${({ theme, status }) => css`
    padding: 6px 10px;
    font-size: 0.9rem;
    line-height: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: ${theme.colors.yellow}10;
    color: ${theme.colors.yellow};

    ${status === 'pending'
    && css`
      background-color: ${theme.colors.yellow}10;
      color: ${theme.colors.yellow};
    `};

    ${status === 'liquidated'
    && css`
      background-color: ${theme.colors.primary}10;
      color: ${theme.colors.primary};
    `};

    ${status === 'rejected'
    && css`
      background-color: ${theme.colors.red};
      color: ${theme.colors.error};
    `};

    ${status === 'approved'
    && css`
      background-color:rgba(78, 171, 224, 0.1);
      color: #4EABE0;
    `};
  `}
`;

export const TableItem = styled.div<TableItemProps>`
  padding: 12px 24px;
  font-size: 14.5px;

  display: flex;
  /* justify-content: space-between; */

  @media (max-width: 912px) {
    display: none;
  }

`;

export const TableItemMobile = styled.div<TableItemProps>`
  ${({ theme }) => css`

  padding: 16px;

  background-color:#F7FDF6;
  border-radius: 6px;

  margin-bottom: 12px;

  @media (min-width: 913px) {
    display: none;
  }

  `}
`;

export const TableFieldMobile = styled.div`
  ${({ theme }) => css`

  display: flex;
  width: 100%;
  justify-content: space-between;

  align-items: center;

  &:first-child {
    margin-bottom: 12px;
  }

  > label { 
    ${theme.texts.bodyParagraphXS};
    color: ${theme.colors.primaryBlack30};
    > p {
    ${theme.texts.buttonTextSM};

    color: ${theme.colors.primaryBlack00};

    margin: 4px 0 2px;
    }
  }

  > p {
    ${theme.texts.bodyParagraphXS};
    color: ${theme.colors.primaryBlack30};
  }
`}
`;

export const TableField = styled.div<TableItemProps>`
  width: 100%;
  max-width: ${(props) => props.width};
  text-align: ${(props) => props.align};

  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;

  display: flex;
  align-items: center;

  > svg {
    font-size: 17px;
    margin-left: 4.5px;
  }
`;

export const TableFooter = styled.div`
  ${({ theme }) => css`
    width: 100%;

    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${theme.colors.primaryBlack};
    margin-top: auto;


    @media (max-width: 480px) {
      display: none;
    }

    > div {
      display: flex;

      > p {
        margin: 0 2px;
      }

      > button {
        margin: 0 0 0 16px;
        opacity: 0.7;
        font-weight: 500;
        cursor: pointer;
        background-color: ${theme.colors.white};

        ${theme.texts.buttonTextSM};

        &.active {
          color: ${theme.colors.primary};
          opacity: 1;
          background-color: ${theme.colors.white};
        }

        > svg {
        font-size: 2.4rem;
      }
      }
    }
  `}
`;
