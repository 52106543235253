import styled, { css } from 'styled-components';

import BackgroundSignIn from '../../assets/BackgroundSignIn.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: -webkit-fill-available;
  height: 100vh;
  background-color: white;
  padding: 35px;

  @media (max-width: 480px) {
    padding: 0;
    height: auto;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${BackgroundSignIn});
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;

  display: flex;
  align-items: center;
  min-height: -webkit-fill-available;
  height: 100vh;

  width: 100%;

  @media (max-width: 480px) {
    background-image: none;
    align-items: initial;
    height: 100%;
  }
`;

export const MainContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 537px;
    margin: 0 auto;
    padding: 44px;
    border-radius: 4px;

    background-color: ${theme.colors.white};
    box-shadow: 0px 82px 438px rgba(0, 0, 0, 0.06);

    @media (max-width: 480px) {
      box-shadow: none;
      padding: 32px;
    }

    > h1 {
      ${theme.texts.titleHeadingXXL}
      color: ${theme.colors.black};

      margin-bottom: 8px;

      > strong {
        color: ${theme.colors.primary};
      }
    }

    > small {
      ${theme.texts.bodyParagraphMD}

      color: ${theme.colors.primaryBlack00};
      opacity: 0.5;
    }

    > a {
      display: flex;
      justify-content: center;
      > span {
        color: ${theme.colors.black};
        ${theme.texts.buttonTextXS};
        cursor: pointer;
        margin-top: 24px;
      }
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 480px) {
      margin-bottom: 84.58px;
    }

    > span {
      padding: 6px 10px;
      margin-left: 18px;

      border-radius: 4px;

      ${theme.texts.alternativeTextXXXS}
      background-color: ${theme.colors.primary10};
      color: ${theme.colors.primary};
    }

    > a {
      margin-left: auto;
      display: flex;
      align-items: center;

      ${theme.texts.alternativeTextXXXS}
      color: ${theme.colors.primary};
      background-color: transparent;

      > svg {
        font-size: 18px;
        margin-left: 6px;
      }
    }
  `};
`;

export const SignInForm = styled.div`
  ${({ theme }) => css`
    margin-top: 32px;

    > button {
      width: 100%;
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;

export const Footer = styled.div`
  text-align: center;

  @media (min-width: 480px) {
    display: none;
  }

  > img {
    margin-top: 84.58px;
  }
`;
