import React, { ReactElement, useEffect, useState } from 'react';
import { RiCameraFill, RiGalleryFill, RiDeleteBinLine } from 'react-icons/ri';
import Button from '../../../Button';

import {
  AttachRow,
  AttachBox,
  AttachBoxSelector,
  AttachBoxTexts,
  AttachBoxButtons,
  AttachImageInfo,
  ButtonWrapper,
} from './styles';

type OnChange = (a: { target: { name: string; value: File | null } }) => void;
interface Field {
  name: string;
  value: File | null;
  onChange: OnChange;
}

interface Props extends Field {
  title: string;
  subTitle: string;
  onOpenScanner: (target: string) => void;
  field: Field;
}

const ImageSelector = ({
  title,
  subTitle,
  onOpenScanner,
  field,
  ...rest
}: Props): ReactElement => {
  const {
    name, value, onChange,
  } = {
    ...field,
    ...rest,
  };
  const [preview, setPreview] = useState<string>();
  const [filePath, setFilePath] = useState<string>();

  useEffect(() => {
    if (value instanceof Blob) {
      const objectUrl = URL.createObjectURL(value);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
    setPreview(undefined);
    return undefined;
  }, [value]);

  const setFile = (file: File | null): void => {
    onChange?.({
      target: {
        name,
        value: file,
      },
    });
  };

  const handleChange = ({
    target: { value: path, files },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const newFile = files?.[0] || null;
    setFile(newFile);
    setFilePath(path);
  };

  const removeFile = (): void => {
    setFile(null);
    setFilePath(undefined);
  };

  return (
    <AttachRow>
      <small>{title}</small>

      {!value ? (
        <AttachBox>
          <AttachBoxTexts>
            <h4>{subTitle}</h4>
            <span>Nenhuma foto anexada</span>
          </AttachBoxTexts>
          <AttachBoxButtons>
            {/* <ButtonWrapper>
              <Button message="Da galeria" icon={<RiGalleryFill />} />
              <input
                name={name}
                type="file"
                accept="image/*"
                onChange={handleChange}
                defaultValue={filePath}
              />
            </ButtonWrapper>
            <span>ou</span> */}
            <Button
              message="Abrir câmera"
              icon={<RiCameraFill />}
              onClick={() => onOpenScanner(name)}
            />
          </AttachBoxButtons>
        </AttachBox>
      ) : (
        <AttachBoxSelector>
          <AttachImageInfo>
            <img src={preview} alt="text" />
          </AttachImageInfo>

          <RiDeleteBinLine onClick={removeFile} />
        </AttachBoxSelector>
      )}
    </AttachRow>
  );
};

export default ImageSelector;
