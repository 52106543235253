import styled, { css } from 'styled-components';

export const AttachRow = styled.div`
  ${({ theme }) => css`
    > small {
      ${theme.texts.bodyParagraphMD};
    }
    &:not(:first-child) {
      margin-top: 24px;
    }
  `};
`;

export const AttachBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary10};
    border: 1px dashed ${theme.colors.primary};
    border-radius: 6px;
    padding: 44px;
    margin: 12px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column;

      text-align: center;
      padding: 44px 0;
    }
  `};
`;

export const AttachBoxSelector = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary10};
    /* border: 1px dashed ${theme.colors.primary}; */
    border-radius: 6px;
    padding: 16px 24px 16px 16px;
    margin: 12px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      font-size: 1.8rem;
      color: ${theme.colors.error};
      cursor: pointer;
    }
  `};
`;

export const AttachBoxTexts = styled.div`
  @media (max-width: 480px) {
    margin-bottom: 24px;
  }

  ${({ theme }) => css`
    > h4 {
      ${theme.texts.buttonTextSM};
      letter-spacing: -0.1px;
      color: ${theme.colors.primaryBlack00};
      margin: 0 0 4px 0;
    }

    > span {
      color: ${theme.colors.primaryBlack00};
      opacity: 0.3;
      ${theme.texts.bodyParagraphSM};
    }
  `};  
`;

export const AttachBoxButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: 0 9px;

    > span {
      color: ${theme.texts.primaryBlack00};
      ${theme.texts.bodyParagraphXS};
      margin: 0 8px;
    }

    > button {
      background-color: ${theme.colors.primary};
      border-radius: 6px;
      color: ${theme.colors.white};
      ${theme.texts.buttonTextXS};
      padding: 8px 12px;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.5rem;
      }
    }
  `};
`;

export const AttachImageInfo = styled.div`
  > img {
    width: 100%;
    max-width: 65px;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  > button {
    ${({ theme }) => css`
      background-color: ${theme.colors.primary};
      border-radius: 6px;
      color: ${theme.colors.white};
      ${theme.texts.buttonTextXS};
      padding: 8px 12px;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.5rem;
      }   
      `};
    } 
`;
