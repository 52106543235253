import React, { ReactElement } from 'react';

import { Container, LoadingCircle, Circle } from './styles';

const Loading = (): ReactElement => {
  const text = '';
  return (
    <Container>
      <LoadingCircle>
        <Circle />
      </LoadingCircle>
    </Container>
  );
};

export default Loading;
