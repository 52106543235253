import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  min-height: -webkit-fill-available;
  /* height: 100vh; */

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DynamicContent = styled.div`
  padding: 90px 36px 36px 36px;
  min-height: -webkit-fill-available;
  /* height:  -webkit-fill-available;  */
  overflow-y: auto;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  bottom: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    padding: 90px 32px 12px 32px;
    min-height: -webkit-fill-available;
    /* height: 100vh; */
  }
`;

export const Content = styled.div<{ viewMode?: string }>`
  ${({ theme, viewMode }) => css`
    background-color: ${theme.colors.white};
    max-width: 724px;
    width: 100%;
    border-radius: 4px;

    position: absolute;
    top: 30px;
    bottom: 30px;

    ${viewMode === 'iFrame'
    && css`
      max-width: 100%;
      top: 0;
      bottom: 0;
      border-radius: 0;
    `};
  `};

  @media (max-width: 480px) {
    top: 0;
    bottom: 0;
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;

  overflow-y: auto;

  }
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: ${theme.colors.white};
    padding: 36px 36px 24px 36px;
    border-radius: 4px 4px 0 0;

    > svg {
      font-size: 2.5rem;
      color: ${theme.colors.primaryBlack00};
      cursor: pointer;
    }
  `};
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;

  > img {
    max-width: 30px;
    width: 100%;
  }
`;

export const Tag = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 104px;
    height: 29px;
    padding: 6px 10px;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: ${theme.colors.background};
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 500;
    color: ${theme.colors.primary};
    letter-spacing: 1.4px;
    margin-left: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;
