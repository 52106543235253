import React, {
  createContext, ReactElement, useEffect, useState,
} from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { Steps, useSteps } from 'react-step-builder';

import BaruLogo from '../../assets/BaruLogo.svg';

import InstallmentValue from './InstallmentValue';

import { OnboardingSteps } from '../../types/onboarding';

import {
  Container,
  Content,
  DynamicContent,
  Header,
  Brand,
  Tag,
} from './styles';
import { BarcodeResponse } from '../../types/billPayment';
import { useAuth } from '../../contexts/AuthContext';

export const OnboardingContext = createContext({} as OnboardingContextValue);

interface OnboardingContextValue {
  prev: () => void;
  next: () => void;
  onboardingData: OnboardingSteps;
  setOnboardingData: React.Dispatch<OnboardingSteps>
  barcodeResponse: BarcodeResponse;
  setBillPaymentResponse: React.Dispatch<BarcodeResponse>;
  numberOfInstallments: number;
  setNumberOfInstallments: React.Dispatch<number>;
}

interface SimulatorModalProps {
  handleClose: () => void;
  nextPage: () => void;
  viewMode?: string;
}

export const SimulatorModal = ({
  handleClose,
  nextPage,
  viewMode,
}: SimulatorModalProps): ReactElement => {
  const [onboardingData, setOnboardingData] = useState({} as OnboardingSteps);
  const [barcodeResponse, setBillPaymentResponse] = useState(
    {} as BarcodeResponse,
  );
  const [numberOfInstallments, setNumberOfInstallments] = useState(1);
  const {
    next, prev, current, jump,
  } = useSteps();
  const { authenticated } = useAuth();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  function handleModalClose(): void {
    document.body.style.overflow = 'auto';
    handleClose();
  }

  function triggerToParent(): void {
    const topWindow = window?.top;

    if (topWindow) {
      topWindow.location.href = process.env.REACT_APP_PARENT_URL || '';
    }
  }

  return (
    <Container>
      <Content viewMode={viewMode}>
        <Header>
          <Brand>
            <img src={BaruLogo} alt="BaruPay" />
            <Tag>Simulador</Tag>
          </Brand>
          <RiCloseLine onClick={() => {
            if (viewMode === 'iFrame') {
              triggerToParent();
              return;
            }
            handleModalClose();
          }}
          />
        </Header>
        <DynamicContent>
          <OnboardingContext.Provider
            value={{
              onboardingData,
              setOnboardingData,
              barcodeResponse,
              setBillPaymentResponse,
              numberOfInstallments,
              setNumberOfInstallments,
              next,
              prev,
            }}
          >
            <InstallmentValue nextPage={nextPage} viewMode={viewMode} />
          </OnboardingContext.Provider>
        </DynamicContent>
      </Content>
    </Container>
  );
};

export default SimulatorModal;
