import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;

    display: flex;
    flex-direction: column;

    min-height: -webkit-fill-available;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary05};
    padding: 24px;

    > h2 {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 600;
      margin: 16px 0;
    }

    > p {
      ${theme.texts.bodyParagraphMD};
      margin: 16px 0;
    }

    > span {
      ${theme.texts.bodyParagraphMD};
      color: ${theme.colors.primaryBlack40};
    }
  `}
`;

export const Tag = styled.div`
  ${({ theme }) => css`
  ${theme.texts.bodyParagraphXS};
  text-transform: uppercase;

  width: 100%;
  max-width: 86px;
  padding: 5px 10px;

  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  border-radius: 4px;
  `}
`;

export const Switch = styled.div`
  ${({ theme }) => css`
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
          background-color: #2196f3;
        }

        &:focus + .slider {
          box-shadow: 0 0 1px #2196f3;
        }

        &:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.primary};
      -webkit-transition: 0.4s;
      transition: 0.4s;

      border-radius: 12px;

      &:before {
        position: absolute;
        content: '';
        height: 12px;
        width: 12px;
        left: 2px;
        bottom: 2px;
        background-color: ${theme.colors.primary};
        -webkit-transition: 0.4s;
        transition: 0.4s;

        border-radius: 50%;
      }
    }
  `}
`;

export const WarningBox = styled.div`
   ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 16px 18px 16px 16px;
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    margin-top: 16px;

    > svg {
      width: 100%;
      max-width: 20px;
      font-size: 2rem;
      margin-right: 10px;

      color: ${theme.colors.yellow};
    }

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 2.2rem;
      font-size: 1.3rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }

      > a {
        ${theme.texts.bodyParagraphSM};
        color: ${theme.colors.primaryBlack00};
        text-decoration: underline;
      }
    }
  `};
`;
