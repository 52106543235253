import styled, { css } from 'styled-components';

interface ButtonProps {
  backgroundColor?: string;
}

export const Container = styled.div<ButtonProps>`
  ${({ theme }) => css`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    min-height: -webkit-fill-available;
    height: 100vh;
    z-index: 1001;

    position: absolute;
    top: 0;
    left: 0;
  `}
`;

export const Content = styled.div<ButtonProps>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 550px;
    border-radius: 6px;
    width: 100%;
    z-index: 1000;
    background-color: ${theme.colors.white};
    padding: 50px;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    @media (max-width: 480px) {
      max-width: 350px;
      padding: 44px;
    }

    > img {
      width: 100%;
      max-width: 102px;
      text-align: center;
      margin-bottom: 24px;
    }

    > svg {
      font-size: 4.4rem;
      margin: 0 auto 24px;
    }

    > h1 {
      ${theme.texts.titleHeadingLG};
      margin-bottom: 12px;
    }

    > p {
      ${theme.texts.bodyParagraphLG};
      margin-bottom: 24px;
    }

    > span {
      ${theme.texts.buttonTextSM};
    }
  `}

  @media(max-width: 480px) {
    ${(props) => props.backgroundColor === 'timeNotAllowed'
      && css`
        > h1 {
          font-size: 2.2rem;
        }

        > p {
          font-size: 1.4rem;
          line-height: 2.2rem;
        }
      `}
  }

  > button {
    margin-bottom: 24px;
    ${(props) => props.backgroundColor === 'confirmation'
      && css`
        background-color: #56e04e;
      `}

    ${(props) => props.backgroundColor === 'sucess'
      && css`
        background-color: #1e1e1e;
      `}

      ${(props) => props.backgroundColor === 'error'
      && css`
        background-color: #1e1e1e;
      `}

      ${(props) => props.backgroundColor === 'alert'
      && css`
        background-color: #e24a4a;
      `}

      ${(props) => props.backgroundColor === 'timeNotAllowed'
      && css`
        background-color: #1e1e1e;
        margin-bottom: 0;
      `}

    color: #FFFFFF;
    width: 100%;

    ${({ theme }) => css`
      &:last-child {
        background-color: white;
        color: ${theme.colors.primaryBlack00};
        padding: 0;
        margin-bottom: 0;
      }
    `}
  }
`;
