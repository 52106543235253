import React, { ReactElement } from 'react';
import {
  RiShieldCheckLine, RiTimeLine, RiCloseCircleLine, RiAlertLine,
} from 'react-icons/ri';
import styled from 'styled-components';
import Button from '../Button';
import { AlertModalProps } from './AlertModal.spec';
import AlertIcon from '../../assets/AlertIcon.svg';

import { Container, Content } from './styles';

export const IconShieldCheck = styled(RiShieldCheckLine)`
  color: #56E04E;
`;

export const IconTimeLine = styled(RiTimeLine)`
  color: #E0C04E;
`;

export const IconClose = styled(RiCloseCircleLine)`
  color: #E24A4A;
`;

export const IconAlert = styled(RiAlertLine)`
  color: #E24A4A;
`;

const AlertModal = ({
  title,
  text,
  messageButton,
  messageClose,
  typeAlert,
  onClick,
  cancel,
  isLoading,
}: AlertModalProps): ReactElement => (
  <Container>
    <Content backgroundColor={typeAlert}>
      {typeAlert === 'sucess' && <IconTimeLine />}
      {typeAlert === 'error' && <IconClose />}
      {typeAlert === 'alert' && <IconAlert />}
      {typeAlert === 'confirmation' && <IconShieldCheck />}
      {typeAlert === 'timeNotAllowed' && <img src={AlertIcon} alt="Horário não permitido" /> }
      <h1>{title}</h1>
      <p>{text}</p>
      {messageButton && (
        <Button message={messageButton} onClick={onClick} isLoading={isLoading} />
      )}
      {messageClose && (
        <Button message={messageClose} onClick={cancel} />
      )}
    </Content>
  </Container>
);

export default AlertModal;
