import styled, { css } from 'styled-components';

export const Section = styled.section``;

export const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const ActionsButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.primaryBlack10};
      background-color: ${theme.colors.white};
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      > svg {
        font-size: 1.2rem;
      }
    }

    @media (max-width: 480px) {
      > button {
        padding: 0;
        border: none;
        background-color: transparent;
        margin-right: 12px;

        &:first-child {
          color: ${theme.colors.error};

          &:disabled {
            opacity: 0.5;
          }
        }

        &:last-child {
          margin-right: 0;

          color: ${theme.colors.primary};
        }

        > svg {
          font-size: 1.2rem;
        }
      }
    }
  `}
`;

export const BtnDelete = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.error} !important;

    > svg {
      color: ${theme.colors.white};
    }
  `}
`;

export const StatusTag = styled.span<{ status?: string }>`
  ${({ theme, status }) => css`
    padding: 6px 10px;
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    background-color: ${theme.colors.yellow}10;
    color: ${theme.colors.yellow};

    @media (max-width: 375px) {
      font-size: 0.9rem;
    }

    ${status === 'pending'
    && css`
      background-color: ${theme.colors.yellow}10;
      color: ${theme.colors.yellow};
    `};

    ${status === 'liquidated'
    && css`
      background-color: ${theme.colors.primary}10;
      color: ${theme.colors.primary};
    `};

    ${status === 'rejected'
    && css`
      background-color: ${theme.colors.red};
      color: ${theme.colors.error};
    `};
    
    ${status === 'approved'
    && css`
      background-color:rgba(78, 171, 224, 0.1);
      color: #4EABE0;
    `};
  `}
`;
