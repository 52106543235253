import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

import { RiAlertFill, RiCheckLine, RiSecurePaymentFill } from 'react-icons/ri';

import { useFormik } from 'formik';
import BaruSymbol from '../../../assets/BaruSymbol.svg';
import Button from '../../Button';
import Checkbox from '../../Checkbox';

import { InputControl } from '../../../styles/components';
import { getIssuerLogo } from '../../../utils/issuerLogos';

import { monthOptions, yearOptions } from '../../../utils/monthYearOptions';

import { formatMoney } from '../../../utils/formatters';

import {
  Step,
  StepForm,
  ImgCard,
  InputControlRow,
  SaveCardBox,
  FinalValueInformations,
  FinalValueBox,
  FinalValueBrand,
  FinalValueTexts,
  WarningBox,
  NavigationButtons,
} from './styles';
import { OrderCreditCardInfo } from '../../../types/order';
import { OrderRequestContext } from '..';
import InputMask from '../../MaskedInput';
import { creditCardMask, securityCodeMask } from '../../../utils/masks';
import ErrorMessage from '../../ErrorMessage';

export const PaymentData = (): ReactElement => {
  const {
    next,
    prev,
    creditCardInfo,
    setCreditCardInfo,
    totalValue,
    installmentValue,
    numberOfInstallments,
  } = useContext(OrderRequestContext);

  const [month, setMonth] = useState<number>(() => monthOptions?.[0]?.value);
  const [year, setYear] = useState<number>(() => yearOptions?.[0]?.value);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    holder: Yup.string().required(
      'Campo "Nome impresso no cartão" é obrigatório',
    ),
    cardNumber: Yup.string().required('Campo "N° do cartão" é obrigatório'),
    securityCode: Yup.string().required('Campo "CVV" é obrigatório'),
  });

  const formik = useFormik<OrderCreditCardInfo>({
    initialValues: {
      holder: creditCardInfo.holder || '',
      cardNumber: creditCardInfo.cardNumber || '',
      expirationDate: creditCardInfo.expirationDate || '',
      securityCode: creditCardInfo.securityCode || '',
      save: creditCardInfo.save || false,
    },
    onSubmit(values) {
      setIsLoading(true);
      setCreditCardInfo(values);
      setTimeout(() => {
        setIsLoading(false);
        next?.();
      }, 1500);
    },
    validationSchema,
  });

  const cardLogo = useMemo(
    (): string | undefined => getIssuerLogo(formik.values.cardNumber?.slice(0, 6)),
    [formik.values.cardNumber],
  );

  useEffect(() => {
    if (month && year) {
      formik.setFieldValue(
        'expirationDate',
        `${month > 9 ? month : `0${month}`}/${year}`,
      );
    }
  }, [month, year]);

  useEffect(() => {
    if (creditCardInfo?.expirationDate) {
      const [monthString, yearString] = creditCardInfo?.expirationDate.split('/');
      setMonth(+monthString);
      setYear(+yearString);
    }
  }, [creditCardInfo?.expirationDate]);

  const filteredMonthOptions = useMemo(() => {
    if (year === new Date().getFullYear()) {
      const currentMonth = new Date().getMonth();
      return monthOptions.filter(({ value }) => value > currentMonth);
    }
    return monthOptions;
  }, [year]);

  useEffect(() => {
    const firstMonth = filteredMonthOptions[0].value;
    if (month < firstMonth) {
      setMonth(firstMonth);
    }
  }, [filteredMonthOptions, month]);

  return (
    <Step>
      <StepForm onSubmit={formik.handleSubmit}>
        <InputControl
          className={
            formik.errors.holder && formik.touched.holder ? 'error' : ''
          }
        >
          <small>Nome impresso no cartão:</small>
          <input
            type="text"
            placeholder="João da Silva"
            name="holder"
            value={formik.values.holder}
            onChange={formik.handleChange}
          />

          {formik.errors.holder && formik.touched.holder ? (
            <ErrorMessage message={formik?.errors?.holder} />
          ) : null}
        </InputControl>

        <InputControl
          className={
            formik.errors.cardNumber && formik.touched.cardNumber ? 'error' : ''
          }
        >
          <small>Nº do cartão:</small>
          <InputMask
            name="cardNumber"
            value={formik.values.cardNumber}
            onChange={({ target: { value } }) => formik.setFieldValue('cardNumber', value?.replace(/\D/g, ''))}
            placeholder="**** **** **** ****"
            mask={creditCardMask}
            guide={false}
          />
          {!!cardLogo && (
            <ImgCard src={cardLogo} alt="Credit card issuer logo" />
          )}

          {formik.errors.cardNumber && formik.touched.cardNumber ? (
            <ErrorMessage message={formik?.errors?.cardNumber} />
          ) : null}
        </InputControl>

        <InputControlRow>
          <InputControl>
            <small>Mês:</small>
            <select
              name="month"
              value={month}
              onChange={({ target: { value } }) => setMonth(+value)}
            >
              {filteredMonthOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </InputControl>

          <InputControl>
            <small>Ano:</small>
            <select
              name="year"
              value={year}
              onChange={({ target: { value } }) => setYear(+value)}
            >
              {yearOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </InputControl>

          <InputControl
            className={
              formik.errors.securityCode && formik.touched.securityCode
                ? 'error'
                : ''
            }
          >
            <small>CVV:</small>
            <InputMask
              name="securityCode"
              value={formik.values.securityCode}
              onChange={formik.handleChange}
              placeholder="***"
              mask={securityCodeMask}
              guide={false}
            />

            {formik.errors.securityCode && formik.touched.securityCode ? (
              <ErrorMessage message={formik?.errors?.securityCode} />
            ) : null}
          </InputControl>
        </InputControlRow>

        <SaveCardBox>
          <Checkbox
            name="save"
            checked={formik.values.save}
            onChange={({ target }) => formik.setFieldValue('save', (target as any).checked)}
          />
          Salvar os dados do meu cartão para futuras transações.
        </SaveCardBox>

        <FinalValueInformations>
          <FinalValueBox>
            <FinalValueBrand>
              <RiSecurePaymentFill />
            </FinalValueBrand>
            <FinalValueTexts>
              <small>Valor final:</small>
              <b>
                {formatMoney(installmentValue)}

                <span>
                  (
                  {`${numberOfInstallments}x`}
                  )
                </span>
              </b>

              <span>{formatMoney(totalValue)}</span>
            </FinalValueTexts>
          </FinalValueBox>
          <img src={BaruSymbol} alt="Baru" />
        </FinalValueInformations>

        <WarningBox>
          <RiAlertFill />
          <p>
            <b>Atenção:</b>
            Você precisará ter em seu cartão o limite no valor total da
            transação para que sua solicitação prossiga.
          </p>
        </WarningBox>

        <NavigationButtons>
          <Button
            message="Voltar"
            type="button"
            onClick={() => {
              setCreditCardInfo(formik.values);
              prev();
            }}
          />
          <Button message="Continuar" type="submit" isLoading={isLoading} />
        </NavigationButtons>
      </StepForm>
    </Step>
  );
};

export default PaymentData;
