import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    position: relative;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};
`;

export const TermsOfUserContent = styled.div`
  margin: 0 0 24px 0;
`;

export const TermsOfUse = styled.div`
  ${({ theme }) => css`
    border-radius: 6px;
    background-color: ${theme.colors.primary10};
    padding: 16px;
    height: 58px;
    ${theme.texts.buttonTextXS};
    color: ${theme.colors.primaryBlack00};

    display: flex;
    align-items: center;

    > label {
      cursor: pointer;
      display: flex;
      align-items: center;

      > svg {
        border-radius: 3px;
      }
    }

    > button {
      background-color: transparent;
      color: ${theme.colors.primary};
      margin-left: 3px;
      ${theme.texts.buttonTextXS};
    }

    .checkbox {
      background: transparent;
      border: 1px ${theme.colors.primary} solid;
      margin-right: 12px;
    }

    .checkbox--active {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }
  `};
`;

export const TermsOfUseModal = styled.div<{ viewMode?: string }>`
  ${({ theme, viewMode }) => css`
    background-color: ${theme.colors.white};
    width: 100%;
    max-width: 724px;
    padding: 48px 36px;
    border-radius: 4px;

    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    bottom: 30px;
    z-index: 1000;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    ${viewMode === 'iFrame'
    && css`
      max-width: 100%;
      top: 0;
      bottom: 0;
      border-radius: 0;
    `};

    > h3 {
      letter-spacing: -0.2px;
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.buttonTextMD};
    }

    > button {
      background-color: transparent;
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextSM};
      margin: 0 auto;
      margin-top: auto;
    }
  `};
`;

export const TermsOfUseModalTexts = styled.div`
  ${({ theme }) => css`
    margin: 24px 0 0 0;
    
    > p {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.bodyParagraphMD};
      margin-bottom: 24px;
      line-height: 24px;
    }

     > h1 {
        margin-bottom: 10px;
        font-size: 1.4rem;
      }

      > h2 {
        margin: 10px 0;
        font-size: 1.4rem;
        text-align: center;
      }

      > h3 {
        margin: 10px 0;
        font-size: 1.6rem;
      }

      > p {
        ${theme.texts.bodyParagraphMD};
      }

    .markdown {
      > h1 {
        margin-bottom: 10px;
        font-size: 1.4rem;
      }

      > h2 {
        margin: 10px 0;
        font-size: 1.4rem;
      }

      > h3 {
        margin: 10px 0;
        font-size: 1.6rem;
      }

      > p {
        ${theme.texts.bodyParagraphMD};
      }
    }
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;
