import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';

import { InputControl } from '../../styles/components';
import Button from '../../components/Button';
import BaruLogo from '../../assets/BaruLogo.svg';
import Checkbox from '../../components/Checkbox';

import {
  Container, BackgroundImage, MainContent, Header, SignInForm, StayConnected,
} from './styles';
import PasswordInput from '../../components/PasswordInput';
import { SignInInfo } from '../../types/auth';
import { getErrorMessage } from '../../utils/errorCodes';

const SignInOperator: React.FC = () => {
  const { authenticated, login } = useAuth();

  const initialValues: SignInInfo = {
    identifier: '',
    password: '',
    keepConnected: true,
  };

  const validationSchema = Yup.object().shape({
    identifier: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  async function handleLogin({
    identifier,
    password,
    keepConnected,
  }: SignInInfo): Promise<void> {
    try {
      await login({
        identifier,
        password,
        keepConnected,
      });
      toast.success('Logado com sucesso!');
    } catch (err: any) {
      toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Erro ao tentar fazer login!');
    }
  }

  if (authenticated) {
    return <Navigate to="/portal" replace />;
  }

  return (
    <Container>
      <BackgroundImage>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(state) => {
            handleLogin(state);
          }}
        >
          {() => (
            <MainContent>
              <Header>
                <img src={BaruLogo} alt="" />
                <span>Login</span>
                <a href="/">
                  Voltar ao site
                  {' '}
                  <FiArrowUpRight />
                  {' '}
                </a>
              </Header>
              <h1>
                Entre em sua conta
              </h1>
              <small>Gerenciamento completo das suas operações</small>
              <Form>

                <SignInForm>
                  <InputControl>
                    <small>Seu e-mail</small>
                    <Field
                      name="identifier"
                      type="email"
                      placeholder="nome@empresa.com.br"
                      autoComplete="email"
                    />
                  </InputControl>

                  <Field
                    component={PasswordInput}
                    name="password"
                    autoComplete="password"
                  />

                  {/* <StayConnected>
                    <Field component={Checkbox} name="keepConnected" />
                    <small>Permanecer conectado</small>
                  </StayConnected> */}
                  <Button type="submit" message="Entrar" />
                </SignInForm>
              </Form>

              <span>Esqueceu sua senha? Contate o administrador</span>
            </MainContent>
          )}
        </Formik>
      </BackgroundImage>
    </Container>
  );
};

export default SignInOperator;
