import { BarcodeRequest, BillPayment } from '../types/billPayment';
import { StrapiEntity, StrapiResponse } from '../types/core';
import { Simulator, SimulatorResponse } from '../types/simulator';
import api from './api';

export default {
  async findByBarcode({
    barcode,
    digitable,
  }: BarcodeRequest): Promise<StrapiEntity<BillPayment>> {
    return (
      await api.post<StrapiResponse<StrapiEntity<BillPayment>, any>>(
        '/bill-payments/barcode',
        {
          barcode,
          digitable,
        },
      )
    )?.data?.data;
  },
  async simulate({
    numberOfInstallments,
    paymentValue,
  }: Simulator): Promise<SimulatorResponse> {
    return (
      await api.post<SimulatorResponse>('/bill-payments/simulate', {
        numberOfInstallments,
        paymentValue,
      })
    )?.data;
  },
};
