import React, { ReactElement, useState, useEffect } from 'react';

import {
  RiCloseLine,
  RiMailCheckFill,
  RiSecurePaymentFill,
  RiCheckLine,
} from 'react-icons/ri';
import { toast } from 'react-toastify';

import Button from '../../../../../components/Button';
import { customerService, orderService } from '../../../../../services';
import { CustomerResponseFull } from '../../../../../types/customer';
import { OrderResponse, OrderResponseFull } from '../../../../../types/order';
import { getErrorMessage } from '../../../../../utils/errorCodes';
import {
  formatDateWithHour,
  formatMoney,
} from '../../../../../utils/formatters';
import { useAuth } from '../../../../../contexts/AuthContext';

import CustomerData from '../ValidationModal/CustomerData';
import RequestData from '../ValidationModal/RequestData';

import {
  Container,
  Content,
  Header,
  Brand,
  Tag,
  Breadcrumb,
  ContentLeft,
  SecurePaymentIcon,
  ValueRequest,
  ContentRight,
  Divider,
  DynamicContent,
  FeedbackModal,
  FeedbackModalContent,
  TextControl,
  TextAreaControl,
} from './styles';

import { stepTexts, ViewModalProps } from './viewModal.spec';

const ViewModal = ({ handleClose, id, type }: ViewModalProps): ReactElement => {
  const { role } = useAuth();

  const [currentStep, setCurrentStep] = useState<'CD' | 'RD'>('CD');
  const [order, setOrder] = useState<OrderResponseFull>();
  const [customer, setCustomer] = useState<CustomerResponseFull>();
  const [isVisibleFeedbackModal, setIsVisibleFeedbackModal] = useState<boolean>(false);

  const requestStatus = {
    rejected: type === 'CD' ? 'Negado' : 'Negada',
    approved: type === 'CD' ? 'Aprovado' : 'Aprovada',
    liquidated: type === 'CD' ? 'Pago' : 'Paga',
  };

  const customerId = order
    ? order?.attributes?.customer?.data?.id
    : customer?.id;

  const orderId = order?.id;

  const finalValue = order
    ? order?.attributes?.paymentValue + order?.attributes?.serviceFee
    : 0;
  const installmentValue = order
    ? finalValue / order?.attributes?.numberOfInstallments
    : 0;

  const stepsOrders: any = {
    CD: customerId ? <CustomerData customerId={customerId} /> : <></>,
    RD: <RequestData order={order} />,
  };

  useEffect((): void => {
    async function fetchData(): Promise<void> {
      try {
        if (type === 'RD') {
          const { data } = await orderService.getOne(id);
          setOrder(data);
        } else {
          const { data } = await customerService.getOne(id);
          setCustomer(data);
        }
      } catch (err: any) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    handleStepRedirect();
  }, [order]);

  // TO DO: Get status of request or customer and redirect to correct step
  async function handleStepRedirect(): Promise<void> {
    try {
      if (
        order?.attributes?.customer?.data?.attributes?.status === 'approved'
      ) {
        setCurrentStep('RD');
      } else {
        setCurrentStep('CD');
      }
    } catch {
      toast.warning('Não foi possível realizar a operação');
    }
  }

  async function resendEmail(): Promise<void> {
    try {
      if (orderId) {
        await orderService.resendReceipt(orderId);
        toast.success('Comprovante reenviado!');
      }
    } catch (err: any) {
      toast.warning('Não foi possível reenviar o comprovante');
    }
  }

  return (
    <Container>
      <Content>
        <Header>
          <Brand>
            <h2>
              <p>Cliente </p>
              {order?.attributes?.customer?.data?.attributes?.user?.data?.attributes?.name
                || customer?.attributes?.user?.data?.attributes?.name}
            </h2>
            <Tag
              status={customer?.attributes?.status || order?.attributes?.status}
            >
              {
                requestStatus[
                  (customer?.attributes?.status
                    || order?.attributes?.status) as keyof typeof requestStatus
                ]
              }
            </Tag>
          </Brand>
          <RiCloseLine onClick={handleClose} />
        </Header>

        <Breadcrumb>
          <ContentLeft>
            {type === 'RD' && (
              <>
                <SecurePaymentIcon>
                  <RiSecurePaymentFill />
                </SecurePaymentIcon>
                <ValueRequest>
                  <p>Valor da soliticação:</p>
                  <p>
                    <strong>{formatMoney(finalValue)}</strong>
                    {' '}
                    (
                    {formatMoney(installmentValue)}
                    {' '}
                    em
                    {' '}
                    {order?.attributes?.numberOfInstallments
                      ? order?.attributes?.numberOfInstallments
                      : '1'}
                    x )
                  </p>
                </ValueRequest>

                {order?.attributes?.status !== 'rejected' && (
                <Button
                  message="Reenviar comprovante"
                  onClick={() => resendEmail()}
                />
                )}
              </>
            )}
            {order?.attributes?.status === 'rejected' && (
              <Button
                message="Ver parecer"
                onClick={() => setIsVisibleFeedbackModal(true)}
              />
            )}
          </ContentLeft>
        </Breadcrumb>
        <Divider />
        <DynamicContent>{stepsOrders[currentStep]}</DynamicContent>
      </Content>
      {isVisibleFeedbackModal && (
        <FeedbackModal>
          <FeedbackModalContent>
            <h4>
              Parecer
              <RiCloseLine onClick={() => setIsVisibleFeedbackModal(false)} />
            </h4>

            <TextAreaControl>
              <small>Motivo da negativa:</small>
              <textarea
                required
                value={
                  order?.attributes?.orderReview?.data?.attributes
                    ?.description
                  || customer?.attributes?.customerReview?.data?.attributes
                    ?.description
                }
                disabled
              />
            </TextAreaControl>

            <TextControl>
              <span>
                {`Operador ${
                  order?.attributes?.orderReview?.data?.attributes?.operator
                    ?.data?.attributes?.name
                  || customer?.attributes?.customerReview?.data?.attributes
                    ?.operator?.data?.attributes?.name
                } recusou dia ${formatDateWithHour(
                  order?.attributes?.orderReview?.data?.attributes.createdAt
                    || customer?.attributes?.customerReview?.data?.attributes
                      .createdAt,
                )}.`}
              </span>
            </TextControl>

          </FeedbackModalContent>
        </FeedbackModal>
      )}
    </Container>
  );
};

export default ViewModal;
