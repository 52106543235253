import styled, { css } from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const ContentTitle = styled.p`
  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;

    margin-bottom: 24px;

    color: ${theme.colors.primary};
  `}
`;

export const ContentLeft = styled.div`
  width: 100%;
`;

export const Divider = styled.div`
  margin: 40px 44px 0;
  ${({ theme }) => css`
    border-left: 1px dashed ${theme.colors.primaryBlack20};
  `}
`;

export const ContentRight = styled.div`
  width: 100%;
`;

export const Datas = styled.div``;

export const ItemDatas = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 1.6rem;

    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    > strong {
      width: 100%;
      max-width: 130px;
      margin-right: 24px;
      color: ${theme.colors.primaryBlack00};
    }

    > p {
      width: 100%;
      font-weight: 500;

      color: ${theme.colors.primaryBlack40};
    }

    > span {
      width: 100%;
      border-bottom: 1px dashed ${theme.colors.primary20};
    }
  `}
`;

export const Status = styled.section`
  display: flex;
  justify-content: space-between;

  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
`;

export const LastStatus = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlack50};
  `}
`;

export const LastUpdate = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlack00};
  `}
`;

export const CardView = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 315px;
    padding: 12px 18px 12px 12px;

    display: flex;

    border-radius: 6px;
    background-color: ${theme.colors.primary10};
  `}
`;
export const ImageCard = styled.img``;
export const CardInfo = styled.div`
  margin-left: 18px;
`;
export const CardNumber = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphSM};
    color: ${theme.colors.primaryBlack00};
  `}
`;
export const CardName = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphXS};
    color: ${theme.colors.primaryBlack40};
  `}
`;
