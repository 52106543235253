import { string } from 'prop-types';
import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { RiUpload2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import { userService } from '../../services';
import Button from '../Button';

import {
  Container,
  PictureContent,
  Picture,
  PictureAction,
  ActionContent,
  ButtonWrapper,
  LoadingCircle,
  Circle,
} from './styles';

interface Props {
  name: string | undefined;
  thumbnailURL: string | undefined;
  onChange: () => Promise<void>;
}

const ProfilePicture = ({
  name,
  thumbnailURL,
  onChange,
}: Props): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { fetchUser } = useAuth();

  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleChange = async (e: any): Promise<void> => {
    const { files } = e.target;

    const newFile = files?.[0] || null;

    setIsLoading(true);
    try {
      await userService.editImage({ avatar: newFile });
      await onChange?.();
      fetchUser();
      toast.success('Foto de perfil alterada com sucesso!');
    } catch (err: any) {
      console.log(toast.error('Nao foi possível atualizar a foto de perfil!'));
    } finally {
      setIsLoading(false);
    }
  };

  const removeFile = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await userService.removeImage('avatar');
      await onChange?.();
      fetchUser();
      toast.success('Foto de perfil removida com sucesso!');
    } catch (error) {
      toast.error('Nao foi possível remover a foto de perfil!');
    } finally {
      if (fileInput.current) {
        fileInput.current.value = '';
      }
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <h2>Foto de perfil</h2>
      <PictureContent>
        <Picture>

          {isLoading ? (
            <LoadingCircle>
              <Circle />
            </LoadingCircle>
          ) : (
            <>
              {thumbnailURL ? (
                <img src={thumbnailURL} alt="Foto de perfil" />
              ) : (
                <p>{name?.[0] || '?'}</p>
              )}

            </>
          )}
        </Picture>
        <PictureAction>
          <h2>Envie sua foto de perfil</h2>
          <p>Sua foto deve conter o tamanho mínimo de 50px x 50px</p>

          {!isLoading && (
            <ActionContent>
              <ButtonWrapper>

                <label htmlFor="file-input">

                  <p>
                    Enviar foto
                    {' '}
                    <RiUpload2Line />
                  </p>
                  <input
                    id="file-input"
                    name="file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                    ref={fileInput}
                  />
                </label>

              </ButtonWrapper>
              {thumbnailURL ? (
                <Button message="Remover foto" onClick={removeFile} />
              ) : (
                <p>Nenhuma foto adicionada.</p>
              )}
            </ActionContent>
          )}

        </PictureAction>
      </PictureContent>
    </Container>
  );
};

export default ProfilePicture;
