import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  RiArrowLeftLine,
  RiCameraFill,
  RiCameraSwitchFill,
} from 'react-icons/ri';

import Webcam, { WebcamProps } from 'react-webcam';

import {
  Content,
  CaptureButton,
  ChangeCamera,
  ConfirmButton,
  Container,
  Mask,
  Preview,
} from './styles';

import { DocumentScanModalProps } from './DocumentScanModal.spec';

const constraints: MediaTrackConstraints = {
  // width: 720,
  // height: 1280,
};

type DefaultConfig =
  | 'height'
  | 'width'
  | 'screenshotQuality'
  | 'audio'
  | 'forceScreenshotSourceSize'
  | 'imageSmoothing'
  | 'screenshotFormat';

const config: Pick<WebcamProps, DefaultConfig> = {
  height: '100%',
  width: '100%',
  audio: false,
  screenshotFormat: 'image/jpeg',
  screenshotQuality: 1,
  forceScreenshotSourceSize: true,
  imageSmoothing: false,
};

export const DocumentScanModal = ({
  name,
  onClose,
  onCapture,
  mask,
}: DocumentScanModalProps): ReactElement => {
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [deviceIndex, setDeviceIndex] = useState(0);

  useEffect(() => {
    async function findDevices(): Promise<void> {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const videoInputList = mediaDevices.filter(
        ({ kind }) => kind === 'videoinput',
      );
      setDevices(videoInputList);
    }
    const interval = setInterval(findDevices, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const videoConstraints = useMemo((): MediaTrackConstraints | undefined => {
    const deviceId = devices?.[deviceIndex]?.deviceId;
    if (deviceId) {
      return {
        ...constraints,
        deviceId,
      };
    }
    return undefined;
  }, [deviceIndex]);

  const changeDevice = (): void => {
    setDeviceIndex((idx) => (idx + 1 >= devices.length ? 0 : idx + 1));
  };

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      const blob = await (await fetch(imageSrc)).blob();
      const file = new File(
        [blob],
        `capture-${name || 'default'}-${Date.now()}.jpg`,
        {
          type: config.screenshotFormat,
        },
      );
      setImgSrc(imageSrc);
      setImgFile(file);
    }
  }, [name]);

  const confirm = (): void => {
    if (imgFile) {
      onCapture?.(imgFile);
    }
  };

  const goBack = (): void => {
    if (imgSrc) {
      setImgFile(null);
      setImgSrc(null);
    } else {
      onClose?.();
    }
  };

  return (
    <Container>
      <RiArrowLeftLine onClick={goBack} />
      {mask && <Mask src={mask} alt="DocumentMask" />}
      <h4>
        Posicione a frente do seu documento na marcação abaixo
      </h4>
      {imgSrc ? (
        <>
          <Preview src={imgSrc} alt="Imagem capturada" />
          <ConfirmButton type="button" onClick={confirm}>
            Usar essa foto
          </ConfirmButton>
        </>
      ) : (
        <>
          <Webcam
            {...config}
            videoConstraints={videoConstraints}
            ref={webcamRef}
          />
          <Content>
            <CaptureButton type="button" onClick={capture}>
              <RiCameraFill />
            </CaptureButton>
            {devices.length > 1 && (
              <ChangeCamera type="button" onClick={changeDevice}>
                <RiCameraSwitchFill />
              </ChangeCamera>
            )}
          </Content>
        </>
      )}
    </Container>
  );
};

export default DocumentScanModal;
