import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RiCloseLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { InputControl } from '../../../../../styles/components';
import BaruSymbolHeader from '../../../../../assets/BaruSymbolHeader.svg';

import { OrderCreditCardInfo } from '../../../../../types/order';
import ErrorMessage from '../../../../../components/ErrorMessage';
import InputMask from '../../../../../components/MaskedInput';
import { creditCardMask, securityCodeMask } from '../../../../../utils/masks';
import { getIssuerLogo } from '../../../../../utils/issuerLogos';
import {
  monthOptions,
  yearOptions,
} from '../../../../../utils/monthYearOptions';
import {
  Container,
  Content,
  Header,
  Left,
  Right,
  Form,
  ImgCard,
  InputControlRow,
} from './styles';
import { Tag } from '../../../../../components/Navbar/styles';
import Button from '../../../../../components/Button';
import { creditCardService } from '../../../../../services';
import { getErrorMessage } from '../../../../../utils/errorCodes';
import { CreditCard, CreditCardCore } from '../../../../../types/creditCard';

interface Props {
  handleClose: () => void;
  onSubmit: (values: CreditCardCore) => void;
}

const NewCard = ({ handleClose, onSubmit }: Props): ReactElement => {
  const [month, setMonth] = useState<number>(() => monthOptions?.[0]?.value);
  const [year, setYear] = useState<number>(() => yearOptions?.[0]?.value);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    holder: Yup.string().required(
      'Campo "Nome impresso no cartão" é obrigatório',
    ),
    cardNumber: Yup.string().required('Campo "N° do cartão" é obrigatório'),
    securityCode: Yup.string().required('Campo "CVV" é obrigatório'),
  });

  const formik = useFormik<CreditCardCore>({
    initialValues: {
      holder: '',
      cardNumber: '',
      expirationDate: '',
      securityCode: '',
      brand: 0,
    },
    async onSubmit(values) {
      setIsLoading(true);
      await onSubmit(values);
      setIsLoading(false);
    },
    validationSchema,
  });

  const cardLogo = useMemo(
    (): string | undefined => getIssuerLogo(formik.values.cardNumber?.slice(0, 6)),
    [formik.values.cardNumber],
  );

  useEffect(() => {
    if (month && year) {
      formik.setFieldValue(
        'expirationDate',
        `${month > 9 ? month : `0${month}`}/${year}`,
      );
    }
  }, [month, year]);

  const filteredMonthOptions = useMemo(() => {
    if (year === new Date().getFullYear()) {
      const currentMonth = new Date().getMonth();
      return monthOptions.filter(({ value }) => value > currentMonth);
    }
    return monthOptions;
  }, [year]);

  useEffect(() => {
    const firstMonth = filteredMonthOptions[0].value;
    if (month < firstMonth) {
      setMonth(firstMonth);
    }
  }, [filteredMonthOptions, month]);

  return (
    <Container>
      <Content>
        <Header>
          <img src={BaruSymbolHeader} alt="BaruSymbol" />
          <Tag>Novo cartão</Tag>
          <RiCloseLine onClick={handleClose} />
        </Header>
        <Form onSubmit={formik.handleSubmit}>
          <InputControl
            className={
            formik.errors.holder && formik.touched.holder ? 'error' : ''
          }
          >
            <small>Nome impresso no cartão:</small>
            <input
              type="text"
              placeholder="João da Silva"
              name="holder"
              value={formik.values.holder}
              onChange={formik.handleChange}
            />

            {formik.errors.holder && formik.touched.holder ? (
              <ErrorMessage message={formik?.errors?.holder} />
            ) : null}
          </InputControl>

          <InputControl
            className={
            formik.errors.cardNumber && formik.touched.cardNumber ? 'error' : ''
          }
          >
            <small>Nº do cartão:</small>
            <InputMask
              name="cardNumber"
              value={formik.values.cardNumber}
              onChange={({ target: { value } }) => formik.setFieldValue('cardNumber', value?.replace(/\D/g, ''))}
              placeholder="**** **** **** ****"
              mask={creditCardMask}
              guide={false}
            />
            {!!cardLogo && (
            <ImgCard src={cardLogo} alt="Credit card issuer logo" />
            )}

            {formik.errors.cardNumber && formik.touched.cardNumber ? (
              <ErrorMessage message={formik?.errors?.cardNumber} />
            ) : null}
          </InputControl>

          <InputControlRow>
            <InputControl>
              <small>Mês:</small>
              <select
                name="month"
                value={month}
                onChange={({ target: { value } }) => setMonth(+value)}
              >
                {filteredMonthOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </InputControl>

            <InputControl>
              <small>Ano:</small>
              <select
                name="year"
                value={year}
                onChange={({ target: { value } }) => setYear(+value)}
              >
                {yearOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </InputControl>
          </InputControlRow>

          <InputControl
            className={
            formik.errors.securityCode && formik.touched.securityCode
              ? 'error'
              : ''
          }
          >
            <small>CVV:</small>
            <InputMask
              name="securityCode"
              value={formik.values.securityCode}
              onChange={formik.handleChange}
              placeholder="***"
              mask={securityCodeMask}
              guide={false}
            />

            {formik.errors.securityCode && formik.touched.securityCode ? (
              <ErrorMessage message={formik?.errors?.securityCode} />
            ) : null}
          </InputControl>

          <Button
            message="Salvar"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Form>
      </Content>
    </Container>
  );
};

export default NewCard;
