import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* overflow: auto; */
  padding-bottom: 46px;

  @media (max-width: 480px) {
    /* padding-bottom: 100px; */
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const InfoBasic = styled.div`
  ${({ theme }) => css`
    > h2 {
      ${theme.texts.buttonTextMD};
    }
  `}
`;

export const Form = styled.form`
  padding-top: 24px;
`;

export const InputControlRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column;

      > button {
        width: 100%;
      }

      &:last-child {
        flex-direction: column-reverse;

        > p {
          margin-top: 24px;
        }
      }
    }

    > div {
      &:not(:last-child) {
        margin-right: 20px;

        @media (max-width: 480px) {
          margin-right: 0;
        }
      }
    }

    > p {
      ${theme.texts.bodyParagraphSM};
      color: ${theme.colors.primaryBlack50};
  
      > a {
       
        color: ${theme.colors.primary};
        font-weight: 600;
        text-decoration: underline;
      }
    }

    > button {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      height: 60px;
    }
  `}
`;
