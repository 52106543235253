import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${theme.colors.primaryBlack};
    margin-top: auto;
    > div {
      display: flex;

      &:first-child {
        opacity: 0.5;
      }

      > p {
        margin: 0 2px;
      }

      > span {
        margin: 0 0 0 16px;
      }

      > button {
        margin: 0 0 0 16px;
        opacity: 0.3;
        font-weight: 500;
        cursor: pointer;
        background-color: ${theme.colors.white};

        ${theme.texts.buttonTextSM};

        &.active {
          color: ${theme.colors.primary};
          opacity: 1;
          background-color: ${theme.colors.white};
        }

        > svg {
          font-size: 2.4rem;
        }
      }
    }
  `}
`;
