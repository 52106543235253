import React, {
  ReactElement, useMemo, useContext, useEffect, useState,
} from 'react';
import { RiSecurePaymentFill, RiAlertFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { OrderRequestContext } from '..';
import { formatMoney } from '../../../utils/formatters';
import { getIssuer, getIssuerByBrand, s2pTypes } from '../../../utils/issuerLogos';
import Button from '../../Button';
import BaruSymbol from '../../../assets/BaruSymbol.svg';

import {
  Step,
  MyCardsBox,
  RowItems,
  RowItem,
  CardView,
  ImageCard,
  CardInfo,
  CardNumber,
  CardName,
  FinalValueInformations,
  FinalValueBox,
  FinalValueBrand,
  FinalValueTexts,
  WarningBox,
  NavigationButtons,
} from './styles';
import Checkbox from '../../Checkbox';
import { creditCardService } from '../../../services';
import { CreditCard, CreditCardResponse } from '../../../types/creditCard';
import { getErrorMessage } from '../../../utils/errorCodes';

const MyCards = (): ReactElement => {
  const {
    prev, jump, installmentValue, numberOfInstallments, totalValue,
    selectedCreditCard, setSelectedCreditCard,
  } = useContext(OrderRequestContext);

  const [cards, setCards] = useState<CreditCardResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect((): void => {
    async function fetchData(): Promise<void> {
      try {
        setCards((await creditCardService.get()).data);
      } catch (err: any) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
      }
    }
    fetchData();
  }, []);

  return (
    <Step>
      <MyCardsBox>
        <h3>Meus cartões:</h3>
        <RowItems>
          {cards.map((card) => {
            const issuer = getIssuerByBrand(card?.attributes?.brand);
            return (
              <CardView key={card.id}>
                {!!issuer && <ImageCard src={issuer.logo} alt="Credit card issuer logo" />}
                <CardInfo>
                  <CardNumber>
                    (Crédito)
                    {' '}
                    {issuer?.name}
                    {' '}
                    com final ****
                    {' '}
                    {card.attributes.cardNumber}
                  </CardNumber>
                  <CardName>{card.attributes.holder}</CardName>
                </CardInfo>
                <Checkbox type="checkbox" name="select" checked={selectedCreditCard?.id === card.id} onChange={() => setSelectedCreditCard(card)} />
              </CardView>
            );
          })}
          <RowItem><Button message="Adicionar novo cartão +" onClick={() => jump(5)} /></RowItem>
        </RowItems>
      </MyCardsBox>
      <FinalValueInformations>
        <FinalValueBox>
          <FinalValueBrand>
            <RiSecurePaymentFill />
          </FinalValueBrand>
          <FinalValueTexts>
            <small>Valor final:</small>
            <b>
              {formatMoney(installmentValue)}

              <span>
                (
                {`${numberOfInstallments}x`}
                )
              </span>
            </b>
            <span>{formatMoney(totalValue)}</span>
          </FinalValueTexts>
        </FinalValueBox>
        <img src={BaruSymbol} alt="Baru" />
      </FinalValueInformations>

      <WarningBox>
        <RiAlertFill />
        <p>
          <b>Atenção:</b>
          Você precisará ter em seu cartão o limite no valor total da transação
          para que sua solicitação prossiga.
        </p>
      </WarningBox>

      <NavigationButtons>
        <Button
          message="Voltar"
          type="button"
          onClick={() => {
            prev();
          }}
        />
        <Button
          message="Continuar"
          onClick={() => {
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              jump(5);
            }, 1500);
          }}
          isLoading={isLoading}
          disabled={!cards?.length}
        />
      </NavigationButtons>
    </Step>
  );
};

export default MyCards;
