import {
  titleHeadingXXL,
  titleHeadingXL,
  titleHeadingLG,
  titleHeadingXXS,
  bodyParagraphXL,
  bodyParagraphLG,
  bodyParagraphMD,
  bodyParagraphSM,
  bodyParagraphXS,
  bodyParagraphXXS,
  buttonTextMD,
  buttonTextSM,
  buttonTextXS,
  alternativeTextXXS,
  alternativeTextXXXS,
} from './mixins';

const defaultTheme = {
  colors: {
    primary: '#56E04E',
    primary90: '#67E360',
    primary80: '#78E671',
    primary70: '#89E983',
    primary60: '#9AEC95',
    primary50: '#ABEFA7',
    primary40: '#BBF3B8',
    primary30: '#CCF6CA',
    primary20: '#DDF9DC',
    primary10: '#EEFCED',
    primary05: '#F7FDF6',
    black: '#000000',
    primaryBlack00: '#14121E',
    primaryBlack10: '#E8E8E8',
    primaryBlack20: '#D2D2D2',
    primaryBlack30: '#BBBBBB',
    primaryBlack40: '#A5A5A5',
    primaryBlack50: '#8F8F8F',
    primaryWhite05: '#292929',
    grey: '#E5E5E5',
    background: '#FFFFFF',
    backgroundGreen: '#EEFCED',
    backgroundWarning: '#FCF9ED',
    white: '#FFFFFF',
    yellow: '#E0C04E',
    warning: '#FDFCF6',
    error: '#E24A4A',
    red: '#FCEDED',
  },
  transitions: {
    default: '180ms ease-in-out',
    transform: '400ms ease-out',
  },
  texts: {
    titleHeadingXXL,
    titleHeadingXL,
    titleHeadingLG,
    titleHeadingXXS,
    bodyParagraphXL,
    bodyParagraphLG,
    bodyParagraphMD,
    bodyParagraphSM,
    bodyParagraphXS,
    bodyParagraphXXS,
    buttonTextMD,
    buttonTextSM,
    buttonTextXS,
    alternativeTextXXS,
    alternativeTextXXXS,
  },
};

export default defaultTheme;
