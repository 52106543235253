import { StrapiResponse } from '../types/core';
import { CreditCard, CreditCardCore, CreditCardResponse } from '../types/creditCard';
import { OrderCreditCardInfo } from '../types/order';
import api from './api';

export default {
  async get(): Promise<StrapiResponse<CreditCardResponse[], any>> {
    return (await api.get('/credit-cards/own'))?.data;
  },

  async create(creditCard : CreditCardCore): Promise<StrapiResponse<CreditCardResponse[], any>> {
    return (await api.post('/credit-cards/own', { data: creditCard }))?.data;
  },

  async delete(creditCardId: number): Promise<any> {
    return (await api.delete(`/credit-cards/own/${creditCardId}`))?.data;
  },
};
