import React, { ReactElement } from 'react';

import { RiArrowDropDownFill, RiArrowDropUpFill } from 'react-icons/ri';
import { Container } from './styles';

import { ColumnNameProps } from './ColumnName.spec';

const ColumnName = ({
  title, ordened = false, width, align,
}: ColumnNameProps): ReactElement => (
  <Container width={width} align={align}>
    {title}
    {ordened && (
    <>
      <RiArrowDropUpFill />

      {/* <RiArrowDropDownFill /> */}
    </>
    )}
  </Container>
);

export default ColumnName;
