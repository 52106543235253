import React, { createContext, useState } from 'react';
import {
  RiSearchLine,
} from 'react-icons/ri';

import Button from '../../../../components/Button';
import GridCustomer from './GridCustomer';
import SearchInput from '../../../../components/SearchInput';

import {
  Container,
  Breadcrumb,
  InputControl,
  SelectControl,
} from './styles';
import ValidationModal from '../Components/ValidationModal';
import ViewModal from '../Components/ViewModal';

export const CostumerContext = createContext({} as CostumerContextValue);

interface CostumerContextValue {
  id: number;
  setId: React.Dispatch<number>;
}

const Customer: React.FC = () => {
  const [isVisibleValidationModal, setIsVisibleValidationModal] = useState<boolean>(false);
  const [isVisibleViewModal, setIsVisibleViewModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('');
  const [id, setId] = useState<number>(0);

  return (
    <Container>
      <Breadcrumb>
        <SearchInput value={searchQuery} onChange={setSearchQuery} />
        <SelectControl>
          <small>Status</small>
          <select name="status" value={status} onChange={({ target: { value } }) => setStatus(value)}>
            <option value="">Todas</option>
            <option value="approved">Aprovadas</option>
            <option value="rejected">Rejeitadas</option>
            <option value="pending">Aguardando aprovacao</option>
          </select>
        </SelectControl>
      </Breadcrumb>
      <CostumerContext.Provider value={{
        id, setId,
      }}
      >

        <GridCustomer
          searchQuery={searchQuery}
          status={status}
          onClick={() => setIsOpen(true)}
          handleVisibleValidation={() => setIsVisibleValidationModal(!isVisibleValidationModal)}
          handleVisibleView={() => setIsVisibleViewModal(!isVisibleViewModal)}
        />
        {isVisibleValidationModal && (
        <ValidationModal
          handleClose={() => {
            setIsVisibleValidationModal(false);
          }}
          type="CD"
          id={id}
        />
        )}
        {isVisibleViewModal && (
        <ViewModal
          handleClose={() => {
            setIsVisibleViewModal(false);
          }}
          type="CD"
          id={id}
        />
        )}
      </CostumerContext.Provider>
    </Container>
  );
};

export default Customer;
