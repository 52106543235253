import React, {
  ReactElement, useCallback, useContext, useState,
} from 'react';
import {
  RiSecurePaymentFill,
  RiLoginBoxLine,
  RiUserAddFill,
  RiArrowLeftLine,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import BaruSymbol from '../../../assets/BaruSymbol.svg';
import LaptopIllu from '../../../assets/LaptopIllu.svg';

import Button from '../../Button';

import { InputControl } from '../../../styles/components';

import {
  Step,
  StepForm,
  InstallmentValueBox,
  BadgeIcon,
  InstallmentValueTexts,
  VerificationLoginModal,
  VerificationLoginModalButtons,
  VerificationLoginModalContent,
  LoadingCircle,
  Circle,
  IFrameButton,
} from './styles';
import { billPaymentService } from '../../../services';
import { Simulator } from '../../../types/simulator';
import { formatMoney } from '../../../utils/formatters';
import { OnboardingContext } from '..';
import installmentOptions from '../utils/installmentOptions';
import { getErrorMessage } from '../../../utils/errorCodes';
import { useAuth } from '../../../contexts/AuthContext';

interface SimulatorModalProps {
  nextPage: () => void;
  viewMode?: string;
}

export const InstallmentValue = ({
  nextPage,
  viewMode,
}: SimulatorModalProps): ReactElement => {
  const { next, numberOfInstallments, setNumberOfInstallments } = useContext(OnboardingContext);
  const [paymentValue, setPaymentValue] = useState<number>();
  const [installmentValue, setInstallmentValue] = useState(formatMoney());
  const [visibleLoginModal, setVisibleLoginModal] = useState<boolean>(false);
  const [isLoadingInstallment, setIsLoadingInstallment] = useState<boolean>(false);

  const { authenticated } = useAuth();

  const simulate = useCallback(
    _.debounce(async (payload: Simulator) => {
      if (payload.paymentValue && payload.numberOfInstallments) {
        try {
          const response = await billPaymentService.simulate(payload);
          setPaymentValue(response.paymentValue);
          setNumberOfInstallments(response.numberOfInstallments);
          setInstallmentValue(formatMoney(response.installmentValue));
        } catch (err: any) {
          toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível consultar o valor da parcela.');
        }
        await setIsLoadingInstallment(false);
      }
    }, 1000),
    [],
  );

  const onChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLSelectElement>): void => {
    setIsLoadingInstallment(true);
    setNumberOfInstallments(+value);
    changeFunc(name, value);
  };

  const onValueChange = (
    value?: string,
    name?: string,
    values?: {
      float: number | null;
      formatted: string;
      value: string;
    },
  ): void => {
    setIsLoadingInstallment(true);
    const val = values?.float || undefined;
    setPaymentValue(val);
    if (name) {
      changeFunc(name, val);
    }
  };

  const changeFunc = (name: string, value?: string | number): void => {
    if (!value) {
      setInstallmentValue(formatMoney());
    }
    const payload: Simulator = {
      paymentValue: +(paymentValue || 0),
      numberOfInstallments: +numberOfInstallments,
      [name]: toNumber(value),
    };
    simulate(payload);
  };

  function toNumber(value?: string | number): number {
    return +(value || 0);
  }

  function redirectParentToLogin(): void {
    const topWindow = window.top;

    if (topWindow) {
      topWindow.location.href = process.env.REACT_APP_LOGIN_URL || '';
    }
  }

  return (
    <Step>
      <StepForm>
        <InputControl>
          <small>Valor do seu boleto:</small>

          <CurrencyInput
            name="paymentValue"
            placeholder="R$ 0,00"
            prefix="R$ "
            decimalSeparator=","
            groupSeparator="."
            decimalsLimit={2}
            onValueChange={onValueChange}
            required
          />
        </InputControl>

        <InputControl>
          <small>Número de parcelas:</small>
          <select
            name="numberOfInstallments"
            value={numberOfInstallments}
            onChange={onChange}
          >
            <option value="">Selecione</option>
            {installmentOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </InputControl>

        <InstallmentValueBox>
          <BadgeIcon>
            <RiSecurePaymentFill />
          </BadgeIcon>
          <InstallmentValueTexts>
            <small>Valor de cada parcela:</small>
            {isLoadingInstallment
              ? (
                <b>
                  <LoadingCircle>
                    <Circle />
                  </LoadingCircle>
                </b>
              )
              : (
                <b>{installmentValue}</b>
              )}
          </InstallmentValueTexts>
          <img src={BaruSymbol} alt="Baru" />
        </InstallmentValueBox>

        <Button
          message="Continuar"
          type="button"
          onClick={() => (authenticated ? nextPage() : setVisibleLoginModal(true))}
          disabled={!paymentValue}
        />

      </StepForm>

      {visibleLoginModal && (
        <VerificationLoginModal viewMode={viewMode}>
          <VerificationLoginModalContent isVisible={visibleLoginModal}>
            <RiArrowLeftLine onClick={() => setVisibleLoginModal(false)} />
            <img src={LaptopIllu} alt="LaptopIllu" />
            <h3>Cadastre-se ou entre em sua conta</h3>
            <p>
              Se for seu primeiro acesso, cadastre-se. Já possui cadastro? Faça
              login para continuar.
            </p>

            <VerificationLoginModalButtons>
              <button
                type="button"
                onClick={() => {
                  setVisibleLoginModal(false);
                  nextPage();
                }}
              >
                Realizar cadastro
                <RiUserAddFill />
              </button>
              {viewMode === 'iFrame' ? (
                <IFrameButton type="button" onClick={() => redirectParentToLogin()}>
                  Fazer login
                  <RiLoginBoxLine />
                </IFrameButton>
              ) : (
                <Link to="/login">
                  <button type="button">
                    Fazer login
                    <RiLoginBoxLine />
                  </button>
                </Link>
              )}
            </VerificationLoginModalButtons>
          </VerificationLoginModalContent>

        </VerificationLoginModal>
      )}
    </Step>
  );
};

export default InstallmentValue;
