import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column; 
    /* height: 100vh;
    min-height: -webkit-fill-available; */

  > button {
    font-size: 1.3rem;
    line-height: 2.2rem;
    font-weight: 500;
    background-color: transparent;
    color: ${theme.colors.primaryBlack00};
  }
  `}
`;

export const EmptyCard = styled.div`
${({ theme }) => css`
    ${theme.texts.bodyParagraphSM};
    line-height: 1.6rem;
    height: 65px;
    padding: 12px 18px 12px 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.primary10};
    border-radius: 6px;
  `}
`;

export const CardView = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 12px 18px 12px 12px;
    margin-top: 24px;

    display: flex;
    align-items: center;

    border-radius: 6px;
    background-color: ${theme.colors.primary10};

    > svg {
     color: ${theme.colors.error};
     font-size: 1.8rem; 
     cursor: pointer;
    }
  `}
`;
export const ImageCard = styled.img`
  width: 100%;
  max-width: 62px;
  margin-right: 12px;
  height: 41px;
`;
export const CardInfo = styled.div`
  margin-right: auto;
`;

export const CardNumber = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphSM};
    color: ${theme.colors.primaryBlack00};
  `}
`;
export const CardName = styled.div`
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphXS};
    color: ${theme.colors.primaryBlack40};
  `}
`;
