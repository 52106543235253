import React, { ReactElement } from 'react';
import { Container } from './styles';

interface Props {
  message?: string;
}

const ErrorMessage = ({ message }: Props): ReactElement => (
  message ? (
    <Container>{message}</Container>
  ) : (<></>)
);

ErrorMessage.defaultProps = {
  message: undefined,
};

export default ErrorMessage;
