import styled, { css } from 'styled-components';

import BackgroundSignIn from '../../assets/BackgroundSignIn.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: -webkit-fill-available;

  background-color: white;
  padding: 35px;

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${BackgroundSignIn});
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;

  display: flex;
  align-items: center;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100%;

  @media (max-width: 480px) {
    background-image: none;
    display: block;
  }
`;

export const MainContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 537px;
    margin: 0 auto;
    padding: 44px;
    border-radius: 4px;

    background-color: ${theme.colors.white};
    box-shadow: 0px 82px 438px rgba(0, 0, 0, 0.06);

    @media (max-width: 480px) {
      box-shadow: none;
      padding: 32px;
    }

    > h1 {
      ${theme.texts.titleHeadingXXL}
      color: ${theme.colors.black};

      margin-bottom: 8px;

      > strong {
        color: ${theme.colors.primary};
      }
    }

    > small {
      ${theme.texts.bodyParagraphMD}

      color: ${theme.colors.primaryBlack00};
      opacity: 0.5;
    }

    > a {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.buttonTextXS};
      cursor: pointer;
      margin-top: 24px;

      display: flex;
      justify-content: center;
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 480px) {
      margin-bottom: 64.44px;
    }

    @media (max-width: 375px) {
      margin-bottom: 20px;
    }

    > span {
      padding: 6px 10px;
      margin-left: 18px;

      border-radius: 4px;

      ${theme.texts.alternativeTextXXXS}
      background-color: ${theme.colors.primary10};
      color: ${theme.colors.primary};
    }

    > a {
      margin-left: auto;
      display: flex;
      align-items: center;

      ${theme.texts.alternativeTextXXXS}
      color: ${theme.colors.primary};
      background-color: transparent;

      > svg {
        font-size: 18px;
        margin-left: 6px;
      }
    }
  `};
`;

export const SignInForm = styled.div`
  ${({ theme }) => css`
    margin-top: 32px;

    > button {
      width: 100%;
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;

export const Actions = styled.div`
  ${({ theme }) => css`
    margin-top: 24px;

    display: flex;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column-reverse;
    }
    

    > button {
      background-color: transparent;
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextXS};
      cursor: pointer;

      @media (max-width: 480px) {
        margin-top: 24px;
      }

      &:not(:first-child) {
        color: ${theme.colors.primaryBlack00};
      }
    }

    

    > a {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.buttonTextXS};
      cursor: pointer;

      display: flex;
      justify-content: center;
    }
  `}
`;

export const Footer = styled.div`
  text-align: center;

  @media (min-width: 480px) {
    display: none;
  }

  > img {
    margin-top: 84.58px;
  }
`;

export const StayConnected = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  ${({ theme }) => css`
    .checkbox {
      background: transparent;
      border: 1px ${theme.colors.primary} solid;
      margin-right: 12px;
    }

    .checkbox--active {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }

    > small {
      ${theme.texts.bodyParagraphSM};
      color: ${theme.colors.primaryBlack00};
    }
  `}
`;

export const ResendEmailContainer = styled.div`
 width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ResendEmailContent = styled.div`
 ${({ theme }) => css`
    background-color: ${theme.colors.white};
    max-width: 400px;
    width: 100%;
    height: 260px;
    border-radius: 4px;
    
    position: absolute;
    top: 30%;
    padding: 24px;

    > svg {
      font-size: 2rem;
      margin-bottom: 10px;
      margin-left: auto;

      display: flex;

      cursor: pointer;
    }

  > button {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    width: 100%;
  }
  `};
`;
