import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    min-height: 100%;
    z-index: 1000;
    border-radius: 4px;
    text-align: center;
    padding: 36px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.black};

    @media (max-width: 480px) {
      position: fixed;
    }

    > video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      border-radius: 4px;
    }

    > svg {
      margin-right: auto;
      font-size: 2.4rem;
      color: ${theme.colors.primary};
      z-index: 100;
      cursor: pointer;
    }

    > h4 {
      ${theme.texts.buttonTextSM};
      max-width: 260px;
      color: ${theme.colors.white};
      z-index: 10;
      margin: 83px 0 auto 0;
    }
  `};
`;

export const Mask = styled.img`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const Preview = styled.img`
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;

`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const CaptureButton = styled.button`
  ${({ theme }) => css`
    width: 76px;
    height: 76px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    color: ${theme.colors.white};
    font-size: 2.5rem;
    z-index: 10;

    transition: ${theme.transitions.default};

    &:hover {
      opacity: 0.8;
    }
  `};
`;

export const ChangeCamera = styled.button`
  ${({ theme }) => css`
    width: 76px;
    height: 76px;
    background-color: transparent;
    border-radius: 50%;
    color: ${theme.colors.white};
    font-size: 2.5rem;
    z-index: 10;

    transition: ${theme.transitions.default};

    margin-right: -50px;

    &:hover {
      opacity: 0.8;
    }
  `};
`;

export const ConfirmButton = styled.button`
  ${({ theme }) => css`
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 19px 24px;
    margin: 0 auto;
    gap: 6.5px;

    width: 580px;
    height: 60px;

    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-radius: 4px;

    transition: ${theme.transitions.default};

    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 480px) {
      max-width: 240px;
    }
  `};
`;
