import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 724px;
  min-height: -webkit-fill-available;
  padding: 21px 32px;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 30px;
  bottom: 30px;

  overflow-y: auto;
  background-color: white;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    padding: 21px 32px 56px;
    top: 0;

    /* height: 100vh; */
    min-height: -webkit-fill-available;
  }

  @media (min-width: 480px) {
    border-radius: 6px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 0 21px 0;
  cursor: pointer;

  > svg {
    font-size: 3rem;
  }
`;

export const Left = styled.div``;

export const Right = styled.div``;

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    position: relative;

    > button {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};
`;

export const ImgCard = styled.img`
  width: 32px;
  position: absolute;
  right: 24px;
  bottom: 20px;
`;

export const InputControlRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;
