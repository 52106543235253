export const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const routeSectionTitle: any = {
  '/portal/operador/solicitacoes': 'Solicitações',
  '/portal/operador/clientes': 'Clientes',
  '/portal/cliente/solicitacoes': 'Minhas solicitações',
  '/portal/cliente/meus-cartoes': 'Meus cartões',
  '/portal/perfil': 'Meu perfil',
  '/portal/cliente/configuracoes': 'Configurações',
};

export const todayDate = new Date();
