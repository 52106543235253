import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { RiBillLine, RiQrScanLine, RiSettings2Line } from 'react-icons/ri';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import BillScanner from '../../BillScanner';
import { InputControl } from '../../../styles/components';

import {
  Step,
  StepForm,
  BarCodeScanner,
  Divider,
  InputPasteControl,
  BoxBoleto,
  Header,
  TableBody,
  NavigationButtons,
  BackgroundRotate,
} from './styles';
import InputMask from '../../MaskedInput';
import { digitableMask } from '../../../utils/masks';
import { billPaymentService, ddaService } from '../../../services';
import { OrderRequestContext } from '..';
import { getErrorMessage } from '../../../utils/errorCodes';
import {
  IconBoleto,
  ItemContent,
  TableItem,
  ValueBox,
} from '../GridMyBoletos/styles';
import { formatDate, formatMoney } from '../../../utils/formatters';
import { DashboardContext } from '../../../pages/Dashboard/index';
import TurnPhoneIcon from '../../../assets/TurnPhoneIcon.svg';
import { useScreenInfo } from '../../../contexts/ScreenInfoContext';
import { DDAResponse } from '../../../types/dda';

export const BarcodeScan = (): ReactElement => {
  const {
    next, jump, setBillPaymentResponse, scanCode,
  } = useContext(OrderRequestContext);
  const { setIsOpenRequest, countDDA } = useContext(DashboardContext);
  const [isVisibleScan, setIsVisibleScan] = useState<boolean>(false);
  const [digitable, setDigitable] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { orientation } = useScreenInfo();
  const [listDDAs, setListDDAs] = useState<DDAResponse[]>([]);
  const [pageSize, setPageSize] = useState<number>(1);

  const submit = async (barcode?: string): Promise<void> => {
    setIsLoading(true);
    try {
      const payload = barcode ? { barcode } : { digitable };
      const {
        attributes: { response },
      } = await billPaymentService.findByBarcode(payload);
      setBillPaymentResponse(response);
      jump(3);
    } catch (err: any) {
      toast.warning(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Erro na consulta do boleto. Por favor, verifique o código digitado e tente novamente.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const readClipboard = async (): Promise<void> => {
    const text = await navigator.clipboard.readText();
    setDigitable(formatDigitable(text));
  };

  const formatDigitable = (code: string): string => code?.replace(/\D/g, '')?.slice(0, 48);

  // async function fetchData(): Promise<void> {
  //   try {
  //     const { data, meta } = await ddaService.getList({ pageSize });
  //     setPageSize(meta.pagination.pageSize);

  //     setListDDAs(data);
  //   } catch (err: any) {
  //     toast.warning(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const dda = listDDAs[0]?.attributes;

  return (
    <>
      <Step>
        <StepForm>
          <BarCodeScanner onClick={() => setIsVisibleScan(true)}>
            <RiQrScanLine />
            Escanear código de barras
          </BarCodeScanner>
          <Divider>ou</Divider>
          <InputControl>
            <small>Linha digitável:</small>
            <InputPasteControl>
              <InputMask
                mask={digitableMask}
                type="text"
                placeholder="Linha digitável"
                value={digitable}
                onChange={({ target: { value } }) => setDigitable(formatDigitable(value))}
                guide={false}
                required
              />
              <button type="button" onClick={readClipboard}>
                Colar linha digitável
              </button>
            </InputPasteControl>
          </InputControl>
          {listDDAs.length > 0 && (
            <BoxBoleto>
              <Header>
                {' '}
                <h3>Boletos em seu nome (DDA): </h3>
                {' '}
                <Link
                  to="/portal/cliente/configuracoes"
                  onClick={() => setIsOpenRequest(false)}
                >
                  <RiSettings2Line />
                </Link>
              </Header>
              <TableBody>
                <TableItem>
                  <IconBoleto>
                    <RiBillLine />
                  </IconBoleto>
                  <ItemContent onClick={() => submit(dda.digitable)}>
                    <h2>{dda.response?.originalBeneficiary?.fantasyName}</h2>
                    <p>
                      Vencimento em:
                      {' '}
                      {formatDate(dda.dueDate)}
                    </p>
                  </ItemContent>
                  <ValueBox>
                    {formatMoney(dda.response?.originalValue)}
                  </ValueBox>
                </TableItem>

                {countDDA > 1 && (
                  <Button
                    message={`Ver todos (${countDDA})`}
                    onClick={() => {
                      next();
                    }}
                  />
                )}
              </TableBody>
            </BoxBoleto>
          )}
        </StepForm>
        <NavigationButtons>
          <Button
            message="Continuar"
            type="button"
            onClick={() => submit()}
            isLoading={isLoading}
          />
        </NavigationButtons>
      </Step>

      {isVisibleScan && (
        <>
          {orientation === 'portrait' ? (
            <BackgroundRotate>
              <img src={TurnPhoneIcon} alt="Telefone Icon" />
              <span>
                Gire a tela do seu dispositivo para realizar a leitura do código
                de barras
              </span>
              <button type="button" onClick={() => setIsVisibleScan(false)}>
                Cancelar
              </button>
            </BackgroundRotate>
          ) : (
            <BillScanner
              onScan={() => {
                setIsVisibleScan(false);
                submit(scanCode);
              }}
              onClose={() => setIsVisibleScan(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default BarcodeScan;
