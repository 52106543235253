import React, {
  createContext, ReactElement, useState, useContext, useEffect,
} from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { Steps, useSteps } from 'react-step-builder';

import { toast } from 'react-toastify';
import BaruLogo from '../../assets/BaruLogo.svg';

import BarcodeScan from './BarcodeScan';
import TaxInformations from './TaxInformations';
import PaymentData from './PaymentData';
import RequestConfirmation from './RequestConfirmation';

import {
  Container,
  Content,
  DynamicContent,
  Header,
  Brand,
  Tag,
} from './styles';
import { BillPayment, BarcodeResponse } from '../../types/billPayment';
import { useAuth } from '../../contexts/AuthContext';
import { OrderCreditCardInfo, OrderRequest } from '../../types/order';
import { orderService } from '../../services';
import AlertModal from '../AlertModal';
import MyCards from './MyCards';
import { CreditCardResponse } from '../../types/creditCard';

import { DashboardContext } from '../../pages/Dashboard/index';
import { errorCodes, getErrorMessage } from '../../utils/errorCodes';
import GridMyBoletos from './GridMyBoletos';

export const OrderRequestContext = createContext(
  {} as OrderRequestContextValue,
);

interface OrderRequestContextValue {
  prev: () => void;
  next: () => void;
  jump: (step: number) => void;
  submit: () => Promise<void>;
  barcodeResponse: BarcodeResponse;
  setBillPaymentResponse: React.Dispatch<BarcodeResponse>;
  serviceFee: number;
  setServiceFee: React.Dispatch<number>;
  paymentValue: number;
  setPaymentValue: React.Dispatch<number>;
  totalValue: number;
  setTotalValue: React.Dispatch<number>;
  installmentValue: number;
  setInstallmentValue: React.Dispatch<number>;
  numberOfInstallments: number;
  setNumberOfInstallments: React.Dispatch<number>;
  description: string;
  setDescription: React.Dispatch<string>;
  selectedCreditCard: CreditCardResponse | null;
  setSelectedCreditCard: React.Dispatch<CreditCardResponse>;
  creditCardInfo: OrderCreditCardInfo;
  setCreditCardInfo: React.Dispatch<OrderCreditCardInfo>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<boolean>;
  scanCode: string;
  setScanCode: React.Dispatch<string>;
}

interface RequestModalProps {
  handleClose: () => void;
}

export const RequestModal = ({
  handleClose,
}: RequestModalProps): ReactElement => {
  const { updateTable, setIsOpenOnlineModal } = useContext(DashboardContext);

  const [isAlertExitVisible, setIsAlertExitVisible] = useState<boolean>(false);
  const [isAlertSucessVisible, setIsAlertSucessVisible] = useState<boolean>(false);
  const [orderRequest, setOrderRequest] = useState({
    numberOfInstallments: 1,
  } as OrderRequest);
  const [barcodeResponse, setBillPaymentResponse] = useState(
    {} as BarcodeResponse,
  );
  const [serviceFee, setServiceFee] = useState<number>(0);
  const [paymentValue, setPaymentValue] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [installmentValue, setInstallmentValue] = useState<number>(0);
  const [numberOfInstallments, setNumberOfInstallments] = useState(1);
  const [description, setDescription] = useState('');
  const [selectedCreditCard, setSelectedCreditCard] = useState<CreditCardResponse | null>(null);
  const [creditCardInfo, setCreditCardInfo] = useState(
    {} as OrderCreditCardInfo,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scanCode, setScanCode] = useState('');
  const { next, prev, jump } = useSteps();

  function setSelectedCC(creditCard: CreditCardResponse): void {
    setSelectedCreditCard(creditCard);
    setCreditCardInfo({} as OrderCreditCardInfo);
  }

  function setInfoCC(creditCard: OrderCreditCardInfo): void {
    setCreditCardInfo(creditCard);
    setSelectedCreditCard(null);
  }

  const submit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const payload = {
        digitable: barcodeResponse.digitable,
        numberOfInstallments,
        ...(selectedCreditCard?.id
          ? {
            creditCardId: selectedCreditCard.id,
          }
          : {
            creditCardInfo,
          }),

        visitorID: (window as any).visitorID,
      };
      await orderService.create(payload);
      setIsAlertSucessVisible(true);
      updateTable();
    } catch (err: any) {
      if (err.response?.data?.error?.details?.serverCode === errorCodes.OFF_BUSINESS_HOURS) {
        setIsOpenOnlineModal(true);
      } else {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  function handleModalClose(): void {
    document.body.style.overflow = 'auto';
    setIsAlertExitVisible(true);
  }

  return (
    <Container>
      <Content>
        <Header>
          <Brand>
            <img src={BaruLogo} alt="BaruPay" />
            <Tag>
              Nova solicitação
            </Tag>
          </Brand>
          <RiCloseLine onClick={() => handleModalClose()} />
        </Header>
        <DynamicContent>
          <OrderRequestContext.Provider
            value={{
              submit,
              barcodeResponse,
              setBillPaymentResponse,
              serviceFee,
              setServiceFee,
              paymentValue,
              setPaymentValue,
              totalValue,
              setTotalValue,
              installmentValue,
              setInstallmentValue,
              numberOfInstallments,
              setNumberOfInstallments,
              description,
              setDescription,
              selectedCreditCard,
              setSelectedCreditCard: setSelectedCC,
              creditCardInfo,
              setCreditCardInfo: setInfoCC,
              next,
              prev,
              jump,
              isLoading,
              setIsLoading,
              scanCode,
              setScanCode,
            }}
          >
            <Steps>
              <BarcodeScan />
              <GridMyBoletos />
              <TaxInformations />
              <MyCards />
              <PaymentData />
              <RequestConfirmation />
            </Steps>
          </OrderRequestContext.Provider>
        </DynamicContent>
      </Content>
      {isAlertSucessVisible && (
        <AlertModal
          messageButton="Nova solicitação"
          messageClose="Voltar ao painel"
          title="Estamos processando sua solicitação!"
          text="Sua solicitação está em processamento e será avaliada por nossos operadores em alguns instantes."
          typeAlert="sucess"
          onClick={() => {
            jump(1);
            setIsAlertSucessVisible(false);
          }}
          cancel={handleClose}
        />
      )}
      {isAlertExitVisible && (
        <AlertModal
          messageButton="Sim, quero sair"
          messageClose="Fechar"
          title="Deseja realmente sair?"
          text="Você perderá os dados adicionados até aqui. Deseja confirmar essa ação?"
          typeAlert="alert"
          onClick={handleClose}
          cancel={() => setIsAlertExitVisible(false)}
        />
      )}
    </Container>
  );
};

export default RequestModal;
