import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  html {
    font-size: 62.5%; // 1rem = 10px
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
  }
  
  button, input, textarea {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
  }
  
  body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    /* max-width: min(480px, 100%); */
    background-color: #56E04E;
    margin: 0 auto;
    /* padding: 32px; */
  }
  
  body, #root {
    position: relative;
    
    display: flex;
    flex-direction: column;
  }
  
  #root {
    height: 100%;
    min-height: -webkit-fill-available;
  }
  
  input, div, li, button, a, textarea, select {
    -webkit-tap-highlight-color: transparent; 
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
  }
  
  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    border: 0;
  }
  
  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }
`;

export default GlobalStyle;
