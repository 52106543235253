import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};  
`;

export const AttachRow = styled.div`
  ${({ theme }) => css`
    > small {
      ${theme.texts.bodyParagraphMD};
    }

    :last-child {
      margin: 24px 0 0 0;
    }
  `};
`;

export const AttachBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary10};
    border: 1px dashed ${theme.colors.primary};
    border-radius: 6px;
    padding: 44px;
    margin: 12px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const AttachBoxTexts = styled.div`
  ${({ theme }) => css`
    > h4 {
      ${theme.texts.buttonTextSM};
      letter-spacing: -0.1px;
      color: ${theme.colors.primaryBlack00};
      margin: 0 0 4px 0;
    }

    > span {
      color: ${theme.colors.primaryBlack00};
      opacity: 0.3;
      ${theme.texts.bodyParagraphSM};
    }
  `};  
`;

export const AttachBoxButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: 0 9px;

    > span {
      color: ${theme.texts.primaryBlack00};
      ${theme.texts.bodyParagraphXS};
      margin: 0 8px;
    }

    button {
      background-color: ${theme.colors.primary};
      border-radius: 6px;
      color: ${theme.colors.white};
      ${theme.texts.buttonTextXS};
      padding: 8px 12px;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.5rem;
      }
    }
  `};
`;

export const ScanDocumentOnBoarding = styled.div`
  ${({ theme }) => css`
    border-radius: 4px;
    width: 100%;
    min-height: 100%;
    background-color: ${theme.colors.white};
    padding: 90px 36px 36px 36px;
  
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    > svg {
      font-size: 2.4rem;
      color: ${theme.colors.primaryBlack00};
      cursor: pointer;
    }

    > img {
      max-width: 280px;
      margin: 24px auto;
    }

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const OnBoardingSteps = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const OnBoardingStep = styled.li`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary10};
    border-radius: 6px;
    padding: 16px;
    ${theme.texts.bodyParagraphSM};
    color: ${theme.colors.primaryBlack00};

    display: flex;
    align-items: center;

    > svg {
      color: ${theme.colors.primary};
      margin: 0 12px 0 0;
      font-size: 1.8rem;
    }

    :not(:last-child) {
      margin: 0 0 14px 0;
    }
  `};
`;

export const WarningBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    padding: 16px 18px 16px 16px;
    margin: 24px 0;
    margin-top: auto;

    display: flex;
    align-items: center;

    @media(max-width: 480px) {
      padding: 16px;
      }

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 2.2rem;
      font-size: 1.3rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    > svg {
      color: ${theme.colors.yellow};
      margin-right: 10px;
      font-size: 2rem;
      
      @media(max-width: 480px) {
        width: 100%;
        max-width: 20px;
      }
    }
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
  
    width: 100%;
    margin: 24px 0 0 0;

    > button {  

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;
