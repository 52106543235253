import { User, UserPartial } from '../types/user';
import api from './api';

export default {
  async getMe(): Promise<User> {
    return (await api.get('/users/me', {
      params: {
        'populate[role][populate]': '',
        'populate[customer][populate]': 'address',
        'populate[manager][populate]': '',
        'populate[operator][populate]': '',
        'populate[avatar][populate]': '',
      },
    }))?.data;
  },

  async edit(user: UserPartial): Promise<User> {
    return (
      await api.put<User>(
        '/users/me', user,
      )
    )?.data;
  },

  async editImage(files: {
    avatar: File
  }): Promise<void> {
    return (await api.postForm('/users/files', files))?.data;
  },

  async removeImage(propName: string): Promise<void> {
    return (await api.delete('/users/files', {
      params: {
        propName,
      },
    }))?.data;
  },
};
