import _ from 'lodash';
import {
  AddressForm,
  OnboardingForm,
  OnboardingResponse,
} from '../types/onboarding';
import api from './api';

import {
  StrapiRequestData,
  StrapiResponse,
  StrapiResponseFindMany,
  StrapiResponseFindOne,
} from '../types/core';
import {
  CustomerCore,
  CustomerResponse,
  CustomerResponseFull,
} from '../types/customer';
import { UserCore } from '../types/user';

interface PaginationProps {
  page: number;
  pageSize: number;
  sort?: string;
  searchQuery?: string;
  status?: string;
}

export default {
  async register(onboardingForm: OnboardingForm): Promise<OnboardingResponse> {
    const formData = new FormData();

    formData.append('data', JSON.stringify(onboardingForm.data));
    formData.append('frontDocument', onboardingForm.frontDocument);
    formData.append('backDocument', onboardingForm.backDocument);
    formData.append('selfie', onboardingForm.selfie);

    return (await api.post<OnboardingResponse>('/customers/register', formData))
      ?.data;
  },

  async getList({
    page,
    pageSize,
    sort,
    searchQuery,
    status,
  }: PaginationProps): Promise<StrapiResponseFindMany<CustomerResponse>> {
    return (
      await api.get('/customers', {
        params: _.omitBy(
          {
            populate: 'user',
            'pagination[page]': page,
            'pagination[pageSize]': pageSize,
            'filters[status][$eq]': status,
            'filters[$or][0][user][name][$containsi]': searchQuery,
            'filters[$or][1][user][cpf][$containsi]': searchQuery,
            'filters[$or][2][user][phone][$containsi]': searchQuery,
            sort: 'createdAt:desc',
          },
          (v) => !v,
        ),
      })
    )?.data;
  },

  async getOne(id: number): Promise<StrapiResponse<CustomerResponseFull, any>> {
    return (
      await api.get(`/customers/${id}`, {
        params: {
          populate:
            'user,address,customerReview,frontDocument,backDocument,selfie',
        },
      })
    )?.data;
  },

  async approve(id: number): Promise<void> {
    await api.post(`/customers/${id}/approve`);
  },

  async reject(id: number, description: string): Promise<void> {
    await api.post(`/customers/${id}/reject`, {
      description,
    });
  },

  async getMe(): Promise<StrapiResponseFindOne<CustomerResponseFull>> {
    return (
      await api.get<StrapiResponseFindOne<CustomerResponseFull>>(
        '/customers/me',
      )
    )?.data;
  },

  async edit(
    customer: StrapiRequestData<Partial<CustomerCore & {
      address?: StrapiRequestData<Partial<AddressForm>>;
      user?: StrapiRequestData<Partial<UserCore>>;
    }>
  >,
  ): Promise<StrapiResponseFindOne<CustomerResponseFull>> {
    return (
      await api.put<StrapiResponseFindOne<CustomerResponseFull>>(
        '/customers/me',
        customer,
      )
    )?.data;
  },

  async acceptTOS(acceptedTOS: boolean, enableDDA: boolean): Promise<void> {
    await api.post('/customers/me/tos', { acceptedTOS, enableDDA });
  },
};
