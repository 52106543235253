import _ from 'lodash';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { RiSearchLine } from 'react-icons/ri';

import { Container, InputControl } from './styles';

interface SearchInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const SearchInput = ({ value, onChange }: SearchInputProps): ReactElement => {
  const [searchQuery, setSearchQuery] = useState('');

  const debouncedOnChange = useCallback(
    _.debounce((val: string) => onChange?.(val), 600),
    [onChange],
  );

  useEffect(() => {
    debouncedOnChange(searchQuery);
  }, [searchQuery, debouncedOnChange]);

  useEffect(() => {
    setSearchQuery(value || '');
  }, [value]);

  return (
    <InputControl>
      <RiSearchLine />
      <input
        type="text"
        placeholder="Pesquisar"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </InputControl>
  );
};

SearchInput.defaultProps = {
  value: '',
  onChange: () => null,
};
export default SearchInput;
