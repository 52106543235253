import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import SimulatorModal from './components/SimulatorModal';

import { AuthProvider } from './contexts/AuthContext';
import Router from './router';

import defaultTheme from './styles/themes/defaultTheme';
import { ScreenInfoContextProvider } from './contexts/ScreenInfoContext';

const { REACT_APP_S2P_ANTIFRAUD_PKEY } = process.env;

const App: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    (window as any).Safe2Pay?.Init(REACT_APP_S2P_ANTIFRAUD_PKEY);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AuthProvider>

        <ScreenInfoContextProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          {isVisible && (
            <SimulatorModal
              handleClose={() => setIsVisible(false)}
              nextPage={() => setIsVisible(false)}
            />
          )}
        </ScreenInfoContextProvider>

      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
