import React, {
  createContext, ReactElement, useEffect, useState,
} from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { Steps, useSteps } from 'react-step-builder';

import BaruLogo from '../../assets/BaruLogo.svg';

import UserData from './UserData';
import AddressData from './AddressData';
import DocumentsScan from './DocumentsScan';
import SelfieScan from './SelfieScan';
import SignInInformations from './SignInInformations';

import { OnboardingSteps } from '../../types/onboarding';

import {
  Container,
  Content,
  DynamicContent,
  Header,
  Brand,
  Tag,
} from './styles';
import { BarcodeResponse } from '../../types/billPayment';
import { useAuth } from '../../contexts/AuthContext';
import EmailConfirmation from './EmailConfirmation';

export const OnboardingContext = createContext({} as OnboardingContextValue);

interface OnboardingContextValue {
  prev: () => void;
  next: () => void;
  onboardingData: OnboardingSteps;
  setOnboardingData: React.Dispatch<OnboardingSteps>;
  barcodeResponse: BarcodeResponse;
  setBillPaymentResponse: React.Dispatch<BarcodeResponse>;
  numberOfInstallments: number;
  setNumberOfInstallments: React.Dispatch<number>;
}

interface OnboardingModalProps {
  handleClose: () => void;
  prevPage: () => void;
  viewMode?: string;
}

export const OnboardingModal = ({
  handleClose,
  prevPage,
  viewMode,
}: OnboardingModalProps): ReactElement => {
  const [onboardingData, setOnboardingData] = useState({} as OnboardingSteps);
  const [barcodeResponse, setBillPaymentResponse] = useState(
    {} as BarcodeResponse,
  );
  const [numberOfInstallments, setNumberOfInstallments] = useState(1);
  const {
    next, prev, current, jump,
  } = useSteps();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  function handleModalClose(): void {
    const topWindow = window?.top;

    if (viewMode === 'iFrame' && topWindow) {
      topWindow.location.href = process.env.REACT_APP_PARENT_URL || '';
      return;
    }

    document.body.style.overflow = 'auto';
    handleClose();
  }

  return (
    <Container>
      <Content viewMode={viewMode}>
        <Header>
          <Brand>
            <img src={BaruLogo} alt="BaruPay" />
            <Tag>Cadastro</Tag>
          </Brand>
          <RiCloseLine onClick={() => handleModalClose()} />
        </Header>
        <DynamicContent>
          <OnboardingContext.Provider
            value={{
              onboardingData,
              setOnboardingData,
              barcodeResponse,
              setBillPaymentResponse,
              numberOfInstallments,
              setNumberOfInstallments,
              next,
              prev,
            }}
          >
            <Steps>
              <UserData prevPage={prevPage} />
              <AddressData />
              <DocumentsScan />
              <SelfieScan />
              <SignInInformations viewMode={viewMode} />
              <EmailConfirmation />
            </Steps>
          </OnboardingContext.Provider>
        </DynamicContent>
      </Content>
    </Container>
  );
};

export default OnboardingModal;
