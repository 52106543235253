import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      visibility: hidden;
    }

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    position: relative;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};  
`;

export const ImgCard = styled.img`
  width: 32px;
  position:  absolute;
  right: 24px;
  bottom: 20px;
`;

export const SaveCardBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    background-color: ${theme.colors.primary10};
    border-radius: 6px;
    padding: 16px;
    ${theme.texts.bodyParagraphXXS};

    .checkbox {
      background: transparent;
      border: 1px ${theme.colors.primary} solid;
      margin-right: 12px;
      border-radius: 3px;
      cursor: pointer;

      display: flex;
      align-items: center;
    }

    .checkbox--active {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }
  `};
`;

export const InputControlRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const FinalValueInformations = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: 24px 0 0 0;
    margin: 24px 0 0 0;
    border-top: 1px dashed ${theme.colors.primaryBlack20};
    position: relative;

    overflow: hidden;

    > img {
      border-radius: 0 6px 6px 0;

      position: absolute;
      right: 0;

      @media (max-width: 480px) {
        right: -20%;
      }
    }
  `};
`;

export const FinalValueBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary05};
    border-radius: 6px;
    width: 100%;

    display: flex;
    align-items: center;
    padding: 16px;
  `}; 
`;

export const FinalValueBrand = styled.div`
  ${({ theme }) => css`
    width: 66px;
    height: 66px;
    background-color: ${theme.colors.primary20};
    border-radius: 6px;
    margin: 0 20px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${theme.colors.primary};
      font-size: 3rem;
    }
  `};
`;

export const FinalValueTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextXS};
    }

    > b {
      ${theme.texts.buttonTextMD};
      letter-spacing: -0.2px;
      color: ${theme.colors.primaryBlack00};
      margin: 4px 0;

      > span {
        ${theme.texts.buttonTextXS};
        margin-left: 4px;
       }
    }

    > span {
      ${theme.texts.buttonTextXS};
      color: ${theme.colors.primaryBlack50};
    }
  `};
`;

export const WarningBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    padding: 16px 70px 16px 16px;
    margin: 24px 0;

    display: flex;
    align-items: center;

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 1.6rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    > svg {
      color: ${theme.colors.yellow};
      margin-right: 10px;
      font-size: 2rem;

      @media(max-width: 480px) {
        width: 100%;
        max-width: 20px;
      }
    }
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    background-color: ${theme.colors.white};

    /* position: sticky; */
    /* bottom: 0; */
    padding: 12px 0 0 0;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;
