import React, { ReactElement } from 'react';
import { RiBillFill, RiSecurePaymentFill } from 'react-icons/ri';
import Button from '../Button';

import GraphicIcon from '../../assets/GraphicIcon.svg';

import { EmptyBox, ActionsButton } from './styles';

interface TableEmptyProps {
  operator?: boolean;
  title?: string;
  text?: string;
  onClick?: () => void;
}

const TableEmpty = ({
  onClick, operator, title, text,
}: TableEmptyProps):ReactElement => (
  <EmptyBox>
    {operator}
    {operator ? (
      <>
        <img src={GraphicIcon} alt="Graphic Icon" />
        <h1>
          Nenhuma
          {' '}
          {title}
          {' '}
          encontrada.
        </h1>
        <p>{text}</p>
      </>
    ) : (
      <>
        <img src={GraphicIcon} alt="Graphic Icon" />
        <h1>Suas solicitações serão exibidas aqui</h1>
        <p>Você ainda não possui nenhuma solicitação ativa. Comece abaixo: </p>
        {/* <ActionsButton>
          <Button message="Nova solicitação" variant="secondary" onClick={onClick} icon={<RiSecurePaymentFill />} />
          <p>ou</p>
          <Button message="Simular parcelas" />
        </ActionsButton> */}

      </>
    ) }
  </EmptyBox>
);

TableEmpty.defaultProps = {
  operator: false,
  title: '',
  text: 'Não possui dados',
  onClick: () => null,
};

export default TableEmpty;
