import React, { ReactElement, useMemo } from 'react';
import { defaultProps } from 'react-select/dist/declarations/src/Select';
import { OrderResponseFull } from '../../../../../../types/order';
import {
  formatCPF,
  formatDate,
  formatDateWithHour,
  formatDigitable,
  formatMoney,
  formatPhone,
} from '../../../../../../utils/formatters';
import { getIssuerByBrand } from '../../../../../../utils/issuerLogos';

import {
  Container,
  Content,
  ContentTitle,
  ContentLeft,
  Divider,
  ContentRight,
  Datas,
  ItemDatas,
  Status,
  LastStatus,
  LastUpdate,
  CardView,
  ImageCard,
  CardInfo,
  CardNumber,
  CardName,
} from './styles';

interface Props {
  order?: OrderResponseFull;
}

const RequestData = ({ order: orderInfo }: Props): ReactElement => {
  const order = orderInfo?.attributes;
  const orderCreditCard = order?.orderCreditCard?.data?.attributes;
  const customer = order?.customer?.data?.attributes;
  const user = customer?.user?.data?.attributes;
  const address = customer?.address?.data?.attributes;
  const customerReview = customer?.customerReview?.data?.attributes;
  const orderBillPaymentInfo = order?.orderBillPayment?.data?.attributes?.info;

  const finalValue = order ? order?.paymentValue + order?.serviceFee : 0;
  const installmentValue = order ? finalValue / order?.numberOfInstallments : 0;

  const issuer = useMemo(
    () => (orderCreditCard ? getIssuerByBrand(orderCreditCard.brand) : undefined),
    [orderCreditCard],
  );

  return (
    <Container>
      <Content>
        <ContentLeft>
          <ContentTitle>Dados da conta/boleto</ContentTitle>
          <Datas>
            <ItemDatas>
              <strong>N° do documento: </strong>
              <p />
            </ItemDatas>
            <ItemDatas>
              <strong>Data de vencimento: </strong>
              <p>{formatDate(orderBillPaymentInfo?.dueDate || new Date().toISOString())}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Beneficiário:</strong>
              <p>{orderBillPaymentInfo?.registerData?.recipient || orderBillPaymentInfo?.assignor}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Juros:</strong>
              <p>
                {formatMoney(
                  orderBillPaymentInfo?.registerData?.interestValueCalculated,
                )}
              </p>
            </ItemDatas>
            <ItemDatas>
              <strong>Multas:</strong>
              <p>
                {formatMoney(
                  orderBillPaymentInfo?.registerData?.fineValueCalculated,
                )}
              </p>
            </ItemDatas>
            <ItemDatas>
              <strong>Descontos: </strong>
              <p>
                {formatMoney(orderBillPaymentInfo?.registerData?.discountValue)}
              </p>
            </ItemDatas>
            <ItemDatas>
              <strong>Valor total: </strong>
              <p>{formatMoney(order?.paymentValue)}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Código de barras: </strong>
              <p>{formatDigitable(order?.digitable)}</p>
            </ItemDatas>
            <ItemDatas>
              <span />
            </ItemDatas>
            <ItemDatas>
              <strong>Tarifa: </strong>
              <p>{formatMoney(order?.serviceFee)}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Parcelamento:</strong>
              <p>
                {formatMoney(installmentValue)}
                {' '}
                {order?.numberOfInstallments}
                x
              </p>
            </ItemDatas>
            <ItemDatas>
              <strong>Valor final:</strong>
              <p>{formatMoney(finalValue)}</p>
            </ItemDatas>
          </Datas>
        </ContentLeft>
        <Divider />
        <ContentRight>
          <ContentTitle>Dados de pagamento</ContentTitle>
          <Datas>
            <ItemDatas>
              <strong>Nome completo: </strong>
              <p>{user?.name}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>CPF: </strong>
              <p>{formatCPF(user?.cpf)}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Telefone: </strong>
              <p>{formatPhone(user?.phone)}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>E-mail: </strong>
              <p>{user?.email}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>CEP: </strong>
              <p>{address?.postalCode}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Endereço: </strong>
              <p>{address?.street}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Número: </strong>
              <p>{address?.number}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Complemento: </strong>
              <p>{address?.complement}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Bairro: </strong>
              <p>{address?.neighborhood}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Cidade: </strong>
              <p>{address?.city}</p>
            </ItemDatas>
            <ItemDatas>
              <strong>Estado: </strong>
              <p>{address?.state}</p>
            </ItemDatas>

            {orderCreditCard && (
              <CardView>
                {!!issuer && (
                  <ImageCard src={issuer.logo} alt="Credit card issuer logo" />
                )}
                <CardInfo>
                  <CardNumber>{`(Crédito) ${issuer?.name || 'Cartão'} com final ${orderCreditCard?.cardNumber}`}</CardNumber>
                  <CardName>{orderCreditCard?.holder}</CardName>
                </CardInfo>
              </CardView>
            )}
          </Datas>
        </ContentRight>
      </Content>
      <Status>
        <LastStatus>
          Cliente aprovado em:
          {' '}
          {formatDateWithHour(
            customerReview?.createdAt,
          )}
        </LastStatus>
        <LastUpdate>
          {order?.status === 'liquidated' ? (
            <>
              Liquidado em:
              {' '}
              {formatDateWithHour(order?.liquidatedAt)}
            </>
          ) : (
            <>
              Solicitação criada em:
              {' '}
              {formatDateWithHour(order?.createdAt)}
            </>
          )}

        </LastUpdate>
      </Status>
    </Container>
  );
};

RequestData.defaultProps = {
  order: {},
};

export default RequestData;
