import React, { useState } from 'react';
import {
  RiSecurePaymentFill,
  RiPriceTag3Fill,
  RiUser3Fill,
  RiUser5Fill,
  RiBillFill,
  RiBankCardFill,
  RiShieldCheckFill,
  RiSeedlingFill,
  RiVipDiamondFill,
  RiWhatsappFill,
} from 'react-icons/ri';

import { StepsProvider } from 'react-step-builder';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';

import FooterLogo from '../../assets/FooterLogo.svg';
import FooterLogoWhite from '../../assets/FooterLogoWhite.svg';
import BarCodeImage from '../../assets/BarCodeImage.svg';
import CreditCardImage from '../../assets/CreditCardImage.svg';
import LockImage from '../../assets/LockImage.svg';
import ApproveImage from '../../assets/ApproveImage.svg';
import HowWorksBG from '../../assets/HowWorksBG.png';
import ShieldIcon from '../../assets/ShieldIcon.svg';

import A4PosterLeft from '../../assets/A4PosterLeft.png';
import A4PosterRight from '../../assets/A4PosterRight.png';
import A4PosterLeft2 from '../../assets/A4PosterLeft2.png';
import A4PosterRight2 from '../../assets/A4PosterRight2.png';
import A4PosterLeftCropped from '../../assets/A4PosterLeftCropped.png';
import A4PosterRightCropped from '../../assets/A4PosterRightCropped.png';
import A4PosterLeftCropped2 from '../../assets/A4PosterLeftCropped2.png';
import A4PosterRightCropped2 from '../../assets/A4PosterRightCropped2.png';

import {
  Container,
  LeftImage,
  RightImage,
  LeftImageCropped,
  RightImageCropped,
  MainContent,
  NestedMainContent,
  MainContentTexts,
  HowWorks,
  HowWorksTexts,
  HowWorksBox,
  HowWorksImage,
  HowWorksListItems,
  HowWorksListItem,
  Purpose,
  PurposeTexts,
  PurposeBoxes,
  PurposeBox,
  PurposeBoxIcon,
  SimulateBox,
  Content,
  SimulateBoxSection,
  SimulateBoxTexts,
  SectionTitleHeading,
  SectionSubtitle,
  SectionAlternativeText,
  ServicesSection,
  ServicesBox,
  ServicesBoxTexts,
  SimulateBoxContent,
  SimulateLeftImage,
  SimulateLeftImageCropped,
  SimulateRightImage,
  SimulateRightImageCropped,
  FloatWhats,
} from './styles';
import Navbar from '../../components/Navbar';

import SimulatorModal from '../../components/SimulatorModal';
import OnboardingModal from '../../components/OnboardingModal';
import { useAuth } from '../../contexts/AuthContext';

const Home: React.FC = () => {
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isVisibleOnboarding, setIsVisibleOnboarding] = useState<boolean>(false);

  const nextPage = (): void => {
    if (authenticated) {
      navigate('/login');
    } else {
      setIsVisibleOnboarding(true);
    }
  };

  return (
    <Container>
      <Navbar handleToggleSimulator={() => setIsVisible(!isVisible)} />

      <MainContent>
        <NestedMainContent>
          <LeftImage src={A4PosterLeft} alt="A4PosterLeft" />
          <LeftImageCropped src={A4PosterLeftCropped} alt="A4PosterLeft" />

          <MainContentTexts>
            <SectionAlternativeText>
              Que tal um boleto a menos?
            </SectionAlternativeText>
            <h1>
              Liberte-se dos boletos organizando sua vida financeira
              {' '}
              {/* <b>livrar dos boletos</b> */}
            </h1>
            <p>Sua estabilidade financeira a poucos cliques de distância.</p>
            <Button
              message="Simular parcelas"
              icon={<RiSecurePaymentFill />}
              onClick={() => setIsVisible(!isVisible)}
            />
          </MainContentTexts>
          <RightImage src={A4PosterRight} alt="A4PosterRight" />
          <RightImageCropped src={A4PosterRightCropped} alt="A4PosterRight" />
        </NestedMainContent>
      </MainContent>

      <ServicesSection>
        <img src={ShieldIcon} alt="ShieldIcon" />
        <ServicesBox>
          <img src={BarCodeImage} alt="Código de barras" />
          <ServicesBoxTexts>
            <SectionAlternativeText>
              Comodidade para seu parcelamento
            </SectionAlternativeText>
            <h4>Pague qualquer conta com código de barras</h4>
            <p>
              Tendo seu boleto ou seu código de barras em mãos, você já pode
              enviar sua solicitação e
              {' '}
              <b>parcelar em até 16x!</b>
            </p>
          </ServicesBoxTexts>
        </ServicesBox>
        <ServicesBox>
          <ServicesBoxTexts>
            <SectionAlternativeText>
              Faça tudo de uma vez só
            </SectionAlternativeText>
            <h4>Utilize o limite do seu cartão de crédito para o pagamento</h4>
            <p>
              Utilize seu limite do cartão de crédito para se libertar dos
              boletos. Você faz tudo através do nosso checkout.
            </p>
          </ServicesBoxTexts>
          <img src={CreditCardImage} alt="Cartão de crédito" />
        </ServicesBox>
        <ServicesBox>
          <img src={LockImage} alt="Segurança de ponta a ponta" />
          <ServicesBoxTexts>
            <SectionAlternativeText>
              Segurança de ponta a ponta
            </SectionAlternativeText>
            <h4>Ambiente seguro com comunicação criptografada e antifraude</h4>
            <p>
              Nossa infraestrutura possui diversos selos de certificação para
              que você navegue por um ambiente seguro e criptografado.
            </p>
          </ServicesBoxTexts>
        </ServicesBox>
        <ServicesBox>
          <ServicesBoxTexts>
            <SectionAlternativeText>
              Agilidade de aprovação
            </SectionAlternativeText>
            <h4>Análise e aprovação da sua solicitação em poucas horas</h4>
            <p>
              Após o recebimento dos seus dados, aprovamos sua solicitação no
              mesmo dia.
            </p>
          </ServicesBoxTexts>
          <img src={ApproveImage} alt="Agilidade de aprovação" />
        </ServicesBox>
      </ServicesSection>

      <HowWorks>
        <HowWorksTexts>
          <SectionAlternativeText>
            Simples, fácil e rápido
          </SectionAlternativeText>
          <SectionTitleHeading>Como funciona</SectionTitleHeading>
          <SectionSubtitle>
            Veja como é fácil começar a parcelar os seus boletos hoje mesmo!
          </SectionSubtitle>
        </HowWorksTexts>
        <HowWorksBox>
          <HowWorksImage src={HowWorksBG} alt="Como funciona" />
          <HowWorksListItems>
            <HowWorksListItem>
              <RiPriceTag3Fill />
              Simule o valor da sua parcela
            </HowWorksListItem>
            <HowWorksListItem>
              <RiUser3Fill />
              Preencha seus dados pessoais
            </HowWorksListItem>
            <HowWorksListItem>
              <RiBillFill />
              Informe o valor do seu boleto
            </HowWorksListItem>
            <HowWorksListItem>
              <RiBankCardFill />
              Pague com seu cartão de crédito
            </HowWorksListItem>
            <HowWorksListItem>
              <RiShieldCheckFill />
              Prontinho! Fácil né?
            </HowWorksListItem>
          </HowWorksListItems>
        </HowWorksBox>
        <Button
          message="Simular parcelas"
          icon={<RiSecurePaymentFill />}
          onClick={() => setIsVisible(!isVisible)}
        />
      </HowWorks>

      <Purpose>
        <img src={FooterLogo} alt="BaruPay | Céos" />
        <PurposeTexts>
          <SectionTitleHeading>Entenda o nosso propósito</SectionTitleHeading>
          <SectionSubtitle>
            Nós queremos oferecer a
            {' '}
            <b>liberdade</b>
            {' '}
            para os nossos clientes
            abraçarem as novas
            {' '}
            <b>oportunidades</b>
            {' '}
            e conquistarem os seus
            sonhos!
          </SectionSubtitle>
        </PurposeTexts>

        <PurposeBoxes>
          <PurposeBox>
            <PurposeBoxIcon>
              <RiSeedlingFill />
            </PurposeBoxIcon>
            <h3>Transparência</h3>
            <p>
              Ter uma comunicação transparente com os nossos clientes e oferecer
              segurança em todos os processos do nosso trabalho.
            </p>
          </PurposeBox>
          <PurposeBox>
            <PurposeBoxIcon>
              <RiUser5Fill />
            </PurposeBoxIcon>
            <h3>Pessoas</h3>
            <p>
              Zelar pela confiança dos nossos clientes e colaboradores e sempre
              colocá-los no centro das nossas ações.
            </p>
          </PurposeBox>
          <PurposeBox>
            <PurposeBoxIcon>
              <RiVipDiamondFill />
            </PurposeBoxIcon>
            <h3>Eficiência</h3>
            <p>
              Ser ágil e eficiente na entrega do que foi solicitado por meio de
              um atendimento humanizado e personalizado.
            </p>
          </PurposeBox>
        </PurposeBoxes>
      </Purpose>

      <SimulateBox>
        <Content>
          <SimulateLeftImage src={A4PosterLeft2} />
          <SimulateLeftImageCropped src={A4PosterLeftCropped2} />
          <SimulateBoxContent>
            <img src={ShieldIcon} alt="ShieldIcon" />
            <SimulateBoxTexts>
              <SectionAlternativeText>
                Que tal um boleto a menos?
              </SectionAlternativeText>
              <SectionTitleHeading>
                Simule sua parcela agora mesmo
                {' '}
                <strong>sem compromisso!</strong>
              </SectionTitleHeading>
              <SectionSubtitle>
                Nosso serviço consultivo busca lhe ajudar a encontrar sua
                liberdade financeira. Conheça agora mesmo!
              </SectionSubtitle>
              <Button
                message="Simular parcelas"
                icon={<RiSecurePaymentFill />}
                onClick={() => setIsVisible(!isVisible)}
              />
            </SimulateBoxTexts>

            <SimulateBoxSection>
              <img src={FooterLogoWhite} alt="BaruPay | Céos" />
              <span>2022 © BaruPay. Todos os direitos reservados.</span>
            </SimulateBoxSection>
          </SimulateBoxContent>
          <SimulateRightImage src={A4PosterRight2} />
          <SimulateRightImageCropped src={A4PosterRightCropped2} />
        </Content>
      </SimulateBox>

      {isVisible && (
        <SimulatorModal
          handleClose={() => setIsVisible(false)}
          nextPage={nextPage}
        />
      )}
      {isVisibleOnboarding && (
        <StepsProvider>
          <OnboardingModal
            handleClose={() => {
              setIsVisibleOnboarding(false);
              setIsVisible(false);
            }}
            prevPage={() => setIsVisibleOnboarding(false)}
          />
        </StepsProvider>
      )}

      <FloatWhats>
        <a href="https://wa.me/555135007992">
          <RiWhatsappFill />
        </a>
      </FloatWhats>
    </Container>
  );
};

export default Home;
