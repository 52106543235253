import React, { ReactElement, useContext, useState } from 'react';
import {
  RiLoginBoxLine,
  RiMenu3Line,
  RiCloseLine,
  RiSecurePaymentFill,
  RiWhatsappLine,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';

import Logo from '../../assets/Logo.svg';
import FooterLogo from '../../assets/FooterLogo.svg';
import BaruSymbolHeader from '../../assets/BaruSymbolHeader.svg';

import Button from '../Button';

import {
  Container,
  Brand,
  NestedContainer,
  NavbarButtons,
  MobileNavbar,
  MobileNavbarButtons,
  MobileMenu,
  MobileMenuHeader,
  Tag,
  MobileMenuContent,
} from './styles';
import { useAuth } from '../../contexts/AuthContext';
import { DashboardContext } from '../../pages/Dashboard';

interface NavbarProps {
  handleToggleSimulator: () => void;
}

export const NavbarMobile = ({
  handleToggleSimulator,
}: NavbarProps): ReactElement => {
  const [isVisibleMenu, setIsVisibleMenu] = useState<boolean>(false);
  const { authenticated, role } = useAuth();
  const { logout } = useAuth();

  const { isOpenRequest, setIsOpenRequest, setIsOpenSimulator } = useContext(DashboardContext);

  return (
    <Container>
      <MobileNavbar>
        <img src={BaruSymbolHeader} alt="BaruSymbol" />
        <Tag>Menu</Tag>
        <MobileNavbarButtons>
          {!authenticated && (
            <Button message="Simular agora!" onClick={handleToggleSimulator} />
          )}
          <RiMenu3Line onClick={() => setIsVisibleMenu(true)} />
        </MobileNavbarButtons>

        <MobileMenu visible={isVisibleMenu}>
          <MobileMenuHeader>
            <img src={BaruSymbolHeader} alt="BaruSymbol" />
            <Tag>Painel</Tag>
            <RiCloseLine onClick={() => setIsVisibleMenu(false)} />
          </MobileMenuHeader>
          <MobileMenuContent>
            <h3>Navegação</h3>

            {authenticated ? (
              <>
                {role === 'customer' ? (
                  <>
                    <Button
                      message="Nova solicitação"
                      icon={<RiSecurePaymentFill />}
                      onClick={() => {
                        setIsOpenRequest(true);
                        setIsVisibleMenu(false);
                      }}
                    />

                    <Button
                      type="button"
                      message="Simular parcelas"
                      onClick={() => {
                        setIsOpenSimulator(true);
                        setIsVisibleMenu(false);
                      }}
                    />

                    <Link to="/portal/cliente/solicitacoes">
                      <Button
                        type="button"
                        message="Minhas solicitações"
                        onClick={() => setIsVisibleMenu(false)}
                      />
                    </Link>

                    <Link
                      to="/portal/perfil"
                      onClick={() => setIsVisibleMenu(false)}
                    >
                      <Button type="button" message="Meus dados" />
                    </Link>

                    <Link
                      to="/portal/cliente/meus-cartoes"
                      onClick={() => setIsVisibleMenu(false)}
                    >
                      <Button type="button" message="Meus cartões" />
                    </Link>

                    {/* <Link
                      to="/portal/cliente/configuracoes"
                      onClick={() => setIsVisibleMenu(false)}
                    >
                      <Button type="button" message="Configurações" />
                    </Link> */}

                    <a
                      href="https://wa.me/555135007992"
                      onClick={() => setIsVisibleMenu(false)}
                    >
                      <Button type="button" message="Ajuda" icon={<RiWhatsappLine />} />
                    </a>
                  </>
                ) : (
                  <>
                    <Link
                      to="/portal/operador/solicitacoes"
                      onClick={() => setIsVisibleMenu(false)}
                    >
                      <Button type="button" message="Solicitações" />
                    </Link>
                    <Link
                      to="/portal/operador/clientes"
                      onClick={() => setIsVisibleMenu(false)}
                    >
                      <Button type="button" message="Clientes" />
                    </Link>
                  </>
                )}

                <Link to="https://www.barupay.com.br/" onClick={() => logout()}>
                  <Button type="button" message="Sair" />
                </Link>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  message="Simular parcelas"
                  onClick={() => {
                    handleToggleSimulator();
                  }}
                />
                <Link to="/login">
                  <Button message="Fazer login" icon={<RiLoginBoxLine />} />
                </Link>
              </>
            )}
          </MobileMenuContent>
          <img src={FooterLogo} alt="FooterLogo" />
        </MobileMenu>
      </MobileNavbar>
    </Container>
  );
};

export default NavbarMobile;
