import React from 'react';
import {
  Routes, Route, Navigate, useLocation, useSearchParams,
} from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Home from '../pages/Home';
import SignInCustomer from '../pages/SignInCustomer';
import SignInOperator from '../pages/SignInOperator';
import ForgotPassword from '../pages/ForgotPassword';
import NewPassword from '../pages/NewPassword';

import RequireAuth from './RequireAuth';
import RequireRole from './RequireRole';
import Dashboard from '../pages/Dashboard';
import Request from '../pages/Dashboard/Operator/Request';
import Customer from '../pages/Dashboard/Operator/Customer';
import MyOrders from '../pages/Dashboard/Customer/MyOrders';
import Profile from '../pages/Dashboard/Profile';
import Settings from '../pages/Dashboard/Customer/Settings';
import MyCards from '../pages/Dashboard/Customer/MyCards';
import IFramePage from '../pages/iFramePage';

const Router: React.FC = () => {
  const { role } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <Routes>
      <Route path="/login" element={<SignInCustomer redirect={location?.state?.path} />} />
      <Route path="/login-operator" element={<SignInOperator />} />
      <Route path="/esqueceu-senha" element={<ForgotPassword />} />
      <Route path="/nova-senha" element={<NewPassword />} />
      <Route path="/i-frame" element={<IFramePage />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate replace to="/" />} />

      <Route
        path="/portal"
        element={(
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        )}
      >
        <Route path="/portal/operador" element={<Navigate replace to="/portal/operador/solicitacoes" />} />
        <Route path="/portal/operador/solicitacoes" element={<RequireRole redirect="/" roleName={['operator', 'manager', 'admin']}><Request orderId={searchParams.get('orderId') || undefined} /></RequireRole>} />
        <Route path="/portal/operador/clientes" element={<RequireRole redirect="/" roleName={['operator', 'manager', 'admin']}><Customer /></RequireRole>} />
        <Route path="/portal/cliente" element={<Navigate replace to="/portal/cliente/solicitacoes" />} />
        <Route path="/portal/cliente/solicitacoes" element={<RequireRole redirect="/" roleName="customer"><MyOrders /></RequireRole>} />
        <Route path="/portal/cliente/configuracoes" element={<RequireRole redirect="/" roleName="customer"><Settings /></RequireRole>} />
        <Route path="/portal/cliente/meus-cartoes" element={<RequireRole redirect="/" roleName="customer"><MyCards /></RequireRole>} />
        <Route path="/portal/perfil" element={<RequireRole redirect="/" roleName={['operator', 'manager', 'admin', 'customer']}><Profile /></RequireRole>} />
        <Route path="" element={<Navigate replace to={`/portal/${role === 'customer' ? 'cliente' : 'operador'}/solicitacoes`} />} />
        <Route path="*" element={<Navigate replace to="/portal" />} />
      </Route>

    </Routes>
  );
};

export default Router;
