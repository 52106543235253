import React, {
  ReactElement, useContext, useMemo, useState,
} from 'react';
import { RiAlertFill, RiCheckLine, RiInformationLine } from 'react-icons/ri';
import { OrderRequestContext } from '..';
import { formatMoney } from '../../../utils/formatters';
import { getIssuer, getIssuerLogo, getIssuerByBrand } from '../../../utils/issuerLogos';
import Button from '../../Button';
import { TaxModal, WarningBox } from '../TaxInformations/styles';
import SecuredPayment from '../../../assets/SecuredPayment.svg';

import {
  Step,
  RequestConfirmationBox,
  RowItems,
  ItemValue,
  RowItem,
  ItemInstallment,
  ItemFinalValue,
  Divider,
  NavigationButtons,
  CardView,
  ImageCard,
  CardInfo,
  CardNumber,
  CardName,
} from './styles';

const RequestConfirmation = (): ReactElement => {
  const {
    prev,
    jump,
    submit,
    barcodeResponse: {
      settleDate,
      registerData,
      assignor,
    },
    paymentValue,
    totalValue,
    numberOfInstallments,
    installmentValue,
    description,
    serviceFee,
    creditCardInfo,
    selectedCreditCard,
    isLoading,
  } = useContext(OrderRequestContext);
  const [isVisibleTaxModal, setIsVisibleTaxModal] = useState<boolean>(false);

  const card = useMemo(
    () => {
      if (selectedCreditCard) {
        return {
          ...getIssuerByBrand(selectedCreditCard.attributes.brand),
          cardNumber: selectedCreditCard.attributes?.cardNumber,
          holder: selectedCreditCard?.attributes.holder,
        };
      }
      const cardNumber = creditCardInfo?.cardNumber?.slice(0, 6);
      return {
        ...getIssuer(creditCardInfo?.cardNumber?.slice(0, 6)),
        cardNumber: creditCardInfo?.cardNumber?.slice(-4),
        holder: creditCardInfo?.holder,
      };
    },
    [creditCardInfo],
  );

  return (
    <Step>
      <RequestConfirmationBox>
        <h3>Confirmação da transação:</h3>
        <RowItems>
          <ItemValue>
            Valor total da conta/boleto:
            <p>
              {formatMoney(paymentValue)}
            </p>
          </ItemValue>
          <RowItem>
            Data de vencimento:
            <small>{settleDate}</small>
          </RowItem>
          <RowItem>
            Beneficiário:
            <small>{registerData?.recipient || assignor}</small>
          </RowItem>
          <RowItem>
            Descrição:
            <small>{description}</small>
          </RowItem>

          <Divider />

          <RowItem>
            Parcelamento:
            <ItemInstallment>
              <small>
                <RiInformationLine
                  onClick={() => {
                    setIsVisibleTaxModal(true);
                  }}
                />
              </small>
              {`${numberOfInstallments}x`}
              {' '}
              de
              {' '}
              {formatMoney(installmentValue)}
            </ItemInstallment>
          </RowItem>
          <RowItem>
            Valor final:
            <ItemFinalValue>

              {formatMoney(totalValue)}
            </ItemFinalValue>
          </RowItem>

          <ItemValue>
            Forma de pagamento:
            <CardView>
              {card.logo && <ImageCard src={card.logo} alt="Credit card issuer logo" />}
              <CardInfo>
                <CardNumber>
                  (Crédito)
                  {' '}
                  {card.name}
                  {' '}
                  com final ****
                  {' '}
                  {card?.cardNumber}
                </CardNumber>
                <CardName>{card?.holder}</CardName>
              </CardInfo>
            </CardView>
          </ItemValue>
        </RowItems>
      </RequestConfirmationBox>
      <NavigationButtons>
        <Button message="Voltar" type="button" onClick={() => jump(3)} />
        <Button
          message="Confirmar transação"
          type="submit"
          onClick={() => {
            submit();
          }}
          icon={<RiCheckLine />}
          isLoading={isLoading}
          disabled={isLoading}
        />
      </NavigationButtons>
      {isVisibleTaxModal && (
        <TaxModal>
          <h3>Entenda a nossa tarifa</h3>
          <p>
            Para que possamos fornecer um serviço financeiro que caiba no seu
            bolso, temos algumas diretrizes financeiras que são agregadas ao
            valor final da sua transação, conforme consta abaixo:
          </p>
          <img src={SecuredPayment} alt="SecuredPayment" />
          <p>
            Para o valor do seu boleto, acrescido das parcelas selecionadas, sua
            tarifa será de
            {' '}
            <b>{formatMoney(serviceFee)}</b>
            .
          </p>

          {/* <WarningBox>
            <RiAlertFill />
            <p>
              <b>Atenção:</b>

              O valor da tarifa acrescido é um valor único,
              independente da quantidade de parcelas selecionadas.
            </p>
          </WarningBox> */}

          <button type="button" onClick={() => setIsVisibleTaxModal(false)}>
            Voltar
          </button>
        </TaxModal>
      )}
    </Step>
  );
};

export default RequestConfirmation;
