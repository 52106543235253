const tableHeader: any[] = [
  {
    id: 1,
    name: 'Cliente',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 2,
    name: 'CPF',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 3,
    name: 'Telefone',
    width: '15%',
    align: 'left',
    ordened: false,
  },
  {
    id: 4,
    name: 'Status',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 5,
    name: 'Data da criação',
    width: '17%',
    align: 'left',
    ordened: true,
  },
  {
    id: 6,
    name: 'Ações',
    width: '13%',
    align: 'center',
    ordened: false,
  },
];

const tableBody: any[] = [];

const requestStatus = {
  rejected: 'Rejeitado',
  approved: 'Aprovado',
  pendingApproval: 'Aguardando aprovação',
  pending: 'Aguardando aprovação',
};

export {
  requestStatus,
  tableHeader,
  tableBody,
};
