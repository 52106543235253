import styled, { css } from 'styled-components';
import ArrowDownFill from '../../assets/ArrowDownFill.svg';

export const InputControl = styled.div`
  ${({ theme }) => css`
    margin-bottom: 24px;
    width: 100%;

    display: flex;
    flex-direction: column;

    position: relative;

    > small {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 24px;
      color: ${theme.colors.black};
      margin-bottom: 12px;
    }

    input {
      width: 100%;
      border: 1px solid ${theme.colors.grey};
      position: relative;
      height: 60px;
      border-radius: 6px;
      padding: 20px 24px 20px 20px;
      color: ${theme.colors.black};
      font-size: 1.4rem;
      line-height: 2.4rem;

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.primaryBlack30};
        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: ${theme.colors.black};
      }
    }

    &.error {
      > small {
        color: ${theme.colors.error};
      }

      > input {
        border-color: ${theme.colors.error};
      }
    }

    select {
      border: 1px solid ${theme.colors.grey};
      position: relative;
      height: 60px;
      border-radius: 6px;
      padding: 20px 24px 20px 20px;
      color: ${theme.colors.black};
      font-size: 1.4rem;

      background-color: transparent;
      transition: ${theme.transitions.default};
      background: url(${ArrowDownFill}) no-repeat right;
      -webkit-appearance: none;
      background-position-x: calc(100% - 24px);

      &::placeholder {
        color: ${theme.colors.primaryBlack30};
        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: ${theme.colors.black};
      }
    }   
  `};
`;

export const Button = styled.button`
  ${({ theme }) => css`
    border-radius: 6px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    height: 60px;
    width: 100%;
    letter-spacing: -0.2px;
    font-size: 1.8rem;
    line-height: 22px;
    font-weight: 600;
    /* margin-bottom: 24px; */

    transition: ${theme.transitions.default};

    &:hover, &:active {
      opacity: 0.8;
    }
  `};
`;

export const BackButton = styled.button`
  ${({ theme }) => css`
    height: 60px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.colors.primary};
    letter-spacing: -0.2px;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;
    max-width: 99px;
    width: 100%;
    margin-right: 10px;
  `};
`;

export const Checkbox = styled.div`
  ${({ theme }) => css`
    height: 22px;
    width: 22px;
    border: 1px solid ${theme.colors.primary};
    border-radius: 3px;
    margin-right: 12px;
    cursor: pointer;
  `};
`;

export const UploadControl = styled.div`
  ${({ theme }) => css`
    > small {
      color: ${theme.colors.black};
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: 400;
    }
  `};
`;

export const UploadGrid = styled.div`
  margin: 32px 0;
`;

export const UploadBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundGreen};
    border-radius: 6px;
    padding: 42px;
    border: 1px dashed ${theme.colors.primary};
    margin: 12px 0 24px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > h2 {
      letter-spacing: -0.1px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 600;
    }

    > p {
      color: ${theme.colors.black};
      opacity: 0.3;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400;
      margin-top: 4px;
    }
  `};
`;

export const UploadButtons = styled.div`
  ${({ theme }) => css`
    margin-top: 24px;

    display: flex;
    align-items: center;

    > small {
      margin: 0 8px;
      color: ${theme.colors.black};
      font-size: 1rem;
      line-height: 1.6rem;
      font-weight: 500;
    }

    > button {
      background-color: ${theme.colors.primary};
      padding: 8px 12px;
      border-radius: 6px;
      color: ${theme.colors.white};
      font-size: 1.2rem;
      line-height: 18px;
      font-weight: 600;
      letter-spacing: -0.1px;

      > svg {
        font-size: 1.6rem;
        margin-right: 6px;
      }
    }
  `};
`;

export const Warning = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    margin-top: auto;
    margin-bottom: 22px;
    background-color: ${theme.colors.backgroundWarning};
    padding: 16px;
    border-radius: 6px;

    display: flex;
    align-items: center;

    @media(max-width: 480px) {
      padding: 16px;
      }

    > p {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      max-width: 282px;
    }

    > svg {
      color: ${theme.colors.warning};
      font-size: 2.4rem;
      margin-right: 12px;

      @media(max-width: 480px) {
        width: 100%;
        max-width: 20px;
      }
    }
  `};
`;
