import React, {
  createContext, useEffect, useState, useContext,
} from 'react';

const getScreenInfo = (): ScreenInfo => {
  let orientation: ScreenInfo['orientation'];
  if (!navigator.maxTouchPoints) {
    orientation = 'desktop';
  } else if (window.screen?.orientation) {
    orientation = !window?.screen?.orientation?.angle
      ? 'portrait'
      : 'landscape';
  } else {
    orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
  }
  return {
    device: navigator.maxTouchPoints ? 'mobile' : 'computer',
    orientation,
  };
};

export type ScreenInfo = {
  device: 'mobile' | 'computer';
  orientation: 'desktop' | 'portrait' | 'landscape';
};

const ScreenInfoContext = createContext<ScreenInfo>(getScreenInfo());

export const ScreenInfoContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [screenInfo, setScreenInfo] = useState<ScreenInfo>(getScreenInfo());

  function detectScreenInfo(): void {
    setScreenInfo(getScreenInfo());
  }

  useEffect(() => {
    window.addEventListener('resize', detectScreenInfo);
    return () => {
      window.removeEventListener('resize', detectScreenInfo);
    };
  }, []);

  return (
    <ScreenInfoContext.Provider value={screenInfo}>
      {children}
    </ScreenInfoContext.Provider>
  );
};

export function useScreenInfo(): ScreenInfo {
  return useContext(ScreenInfoContext);
}
