import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  min-height: -webkit-fill-available;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 100%;
    border-radius: 4px;
    flex: 1;

    /* position: absolute;
    top: 0;
    bottom: 0; */

    padding: 32px;
  `};

  @media (max-width: 480px) {
    top: 0;
    height: 100vh;
  }
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* position: sticky; */
    /* top: 0; */
    width: 100%;
    z-index: 1000;
    background-color: ${theme.colors.white};
    padding: 0 0 24px;
    border-radius: 4px 4px 0 0;

    > svg {
      font-size: 2.5rem;
      color: ${theme.colors.primaryBlack00};
      cursor: pointer;
    }
  `};
`;

export const Brand = styled.div`
  display: flex;
  align-items: flex-end;

  ${({ theme }) => css`
    > img {
      max-width: 30px;
      width: 100%;
    }

    > h2 {
      font-size: 2.4rem;
      line-height: 3rem;
      letter-spacing: -1%;

      > p {
        font-size: 1.3rem;
        line-height: 1.6rem;
        font-weight: 400;

        color: ${theme.colors.primaryBlack00};
        opacity: 0.5;
      }
    }
  `}
`;

export const Tag = styled.div<{ status?: string }>`
  ${({ theme, status }) => css`
    height: 24px;
    padding: 6px 10px;
    border-radius: 4px;
    background-color: ${theme.colors.backgroundGreen};
    font-size: ${theme.colors.bodyParagraphXS};
    color: ${theme.colors.primary};
    margin-left: 18px;

    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    ${status === 'pending'
    && css`
      background-color: ${theme.colors.yellow}10;
      color: ${theme.colors.yellow};
    `};

    ${status === 'approved'
    && css`
      background-color:rgba(78, 171, 224, 0.1);
      color: #4EABE0;
    `};

    ${status === 'rejected'
    && css`
      background-color: ${theme.colors.red};
      color: ${theme.colors.error};
    `};

    ${status === 'liquidated'
    && css`
      background-color: ${theme.colors.primary}10;
      color: ${theme.colors.primary};
    `};

    > svg {
      margin-right: 6px;
    }
  `};
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentLeft = styled.div`
  ${({ theme }) => css`

  display: flex;
  align-items: center;
  width: 50%;

  > button {
    background-color: white;
    width: 100%;
    max-width: 100px;

    color: ${theme.colors.primaryBlack00};
    border: 1px solid ${theme.colors.primaryBlack20};

    ${theme.texts.buttonTextXS};

    padding: 10px;
    margin-left: 24px;

    &:last-child{
      max-width: 155px;
    }
  }
  `}
`;

export const SecurePaymentIcon = styled.div`
  width: 100%;
  max-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;

  ${({ theme }) => css`
    background-color: ${theme.colors.primary20};
    border-radius: 8px;

    > svg {
      font-size: 2.8rem;
      color: ${theme.colors.primary};
    }
  `}
`;

export const ValueRequest = styled.div`
  /* width: 100%; */
  ${({ theme }) => css`
    ${theme.texts.bodyParagraphSM};
  `}
`;

export const ContentRight = styled.div`
  display: flex;
  ${({ theme }) => css`
    > button {
      &:first-child {
        border: 1px solid ${theme.colors.primaryBlack20};
        background-color: ${theme.colors.white};
        margin-right: 14px;
      }
      &:last-child {
        border: 1px solid ${theme.colors.primary};
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      }
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    margin: 24px auto;

    background-color: ${theme.colors.primaryBlack10};
  `}
`;

export const DynamicContent = styled.div``;

export const LiquidationModal = styled.div`
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LiquidationModalContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 6px;
    max-width: 466px;
    width: 100%;
    padding: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > svg {
      color: ${theme.colors.primaryBlack00};
      font-size: 4.4rem;
      margin: 0 0 24px 0;
    }

    > h4 {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.titleHeadingLG};
      text-align: center;
    }

    > button {
      width: 100%;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:last-child {
        background-color: transparent;
        color: ${theme.colors.primaryBlack00};
      }
    }
  `};
`;

export const LiquidationModalCustomerInfos = styled.div`
  ${({ theme }) => css`
    border: 2px dashed ${theme.colors.primaryBlack10};
    border-radius: 6px;
    width: 100%;
    margin: 24px 0;
    padding: 32px;
  `};
`;

export const LiquidationModalCustomerRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > small {
      margin: 0 0 8px 0;
      color: ${theme.colors.primaryBlack00};
      opacity: 0.5;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 2.2rem;
    }

    > h5 {
      font-size: 2.4rem;
      line-height: 2.2rem;
      font-weight: 600;
      color: ${theme.colors.primaryBlack00};
    }

    > h6 {
      color: ${theme.colors.primaryBlack00};
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;

      display: flex;
      align-items: center;

      > span {
        font-size: 1.2rem;
        line-height: 2.2rem;
        font-weight: 400;
        margin: 0 0 0 6px;
      }
    }

    &:first-child {
      margin: 0 0 18px 0;
      padding: 0 0 18px 0;
      border-bottom: 1px solid ${theme.colors.primaryBlack10};
    }
  `};
`;

export const FeedbackModal = styled.div`
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FeedbackModalContent = styled.form`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 6px;
    max-width: 550px;
    width: 100%;
    padding: 36px;

    display: flex;
    flex-direction: column;

    > h4 {
      ${theme.texts.titleHeadingLG};
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > button {
      background-color: ${theme.colors.primaryBlack00};
      color: ${theme.colors.white};
    }
  `};
`;

export const TextAreaControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    margin: 24px 0;

    > small {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.bodyParagraphMD};
      margin: 0 0 12px 0;
    }

    > textarea {
      resize: none;
      height: 134px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.primaryBlack20};
      padding: 20px;
    }
  `};
`;

export const TextControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.primaryBlack00};
      ${theme.texts.bodyParagraphMD};
      margin: 0 0 12px 0;
    }

    > span {
      font-size: 1.2rem;
      
      color: ${theme.colors.primaryBlack00};
      /* border-radius: 4px;
      border: 1px solid ${theme.colors.primaryBlack20}; */
    }

    > input {
      height: 40px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.primaryBlack20};
      padding: 20px;
    }
  `};
`;
