import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { RiDeleteBack2Fill, RiDeleteBinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { DashboardContext } from '../..';
import AlertModal from '../../../../components/AlertModal';
import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import { creditCardService } from '../../../../services';
import {
  CreditCardCore,
  CreditCardResponse,
} from '../../../../types/creditCard';
import { getErrorMessage } from '../../../../utils/errorCodes';
import { getIssuerByBrand } from '../../../../utils/issuerLogos';
import NewCard from './NewCard';

import {
  Container,
  CardView,
  ImageCard,
  CardInfo,
  CardNumber,
  CardName,
  EmptyCard,
} from './styles';

const MyCards = (): ReactElement => {
  const { isLoading, setIsLoading } = useContext(DashboardContext);
  const [cards, setCards] = useState<CreditCardResponse[]>([]);
  const [cardId, setCardId] = useState<number>(0);

  const [isVisibleNewCard, setIsVisibleNewCard] = useState(false);
  const [isVisibleRemoveCard, setIsVisibleRemoveCard] = useState(false);

  async function fetchData(): Promise<void> {
    setIsLoading(true);
    try {
      const { data } = await creditCardService.get();
      setCards(data);
    } catch (err: any) {
      toast.error(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect((): void => {
    fetchData();
  }, []);

  const handleSubmit = async (values: CreditCardCore): Promise<void> => {
    setIsLoading(true);
    try {
      await creditCardService.create(values);
      toast.success('Cartão adicionado com sucesso!');
      fetchData();
      setIsVisibleNewCard(false);
    } catch (err: any) {
      toast.error(
        err.response?.data?.error?.details?.message
          || getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (): void => {
    setIsVisibleRemoveCard(true);
  };

  async function removeCreditCard(id: number): Promise<void> {
    setIsLoading(true);
    try {
      if (id) {
        await creditCardService.delete(id);
        setIsVisibleRemoveCard(false);
        toast.success('Cartão removido com sucesso!');
        fetchData();
      }
    } catch (err: any) {
      toast.error(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Container>
        {cards.length ? (
          <>
            {cards.map((card) => {
              const issuer = getIssuerByBrand(card?.attributes?.brand);

              return (
                <CardView key={card.id}>
                  {!!issuer && (
                    <ImageCard
                      src={issuer.logo}
                      alt="Credit card issuer logo"
                    />
                  )}
                  <CardInfo>
                    <CardNumber>
                      (Crédito)
                      {' '}
                      {issuer?.name}
                      {' '}
                      com final ****
                      {' '}
                      {card.attributes.cardNumber}
                    </CardNumber>
                    <CardName>{card.attributes.holder}</CardName>
                  </CardInfo>

                  <RiDeleteBinLine onClick={() => { handleRemove(); setCardId(card?.id); }} />
                </CardView>
              );
            })}
          </>
        ) : (
          <EmptyCard>Nenhum cartão salvo ainda.</EmptyCard>
        )}
        <Button
          type="button"
          message="Adicionar novo cartão + "
          onClick={() => setIsVisibleNewCard(true)}
        />
        {isVisibleNewCard && (
          <NewCard
            onSubmit={handleSubmit}
            handleClose={() => setIsVisibleNewCard(false)}
          />
        )}
      </Container>

      {isVisibleRemoveCard && (
        <AlertModal
          typeAlert="alert"
          title="Excluir cartão ?"
          text="Você poderá utilizar quando precisar, basta adicioná-lo novamente."
          messageButton="Sim, excluir cartão"
          messageClose="Fechar"
          onClick={() => {
            removeCreditCard(cardId);
          }}
          cancel={() => setIsVisibleRemoveCard(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default MyCards;
