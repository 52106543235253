const moneyFormatterBRL = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});
export function formatMoney(value?: string | number): string {
  return moneyFormatterBRL.format(+(value || 0));
}

export function formatDateWithHour(value: string | undefined): string {
  return value ? `${new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(value))} às ${new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric', minute: 'numeric',
  }).format(new Date(value))}` : '';
}

export function formatDate(value: string | undefined): string | undefined {
  return value ? `${new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(value))}` : undefined;
}

export function formatDateString(value: string | undefined): string {
  return value ? `${new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(value))}` : '0';
}

export function formatPhone(value?: string): string {
  return value ? value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3 $4')
    .replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2 $3')
    .replace(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/, '+$1 $2 $3 $4') : '0';
}

export function formatCPF(value?: string): string {
  return value ? value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4') : '0';
}

export function formatDigitable(value?: string): string {
  return value ? value
    .replace(/^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/, '$1.$2 $3.$4 $5.$6 $7 $8')
    .replace(/^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})(\d{1})$/, '$1.$2 $3.$4 $5.$6 $7 $8 $9') : '0';
}
