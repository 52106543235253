import creditCardType from 'credit-card-type';
import LogoVisa from '../assets/BrandVisa.png';
import LogoMastercard from '../assets/BrandMaster.png';
import LogoAmericanExpress from '../assets/BrandAmericanExpress.png';
import LogoElo from '../assets/BrandElo.png';
import LogoDinersClub from '../assets/BrandDinersClub.png';
import LogoDiscover from '../assets/BrandDiscover.png';
import LogoJcb from '../assets/BrandJcb.png';
import LogoAura from '../assets/BrandAura.png';
import LogoHipercard from '../assets/BrandHipercard.png';

export const logos = {
  VISA: LogoVisa,
  MASTERCARD: LogoMastercard,
  AMERICAN_EXPRESS: LogoAmericanExpress,
  ELO: LogoElo,
  DINERS_CLUB: LogoDinersClub,
  DISCOVER: LogoDiscover,
  JCB: LogoJcb,
  AURA: LogoAura,
  HIPERCARD: LogoHipercard,
};

export const s2pTypes: Record<number, Issuer> = {
  1: { logo: logos.VISA, name: 'Visa' },
  2: { logo: logos.MASTERCARD, name: 'Mastercard' },
  3: { logo: logos.AMERICAN_EXPRESS, name: 'American Express' },
  7: { logo: logos.ELO, name: 'Elo' },
  8: { logo: logos.AURA, name: 'Aura' },
  9: { logo: logos.JCB, name: 'Jcb' },
  10: { logo: logos.DINERS_CLUB, name: 'Diners Club' },
  11: { logo: logos.DISCOVER, name: 'Discover' },
  12: { logo: logos.HIPERCARD, name: 'Hipercard' },
};

export const ccTypes = {
  visa: logos.VISA,
  mastercard: logos.MASTERCARD,
  'american-express': logos.AMERICAN_EXPRESS,
  elo: logos.ELO,
  'diners-club': logos.DINERS_CLUB,
  discover: logos.DISCOVER,
  jcb: logos.JCB,
  aura: logos.AURA,
  hipercard: logos.HIPERCARD,
};

interface Issuer {
  logo?: string,
  name?: string,
}

export function getIssuer(cardNumber: string): Issuer {
  if (cardNumber?.length < 2) {
    return {};
  }
  const creditCard = creditCardType(cardNumber)?.[0];
  if (creditCard) {
    const { type, niceType } = creditCard;
    return { logo: ccTypes[type as keyof typeof ccTypes], name: niceType };
  }
  return {};
}

export function getIssuerLogo(cardNumber: string): string | undefined {
  return getIssuer(cardNumber).logo;
}

export function getIssuerByBrand(brand: number): Issuer {
  return s2pTypes[brand as keyof typeof s2pTypes];
}
