import styled, { css } from 'styled-components';

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      width: 90px;
      height: 38px;

      border-radius: 4px;

      ${theme.texts.buttonTextXS};
    }

    .analyze {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.primary};
    }

    .view {
      border: 1px solid ${theme.colors.primaryBlack10};
      background-color: ${theme.colors.white};
    }
  `}
`;

export const BtnDelete = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.error} !important;

    > svg {
      color: ${theme.colors.white};
    }
  `}
`;
