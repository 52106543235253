import React, { ReactElement, useContext, useState } from 'react';
import * as Yup from 'yup';

import { Field, Formik, getIn } from 'formik';
import { cpfMask, phoneMask8, phoneMask9 } from '../../../utils/masks';

import Button from '../../Button';
import ErrorMessage from '../../ErrorMessage';

import { InputControl } from '../../../styles/components';

import { Step, StepForm, NavigationButtons } from './styles';
import InputMask from '../../MaskedInput';
import { OnboardingSteps } from '../../../types/onboarding';
import { OnboardingContext } from '..';

interface UserDataProps {
  prevPage: () => void;
}

export const UserData = ({ prevPage }: UserDataProps): ReactElement => {
  const {
    next, prev, onboardingData, setOnboardingData,
  } = useContext(OnboardingContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues: OnboardingSteps['user'] = onboardingData.user || {
    name: '',
    cpf: '',
    phone: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('O campo "Nome completo" é obrigatório.'),
    cpf: Yup.string()
      .matches(/\d{11}/, 'O formato de CPF é inválido.')
      .required('O campo "CPF" é obrigatório.'),
    phone: Yup.string()
      .matches(/\d{10,11}/, 'O formato de telefone é inválido.')
      .required('O campo "Telefone" é obrigatório.'),
  });

  return (
    <Step>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          setOnboardingData({
            ...onboardingData,
            user: values,
          });
          setTimeout(() => {
            next?.();
          }, 1500);
        }}
      >
        {(formikProps) => (
          <StepForm onSubmit={formikProps.handleSubmit}>
            <InputControl className={formikProps?.errors?.name ? 'error' : ''}>
              <small>Nome completo:</small>
              <Field
                name="name"
                type="text"
                placeholder="João da Silva"
                autoComplete="name"
              />
              <ErrorMessage message={formikProps.errors.name} />
            </InputControl>

            <InputControl className={formikProps?.errors?.cpf ? 'error' : ''}>
              <small>CPF:</small>
              <InputMask
                name="cpf"
                placeholder="000.000.000-00"
                mask={cpfMask}
                guide={false}
                value={formikProps.values.cpf}
                onChange={({ target: { value } }) => formikProps.setFieldValue('cpf', value?.replace(/\D/g, ''))}
                className={formikProps?.errors?.name ? 'error' : ''}
              />
              <ErrorMessage message={formikProps.errors.cpf} />
            </InputControl>

            <InputControl className={formikProps?.errors?.phone ? 'error' : ''}>
              <small>Telefone:</small>
              <InputMask
                name="phone"
                placeholder="(00) 0 0000-0000"
                mask={
                  formikProps.values.phone?.length > 10
                    ? phoneMask9
                    : phoneMask8
                }
                autoComplete="tel-national mobile"
                value={formikProps.values.phone}
                guide={false}
                onChange={({ target: { value } }) => formikProps.setFieldValue('phone', value?.replace(/\D/g, ''))}
                className={formikProps?.errors?.name ? 'error' : ''}
              />
              <ErrorMessage message={formikProps.errors.phone} />
            </InputControl>

            <NavigationButtons>
              <Button message="Voltar" type="button" onClick={prevPage} />
              <Button message="Continuar" type="submit" isLoading={isLoading} />
            </NavigationButtons>
          </StepForm>
        )}
      </Formik>
    </Step>
  );
};

export default UserData;
