import styled, { css } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};  
`;

export const InputControlRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      max-width: 48%;
    }

    > div { &:nth-child(3n) {
      max-width: 100%;
    }}
  }

  > div {
    &:not(:last-child) {
      margin-right: 12px;

      @media (max-width: 480px) {
        margin-right: 0;
      }
    }
  }
`;

export const NavigationButtons = styled.div`
  ${
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;
