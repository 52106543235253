export const errorCodes = {
  INVALID_ID_PASSWORD: 'E-mail ou senha inválido.',
  EMAIL_NOT_CONFIRMED: 'E-mail não confirmado. Por favor verifique seu e-mail.',
  ACCOUNT_BLOCKED: 'Conta bloqueada.',
  SIMULATOR_OUT_OF_RANGE: 'Valor não aceito.',
  CUSTOMER_NOT_FOUND: 'Não foi possível encontrar o cliente.',
  CUSTOMER_ALREADY_REVIEWD: 'Cliente já analisado.',
  NOT_A_CUSTOMER: 'Usuário não é um cliente.',
  BOLETO_NOT_FOUND: 'Boleto não encontrado.',
  CC_TOKENIZATION_ERROR: 'Não foi possível salvar os dados do cartão.',
  CC_NOT_FOUND: 'Não foi possível encontrar o cartão de crédito.',
  CC_COULD_NOT_DELETE: 'Não foi possível deletar o cartão de crédito.',
  PAYMENT_PROCESSING_ERROR: 'Erro no processamento do pagamento.',
  PAYMENT_LIQUIDATION_ERROR: 'Erra na liquidação do pagamento.',
  ORDER_NOT_FOUND: 'Solicitação não encontrada.',
  ORDER_CREATION_ERROR: 'Criação da solicitação falhou.',
  ORDER_ALREADY_REVIEWD: 'Solicitação já analisada.',
  ORDER_APPROVAL_ERROR: 'Falha ao aprovar a Solicitação.',
  ORDER_REJECTION_ERROR: 'Falha ao rejeitar a Solicitação.',
  ORDER_EXPIRED: 'Solicitação expirada, favor aprovar novamente.',
  OFF_BUSINESS_HOURS:
    'Para que possamos liquidar seu boleto no mesmo dia, pedimos para que sua solicitação seja enviada em dia útil das 09h às 17h. Agradecemos pela compreensão.',
  TOS_ACCEPT_UPDATED: 'Termos de uso atualizados.',
};

export type ErrorKey = keyof typeof errorCodes;

export const errorLabels = Object.fromEntries(
  Object.keys(errorCodes).map((k) => [k, k]),
) as { [k in ErrorKey]: k };

export function getErrorMessage(serverCode: string): string | undefined {
  return errorCodes[serverCode as ErrorKey];
}
