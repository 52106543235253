import React, { ReactElement, useContext, useState } from 'react';
import { useSteps } from 'react-step-builder';
import * as Yup from 'yup';

import {
  RiAlertFill,
  RiInformationLine,
  RiLightbulbLine,
  RiLock2Line,
  RiUserSmileLine,
} from 'react-icons/ri';
import { Field, Formik } from 'formik';
import ScanOnBoarding from '../../../assets/ScanOnBoarding.svg';

import Button from '../../Button';

import {
  Step,
  StepForm,
  ScanDocumentOnBoarding,
  OnBoardingSteps,
  OnBoardingStep,
  WarningBox,
  NavigationButtons,
} from './styles';
import ImageSelector from '../DocumentsScan/ImageSelector';
import { OnboardingSteps } from '../../../types/onboarding';
import { OnboardingContext } from '..';
import ErrorMessage from '../../ErrorMessage';
import DocumentScanModal from '../DocumentsScan/DocumentScanModal';

export const SelfieScan = (): ReactElement => {
  const {
    next, prev, onboardingData, setOnboardingData,
  } = useContext(OnboardingContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scanStep, setScanStep] = useState<'info' | 'capture' | null>(null);

  const initialValues: OnboardingSteps['documentSelfie'] = onboardingData.documentSelfie || {
    selfie: null,
  };

  const validationSchema = Yup.object().shape({
    selfie: Yup.mixed().required(
      'O campo "Selfie com documento" é obrigatório.',
    ),
  });

  return (
    <>
      <Step>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            setIsLoading(true);
            setOnboardingData({
              ...onboardingData,
              documentSelfie: values,
            });
            setTimeout(() => {
              next?.();
            }, 1500);
          }}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <StepForm onSubmit={formikProps.handleSubmit}>
              <Field
                component={ImageSelector}
                onOpenScanner={() => setScanStep('info')}
                name="selfie"
                title="Selfie com documento:"
                subTitle="Anexar selfie com documento em mãos"
              />

              <ErrorMessage message={formikProps.errors.selfie} />

              <WarningBox>
                <RiAlertFill />
                <p>
                  <b>Atenção:</b>
                  {' '}
                  Sua foto deverá conter seu rosto com boa
                  iluminação e seu documento de identificação com foto visível.
                </p>
              </WarningBox>

              <NavigationButtons>
                <Button
                  message="Voltar"
                  type="button"
                  onClick={() => {
                    setOnboardingData({
                      ...onboardingData,
                      documentSelfie: formikProps.values,
                    });
                    prev?.();
                  }}
                />
                <Button message="Continuar" type="submit" isLoading={isLoading} />
              </NavigationButtons>
              {scanStep === 'capture' && (
                <DocumentScanModal
                  name="selfie"
                  onClose={() => setScanStep(null)}
                  onCapture={(file) => {
                    formikProps.setFieldValue('selfie', file);
                    setScanStep(null);
                  }}
                  // mask={}
                />
              )}
            </StepForm>
          )}
        </Formik>
      </Step>

      {scanStep === 'info' && (
        <ScanDocumentOnBoarding>
          <img src={ScanOnBoarding} alt="ScanOnBoarding" />
          <OnBoardingSteps>
            <OnBoardingStep>
              <RiLightbulbLine />
              Escolha um ambiente com boa iluminação
            </OnBoardingStep>
            <OnBoardingStep>
              <RiLock2Line />
              Usaremos sua câmera para captar sua biometria facil. Esse processo
              é 100% confidencial.
            </OnBoardingStep>
            <OnBoardingStep>
              <RiInformationLine />
              Não use acessórios, como: boné, óculos e afins.
            </OnBoardingStep>
            <OnBoardingStep>
              <RiUserSmileLine />
              Segure seu documento ao lado do seu rosto, de forma que fique
              ambos fiquem visíveis.
            </OnBoardingStep>
          </OnBoardingSteps>

          <NavigationButtons>
            <Button
              message="Voltar"
              type="button"
              onClick={() => setScanStep(null)}
            />
            <Button
              message="Continuar"
              type="button"
              onClick={() => setScanStep('capture')}
              isLoading={isLoading}
            />
          </NavigationButtons>
        </ScanDocumentOnBoarding>
      )}
    </>
  );
};

export default SelfieScan;
