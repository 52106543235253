import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import * as Yup from 'yup';

import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import { InputControl } from '../../../styles/components';

import {
  Step,
  StepForm,
  TermsOfUse,
  TermsOfUseModal,
  TermsOfUserContent,
  TermsOfUseModalTexts,
  NavigationButtons,
} from './styles';
import { OnboardingForm, OnboardingSteps } from '../../../types/onboarding';
import { OnboardingContext } from '..';
import ErrorMessage from '../../ErrorMessage';
import { customerService, tosVersionService } from '../../../services';
import { getErrorMessage } from '../../../utils/errorCodes';
import { TOSVersionResponse } from '../../../types/tosVersion';

export const SignInInformations = ({ viewMode }: any): ReactElement => {
  const {
    next, prev, onboardingData, setOnboardingData,
  } = useContext(OnboardingContext);

  const [isVisibleTerms, setIsVisibleTerms] = useState<boolean>(false);
  const [isVisibleEmailConfirmation, setIsVisibleEmailConfirmation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tosVersion, setTosVersion] = useState<TOSVersionResponse>();

  const initialValues: OnboardingSteps['info'] = {
    email: '',
    password: '',
    passwordConfirmation: '',
    acceptedTOS: false,
    enableDDA: true,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('O campo e-mail não é válido.')
      .required('O campo "e-mail" é obrigatório.'),
    password: Yup.string()
      .min(8, 'A senha deve conter ao menos 8 caracteres.')
      .required('O campo "senha" é obrigatório.'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'As senhas digitadas devem coincidir.',
    ),
    acceptedTOS: Yup.bool().isTrue('O aceite dos termos de uso é obrigatório.'),
  });

  const handleSubmit = async (
    values: OnboardingSteps['info'],
  ): Promise<void> => {
    setIsLoading(true);
    const data = {
      ...onboardingData,
      info: values,
    };
    setOnboardingData(data);

    const {
      user: userData,
      info,
      address,
      documentID: { frontDocument, backDocument },
      documentSelfie: { selfie },
    } = data;

    if (frontDocument && backDocument && selfie) {
      const onboardingForm: OnboardingForm = {
        data: {
          ...userData,
          ...info,
          address,
        },
        frontDocument,
        backDocument,
        selfie,
      };

      try {
        const { jwt, customer, user } = await customerService.register(
          onboardingForm,
        );

        // setToken({ jwt, user });

        setTimeout(() => {
          next?.();
        }, 1500);
      } catch (err: any) {
        toast.error(
          getErrorMessage(err.response?.data?.error?.details?.serverCode)
            || 'Não foi possível cadastrar usuário',
        );
      }
    }
  };

  // async function fetchTerms(): Promise<void> {
  //   try {
  //     const { data } = await tosVersionService.getLatest();
  //     setTosVersion(data);
  //   } catch (err: any) {
  //     toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
  //   }
  // }

  // useEffect((): void => {
  //   fetchTerms();
  // }, []);

  // const text = tosVersion?.attributes?.content || '';

  return (
    <Step>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <StepForm onSubmit={formikProps.handleSubmit}>
            <InputControl className={formikProps?.errors?.email ? 'error' : ''}>
              <small>E-mail:</small>
              <Field
                type="email"
                autoComplete="email"
                placeholder="email@email.com.br"
                name="email"
              />
              <ErrorMessage message={formikProps.errors.email} />
            </InputControl>

            <InputControl
              className={formikProps?.errors?.password ? 'error' : ''}
            >
              <small>Crie uma senha:</small>
              <Field
                type="password"
                autoComplete="new-password"
                placeholder="*************"
                name="password"
              />
              <ErrorMessage message={formikProps.errors.password} />
            </InputControl>

            <InputControl
              className={
                formikProps?.errors?.passwordConfirmation ? 'error' : ''
              }
            >
              <small>Repita sua senha:</small>
              <Field
                type="password"
                autoComplete="new-password"
                placeholder="*************"
                name="passwordConfirmation"
              />
              <ErrorMessage message={formikProps.errors.passwordConfirmation} />
            </InputControl>

            <TermsOfUserContent>
              <TermsOfUse>
                <Field component={Checkbox} name="acceptedTOS" />
                Aceite dos
                {' '}
                <button type="button" onClick={() => setIsVisibleTerms(true)}>
                  termos de uso
                </button>
                .
                {isVisibleTerms && (
                  <TermsOfUseModal viewMode={viewMode}>
                    <h3>Termos de uso</h3>
                    <TermsOfUseModalTexts>
                      {/* <ReactMarkdown className="markdown" components={{ h3: 'h2' }}>
                      </ReactMarkdown> */}

                      <p>
                        CONTRATADA: OCCA SERVICOS FINANCEIROS LTDA, pessoa jurídica de direito privado na forma de sociedade limitada, com sede em Bento Gonçalves/RS, sito à Tv. Maceió, nº 28, sala 214, Bairro Centro, CEP: 95.700-114, inscrita no CNPJ/MF sob nº 43.527.033/0001-05.
                        <br />
                        CONTRATANTE: Desde já é estabelecido que o CONTRATANTE, pessoa responsável por esse contrato, é aquela identificada e qualificada no cadastro de usuário, disponível na plataforma online do contratado.
                        <br />
                        “As partes supra nomeadas e qualificadas resolvem, por este instrumento particular e na melhor forma de direito; livres de quaisquer constrangimentos ou vícios de vontade, ajustar contrato de prestação de serviços, a ser regido pelas cláusulas e condições adiante fixadas.”
                      </p>
                      <h3>DO OBJETO</h3>

                      <p> Cláusula 1ª. O presente contrato tem por objeto a INTERMEDIAÇÃO do pagamento (s) de conta (s) vincenda (s) constantes em boleto (s) ou documentos (s) similar (es) de titularidade do CONTRATANTE, através de cartões de crédito também de sua titularidade mediante disponibilidade de limite de crédito. </p>

                      <h3>DAS CONDIÇÕES E MODO DO SERVIÇO</h3>

                      <p>
                        Cláusula 2ª. A intermediação do pagamento está sujeita a análise discricionária da CONTRATADA, bem como análise e aprovação do cartão de crédito utilizado e seu limite, conforme pactuado e regido pelas cláusulas desse contrato.
                        <br />
                        Parágrafo único. A CONTRATADA disporá de 72h (Setenta e duas horas) para a realização da análise citada na cláusula 2ª desse instrumento.
                        <br />
                        Cláusula 3ª. A intermediação do pagamento somente será realizada após a análise e a aprovação da operação, podendo a CONTRATADA negar-se a realizar a intermediação do pagamento de forma discricionária e imotivada.

                      </p>

                      <h3>DO PRAZO</h3>

                      <p>
                        Cláusula 4ª. O presente contrato vigorará por prazo determinado a ser estipulado conforme prazos e condições disponíveis na plataforma online de contratação no site da CONTRATADA sendo renovado automaticamente, exceto no caso em que as partes não o queiram, nesse caso deverão comunicar por escrito uma a outra.
                        <br />
                        Parágrafo único. O presente contrato tem seu aceite pela CONTRATANTE de forma irrevogável e irretratável, ficando esse ciente de que os termos aqui aceitos regem a relação das partes daqui em diante, podendo essa tomar o serviço da CONTRATADA por diversas vezes, sem assinatura de novo termo.
                        <br />
                      </p>

                      <h3>DA REMUNERAÇÃO E PAGAMENTO</h3>

                      <p>Cláusula 5ª. A CONTRATANTE pagará a CONTRATADA a título de HONORÁRIOS pelo serviço prestado uma tarifa preestabelecida, informada ao CONTRATANTE no ato da contratação. A tarifa irá variar conforme o valor do documento a ser pago e o prazo do parcelamento.</p>

                      <h3>DO CARTÃO DE CRÉDITO INDICADO</h3>

                      <p>
                        Cláusula 6ª. A CONTRATANTE indicará por sua exclusiva responsabilidade e conforme sua vontade, sob as penas da legislação vigente, o cartão de crédito a ser utilizado para a realização do serviço contratado.
                        <br />
                        Parágrafo 1º. A CONTRATANTE deverá indicar e identificar todos os dados do cartão de crédito indicado na plataforma online da CONTRATADA em campo próprio para tal.
                        <br />
                        Parágrafo 2º. A CONTRATANTE responsabiliza-se pela veracidade e precisão das informações e de todos os dados passados a CONTRATADA.
                        <br />
                        Parágrafo 3º. A CONTRATANTE obriga-se a comunicar o CANCELAMENTO OU SUSPENSÃO do cartão de crédito indicado a CONTRATADA, em até 48h (quarenta e oito horas) do cancelamento ou suspensão.
                        <br />
                        Parágrafo 4º. Em caso de cancelamento e ou suspensão do cartão de crédito indicado obriga-se a CONTRATANTE a indicar novo cartão de crédito para substituir o cancelado, ficando a cargo da CONTRATADA a aceitação ou não do novo cartão indicado.
                        <br />
                        Parágrafo 5º. Em caso de cancelamento de cartão, a CONTRATANTE ficará responsável pelo adimplemento das parcelas restantes.
                        <br />
                        Parágrafo 6º. Em caso de o cartão indicado não pertencer a CONTRATANTE essa responsabiliza-se de igual forma pelos dados prestados.

                      </p>

                      <h3>DA RESCISÃO</h3>

                      <p>
                        Cláusula 7ª. O presente contrato é celebrado em caráter irrevogável e irretratável, obrigando herdeiros e sucessores a qualquer título fazer sempre boa e fiel o cumprimento da avença.
                        <br />
                        Cláusula 8ª. O Contrato finda-se após o pagamento de todas as parcelas acordadas na plataforma do contratado.

                      </p>

                      <h3>DA RESPONSABILIDADE PELAS INFORMAÇÕES E LGPD</h3>

                      <p>
                        Cláusula 9ª. A CONTRATANTE responsabiliza-se pela veracidade das informações bem como de quaisquer dados e documentos enviados a CONTRATADA.
                        <br />
                        Cláusula 10ª. A CONTRATANTE fornece seus dados pessoais e bancários de forma livre, consciente e voluntária através do preenchimento do formulário na plataforma da CONTRATADA, a CONTRATANTE também AUTORIZA EXPRESSAMENTE o tratamento de todos os dados enviados, bem como ANUI qualquer pesquisa ou consulta realizada pela CONTRATADA a quaisquer órgãos de proteção ao crédito.
                        <br />
                        Parágrafo 1º. A CONTRATADA responsabiliza-se pelos dados coletados e tratados e declara expressamente que somente utilizará os dados para a boa prestação do serviço contratado.
                        <br />
                        Cláusula 11ª. Com base nas Leis Aplicáveis à Proteção de Dados, as quais abrangem todas as leis, normas e regulamentos que regem o tratamento de dados pessoais, especificamente, o Regulamento Geral da Proteção de Dados da União Europeia 2016/679 (“GDPR”) e a Lei Geral de Proteção de Dados (Lei Federal n. 13.709/2018, “LGPD”), além das normas e dos regulamentos adotados pelas competentes autoridades de proteção de dados, a CONTRATADA compromete-se a manter sigilo dos dados coletados para cumprimento da suas funções relativas à pessoa física natural, os utilizando apenas para finalidade específica de:

                        coleta de informações necessárias para a análise de crédito junto aos órgãos de proteção de crédito, tribunais de justiça, órgãos administrativos, cartórios, junta comercial, Receita Federal, parceiros e demais instituições portadoras de informações relativas à situação econômica do titular dos dados;

                        uso dos dados coletados para recuperação de crédito nos casos de inadimplemento por parte do Emitente;

                        compartilhamento dos dados coletados com assessorias parceiras visando esclarecimentos de dúvidas, pareceres, cobrança, solicitações administrativas e ajuizamento de ações.

                        a coleta de dados apenas poderá ser realizada com o objetivo de cumprimento do presente contrato e jamais para qualquer outro propósito.

                        Os dados coletados serão eliminados quando: (I) a finalidade tiver sido alcançada ou os dados tiverem deixado de ser necessários; (II) se der o fim do período do tratamento; (III) houver comunicação do titular, “inclusive no exercício de seu direito de revogação do consentimento”; e (IV) a autoridade nacional assim determinar, quando houver violação ao disposto na LGPD.

                      </p>

                      <h3>DAS DISPOSIÇÕES GERAIS</h3>

                      <p>
                        Cláusula 12ª. Vincula-se a esse contrato as condições, prazos e honorários estipulados no formulário de contratação da plataforma online da CONTRATADA.
                        <br />
                        Cláusula 13ª. Declaram as partes que aceitam e reconhecem como válidas para o presente contrato a assinatura eletrônica nos termos da lei 14.063/2020. Sendo aqui utilizada a assinatura eletrônica simples ou a avançada, nos termos do Art. 4º da referida lei.

                      </p>

                      <h3>DO FORO</h3>

                      <p>Cláusula 14ª. As partes elegem o Foro da Comarca de Porto Alegre/RS, como o único competente para dirimir controvérsias oriundas deste instrumento, com renúncia qualquer outro.</p>

                      <h3>
                        DA CIÊNCIA E DO ACEITE
                      </h3>
                      <p>
                        Cláusula 15ª. A CONTRATANTE declara expressamente ter lido e entendido todo o teor do CONTRATO DE PRESTAÇÃO DE SERVIÇOS que reger-se-á pelas cláusulas acima descritas bem como pelos dispositivos legais do Código Civil Brasileiro, não obstante a CONTRATANTE registra estar de ACORDO com todas as cláusulas pactuadas bem como como as políticas de privacidade e termos de uso da plataforma online da CONTRATADA.
                        <br />
                        “E assim, por estarem justas e contratadas, livres de quaisquer vícios de entendimento ou consentimento, por livre e espontânea vontade, as partes assinam o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS, concordando com todas as cláusulas pactuadas e as obrigações decorrentes delas, de forma eletrônica nos termos da lei 14.063/2020
                      </p>
                    </TermsOfUseModalTexts>

                    <button
                      type="button"
                      onClick={() => setIsVisibleTerms(false)}
                    >
                      Voltar
                    </button>
                  </TermsOfUseModal>
                )}
              </TermsOfUse>
              <ErrorMessage message={formikProps.errors.acceptedTOS} />
            </TermsOfUserContent>

            <NavigationButtons>
              <Button
                message="Voltar"
                type="button"
                onClick={() => {
                  setOnboardingData({
                    ...onboardingData,
                    info: formikProps.values,
                  });
                  prev?.();
                }}
              />
              <Button
                message="Criar conta"
                type="submit"
                isLoading={isLoading}
              />
              {/* <button type="button" onClick={() => setIsVisibleEmailConfirmation(!isVisibleEmailConfirmation)}>clique para email</button> */}
            </NavigationButtons>
          </StepForm>
        )}
      </Formik>
    </Step>
  );
};

export default SignInInformations;
