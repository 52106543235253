import React, { ReactElement } from 'react';

import { Container, LoadingCircle, Circle } from './styles';

import { ButtonsProps } from './Button.spec';

export const Button = ({
  message = '',
  isLoading = false,
  icon = null,
  onClick,
  left = false,
  ...rest
}: ButtonsProps): ReactElement => (
  <Container onClick={onClick} {...rest}>
    {!isLoading ? (
      <>
        {left ? (
          <>
            {icon}
            {message}
          </>
        ) : (
          <>
            {message}
            {icon}
          </>
        )}
      </>
    ) : (
      <>
        Carregando...
        <LoadingCircle>
          <Circle />
        </LoadingCircle>
      </>
    )}

  </Container>
);

export default Button;
