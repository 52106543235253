const tableHeader = [
  {
    id: 1,
    name: 'Valor do boleto',
    width: '26%',
    align: 'left',
    ordened: false,
  },
  {
    id: 2,
    name: 'Parcelamento',
    width: '20%',
    align: 'left',
    ordened: false,
  },
  {
    id: 3,
    name: 'Status',
    width: '24%',
    align: 'left',
    ordened: false,
  },
  {
    id: 4,
    name: 'Vencimento',
    width: '24%',
    align: 'left',
    ordened: true,
  },
  {
    id: 5,
    name: 'Ações',
    width: '6%',
    align: 'center',
    ordened: false,
  },
];

const tableBody = [
  {
    id: 0,
    value: '5000',
    parc: '12x (R$ 500,00)',
    status: 'Analise pendente',
    venc: new Date(),
  },
  {
    id: 1,
    value: 'value',
    parc: 'valor',
    status: 'status',
    venc: new Date(),
  },
  {
    id: 2,
    value: 'ville',
    parc: 'valor',
    status: 'status',
    venc: new Date(),
  },
  {
    id: 3,
    value: 'ville',
    parc: 'valor',
    status: 'status',
    venc: new Date(),
  },
];

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const requestStatus = {
  rejected: 'Rejeitado',
  approved: 'Aprovado',
  pending: 'Aguardando aprovação',
  liquidated: 'Paga',
};

export {
  tableHeader,
  tableBody,
  requestStatus,
};
