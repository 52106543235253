import React, { ReactElement, useState } from 'react';
import { StepsProvider } from 'react-step-builder';
import SimulatorModal from '../../components/SimulatorModal';
import OnboardingModal from '../../components/OnboardingModal';

import {
  Container,
} from './styles';

const IFramePage = (): any => {
  const [isVisibleSimulator, setIsVisibleSimulator] = useState<boolean>(true);
  const [isVisibleOnBoarding, setIsVisibleOnBoarding] = useState<boolean>(false);

  return (
    <Container>
      {isVisibleSimulator && (
      <SimulatorModal
        handleClose={() => setIsVisibleSimulator(false)}
        nextPage={() => {
          setIsVisibleSimulator(false);
          setIsVisibleOnBoarding(true);
        }}
        viewMode="iFrame"
      />
      )}
      {isVisibleOnBoarding && (
        <StepsProvider>
          <OnboardingModal
            handleClose={() => setIsVisibleOnBoarding(false)}
            prevPage={() => {
              setIsVisibleSimulator(true);
              setIsVisibleOnBoarding(false);
            }}
            viewMode="iFrame"
          />
        </StepsProvider>
      )}
    </Container>
  );
};

export default IFramePage;
