import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import {
  RiArrowLeftLine,
  RiBillLine,
  RiSettings2Line,
  RiSettingsLine,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../pages/Dashboard';
import { formatDate, formatMoney } from '../../../utils/formatters';
import { SelectControl } from '../../../pages/Dashboard/Operator/Customer/styles';

import {
  Container,
  Top,
  Header,
  Content,
  TableBody,
  TableItem,
  IconBoleto,
  ItemContent,
  ValueBox,
} from './styles';
import { OrderRequestContext } from '..';
import { billPaymentService, ddaService } from '../../../services';
import { getErrorMessage } from '../../../utils/errorCodes';
import { StatusEnum } from '../../../services/dda';
import { DDAResponse } from '../../../types/dda';

const GridMyBoletos = (): ReactElement => {
  const { prev, setBillPaymentResponse, next } = useContext(OrderRequestContext);
  const {
    setIsOpenGridBoletos, setIsOpenRequest,
  } = useContext(DashboardContext);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.ALL);
  const [listDDAs, setListDDAs] = useState<DDAResponse[]>([]);

  // const [digitable, setDigitable] = useState('');

  async function fetchData(): Promise<void> {
    try {
      const { data, meta } = await ddaService.getList({ status });

      setListDDAs(data);
    } catch (err: any) {
      toast.warning(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação');
    }
  }

  useEffect(() => {
    fetchData();
  }, [status]);

  const submit = async (digitable: string): Promise<void> => {
    setIsLoading(true);
    try {
      const payload = { digitable };
      const {
        attributes: { response },
      } = await billPaymentService.findByBarcode(payload);
      setBillPaymentResponse(response);
      next();
    } catch (err: any) {
      toast.warning(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Erro na consulta do boleto. Por favor, verifique o código digitado e tente novamente.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <Top>
          <RiArrowLeftLine onClick={() => prev()} />
          <Link
            to="/portal/cliente/configuracoes"
            onClick={() => setIsOpenRequest(false)}
          >
            <RiSettings2Line />
          </Link>
        </Top>

        <Header>
          <h2>Boletos em meu nome</h2>

          <SelectControl>
            <small>Status</small>
            <select
              name="status"
              value={status}
              onChange={({ target: { value } }) => setStatus(value as unknown as StatusEnum)}
            >
              <option value={StatusEnum.ALL}>Todos</option>
              <option value={StatusEnum.EXPIRED}>Vencidos</option>
            </select>
          </SelectControl>
        </Header>

        <TableBody>
          {listDDAs.map((item) => (
            <TableItem key={item.id} onClick={() => submit(item.attributes.digitable)}>
              <IconBoleto
                status={
                  item.attributes.dueDate > new Date().toISOString()
                    ? ''
                    : 'overdue'
                }
              >
                <RiBillLine />
              </IconBoleto>
              <ItemContent>
                <h2>
                  {item.attributes.response.originalBeneficiary.fantasyName}
                  {' '}
                </h2>
                <p>
                  {`${
                    item.attributes?.dueDate > new Date().toISOString()
                      ? 'Vencimento'
                      : 'Vencido'
                  } em:`}
                  {' '}
                  {formatDate(item.attributes?.dueDate)}
                </p>
              </ItemContent>
              <ValueBox>
                {formatMoney(item.attributes.response?.originalValue)}
              </ValueBox>
            </TableItem>
          ))}
        </TableBody>
      </Content>
    </Container>
  );
};

export default GridMyBoletos;
