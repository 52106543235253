import React, { ReactElement, useState } from 'react';
import {
  RiLoginBoxLine, RiMenu3Line, RiCloseLine, RiSecurePaymentFill,
} from 'react-icons/ri';
import { Link } from 'react-router-dom';

import Logo from '../../assets/Logo.svg';

import Button from '../Button';

import {
  Container,
  Brand,
  NestedContainer,
  NavbarButtons,
} from './styles';
import { useAuth } from '../../contexts/AuthContext';
import NavbarMobile from '../NavbarMobile';

interface NavbarProps {
  handleToggleSimulator: () => void;
}

export const Navbar = ({ handleToggleSimulator }: NavbarProps): ReactElement => {
  const { authenticated, logout } = useAuth();

  return (
    <>
      <Container>
        <NestedContainer>
          <Brand>
            <img src={Logo} alt="BaruPay" />
          </Brand>
          <NavbarButtons>
            {authenticated ? (
              <Link to="/login">
                <Button message="Acessar conta" />
              </Link>
            ) : (
              <Link to="/login">
                <Button message="Fazer login" icon={<RiLoginBoxLine />} />
              </Link>
            )}
            <Button
              message="Simular agora!"
              onClick={() => {
                handleToggleSimulator();
              }}
            />
          </NavbarButtons>
        </NestedContainer>

      </Container>
      {' '}
      <NavbarMobile handleToggleSimulator={handleToggleSimulator} />

    </>

  );
};

export default Navbar;
