import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    > button {
      background-color: transparent;
      position: absolute;
      top: 60px;
      right: 19px;

      > svg {
        color: ${theme.colors.primary};

        font-size: 16px;
      }
    }
  `}
`;
