import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import { RiAlertFill } from 'react-icons/ri';
import { OnboardingContext } from '..';
import { OnboardingSteps } from '../../../types/onboarding';

import Button from '../../Button';
import ImageSelector from './ImageSelector';
import DocumentMask from '../../../assets/DocumentMask.png';

import {
  Step, StepForm, StepFormFields, WarningBox, NavigationButtons,
} from './styles';
import ErrorMessage from '../../ErrorMessage';
import DocumentScanModal from './DocumentScanModal';

export const DocumentsScan = (): ReactElement => {
  const {
    next, prev, onboardingData, setOnboardingData,
  } = useContext(OnboardingContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [documentScannerTarget, setDocumentScannerTarget] = useState<
    string | null
  >(null);

  const initialValues: OnboardingSteps['documentID'] = onboardingData.documentID || {
    backDocument: null,
    frontDocument: null,
  };

  const validationSchema = Yup.object().shape({
    backDocument: Yup.mixed().required(
      'O campo "Verso do seu documento" é obrigatório.',
    ),
    frontDocument: Yup.mixed().required(
      'O campo "Frente do seu documento" é obrigatório.',
    ),
  });

  const openScanner = (target: string): void => {
    setDocumentScannerTarget(target);
  };

  return (
    <Step id="scrollModal">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setIsLoading(true);
          setOnboardingData({
            ...onboardingData,
            documentID: values,
          });
          setTimeout(() => {
            next?.();
          }, 1500);
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <StepForm onSubmit={formikProps.handleSubmit}>
            <StepFormFields>
              <Field
                component={ImageSelector}
                name="frontDocument"
                title="Frente do seu documento:"
                subTitle="Foto da frente do seu documento"
                onOpenScanner={openScanner}
              />

              <ErrorMessage message={formikProps.errors.frontDocument} />

              <Field
                component={ImageSelector}
                name="backDocument"
                title="Verso do seu documento:"
                subTitle="Foto do verso do seu documento"
                onOpenScanner={openScanner}
              />

              <ErrorMessage message={formikProps.errors.backDocument} />
            </StepFormFields>

            <WarningBox>
              <RiAlertFill />
              <p>
                <b>Atenção:</b>
                {' '}
                Atente-se em uma boa iluminação e cuide para que
                seu documento de identificação com foto esteja visível.
              </p>
            </WarningBox>

            <NavigationButtons>
              <Button
                message="Voltar"
                type="button"
                onClick={() => {
                  setOnboardingData({
                    ...onboardingData,
                    documentID: formikProps.values,
                  });
                  prev?.();
                }}
              />
              <Button message="Continuar" type="submit" isLoading={isLoading} />
            </NavigationButtons>
            {!!documentScannerTarget && (
              <DocumentScanModal
                name={documentScannerTarget}
                onClose={() => setDocumentScannerTarget(null)}
                onCapture={(file) => {
                  formikProps.setFieldValue(documentScannerTarget, file);
                  setDocumentScannerTarget(null);
                }}
                mask={DocumentMask}
              />
            )}
          </StepForm>
        )}
      </Formik>
    </Step>
  );
};

export default DocumentsScan;
