import React, {
  ReactElement, useEffect, useState, useContext,
} from 'react';
import { toast } from 'react-toastify';
import { RequestContext } from '..';
import { DashboardContext } from '../../..';

import { Button } from '../../../../../components/Button';

import ColumnName from '../../../../../components/ColumnName';
import TablePagination from '../../../../../components/Pagination';
import TableEmpty from '../../../../../components/TableEmpty';
import {
  TableField,
  TableItem,
  TableHeader,
  TableBody,
  TableFooter,
  StatusTag,
} from '../../../../../components/TableItem';
import { useAuth } from '../../../../../contexts/AuthContext';
import { orderService } from '../../../../../services';
import { OrderResponseFull } from '../../../../../types/order';
import { getErrorMessage } from '../../../../../utils/errorCodes';
import {
  formatDateWithHour,
  formatMoney,
} from '../../../../../utils/formatters';

import { tableHeader, requestStatus } from './gridRequest.spec';

import { Table, ActionsButton } from './styles';

interface GridRequestProps {
  handleVisibleValidation: () => void;
  handleVisibleView: () => void;
  searchQuery: string;
  status: string;
}

const GridRequest = ({
  handleVisibleValidation,
  handleVisibleView,
  searchQuery,
  status,
}: GridRequestProps): ReactElement => {
  const { setId } = useContext(RequestContext);
  const { updateTable, updateTableSignal, setIsLoading } = useContext(DashboardContext);
  const { role } = useAuth();

  const [orders, setOrders] = useState<OrderResponseFull[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageCount, setPageCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [signal, setSignal] = useState({});

  async function fetchData(): Promise<void> {
    setIsLoading(true);
    try {
      const { data, meta } = await orderService.getList({
        page,
        pageSize,
        searchQuery,
        status,
      });
      setOrders(data);
      setPage(meta.pagination.page);
      setPageSize(meta.pagination.pageSize);
      setPageCount(meta.pagination.pageCount);
      setTotal(meta.pagination.total);
    } catch (err: any) {
      toast.error(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect((): void => {
    setPage(1);
    setSignal({});
  }, [searchQuery, status, updateTableSignal]);

  useEffect((): void => {
    fetchData();
  }, [page, pageSize, signal]);

  return (
    <Table>
      <TableHeader>
        {tableHeader.map((item) => (
          <ColumnName
            key={item.id}
            title={item.name}
            width={item.width}
            align={item.align}
            ordened={item.ordened}
          />
        ))}
      </TableHeader>
      <TableBody>
        {orders?.length ? (
          <>
            {orders?.map((item: any) => (
              <TableItem key={item.id}>
                <TableField width="20%" align="start">
                  {item.attributes?.customer?.data?.attributes?.user?.data?.attributes?.name}
                </TableField>
                <TableField width="20%" align="start">
                  {formatMoney(item.attributes?.paymentValue + item?.attributes?.serviceFee)}
                </TableField>
                <TableField width="15%" align="start">
                  {` ${formatMoney(
                    (item?.attributes?.paymentValue
                        + item?.attributes?.serviceFee)
                        / item?.attributes?.numberOfInstallments,
                  )} (${item?.attributes?.numberOfInstallments}x)`}
                </TableField>
                <TableField width="20%" align="start">
                  <StatusTag status={item?.attributes?.status}>
                    {
                      requestStatus[
                        item?.attributes?.status as keyof typeof requestStatus
                      ]
                    }
                  </StatusTag>
                </TableField>
                <TableField width="17%" align="start">
                  {formatDateWithHour(item.attributes?.createdAt)}
                </TableField>
                <TableField width="8%" align="center">
                  <ActionsButton>
                    {item?.attributes?.status === 'pending'
                    || (role === 'manager'
                      && item?.attributes?.status !== 'liquidated') ? (
                        <Button
                          type="button"
                          message="Analisar"
                          className="analyze"
                          onClick={() => {
                            handleVisibleValidation();
                            setId(item.id);
                          }}
                        />
                      ) : (
                        <Button
                          type="button"
                          message="Visualizar"
                          className="view"
                          onClick={() => {
                            handleVisibleView();
                            setId(item.id);
                          }}
                        />
                      )}
                  </ActionsButton>
                </TableField>
              </TableItem>
            ))}
          </>
        ) : (
          <TableEmpty
            operator
            title="solicitação"
            text="Quando houver uma nova solicitação, será exibida aqui."
          />
        )}
      </TableBody>
      <TableFooter>
        <TablePagination
          pagination={{
            page,
            pageSize,
            pageCount,
            total,
          }}
          onChange={({ page: newPage, pageSize: newPageSize }) => {
            setPage(newPage);
            setPageSize(newPageSize);
          }}
        />
      </TableFooter>
    </Table>
  );
};

export default GridRequest;
