import _ from 'lodash';
import { CountResponse, StrapiAttributes, StrapiResponseFindMany } from '../types/core';
import { DDAResponse } from '../types/dda';
import api from './api';

interface PaginationProps {
  page?: number;
  pageSize?: number;
  status?: StatusEnum;
  sort?: string;
}

// eslint-disable-next-line no-shadow
export enum StatusEnum {
  ALL = 'ALL',
  EXPIRED = 'EXPIRED',
}

const StatusFunction = {
  [StatusEnum.ALL]: () => undefined,
  [StatusEnum.EXPIRED]: () => ({
    'filters[dueDate][$lt]': new Date().toISOString(),
  }),
};

const defaultParams = {
  page: 0,
  pageSize: 99,
  sort: '',
  status: StatusEnum.ALL,
};

export default {
  async getList({
    page = defaultParams.page,
    pageSize = defaultParams.pageSize,
    sort = defaultParams.sort,
    status = defaultParams.status,
  }: PaginationProps = defaultParams): Promise<
    StrapiResponseFindMany<DDAResponse>
  > {
    return (
      await api.get('/dda-bill-payments/own', {
        params: _.omitBy(
          {
            'pagination[page]': page,
            'pagination[pageSize]': pageSize,
            ...StatusFunction[status]?.(),
          },
          (v) => !v,
        ),
      })
    )?.data;
  },

  async countDDA(): Promise<CountResponse> {
    return (await api.get('/dda-bill-payments/own/count'))?.data;
  },
};
