import styled, { css } from 'styled-components';

export const Container = styled.section`
`;

export const Content = styled.div`
  display: flex;
  margin-bottom: 58px;
`;

export const ContentTitle = styled.p`
  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;

    margin-bottom: 24px;

    color: ${theme.colors.primary};
  `}
`;

export const ContentLeft = styled.div`
  width: 100%;
  max-width: 350px;
`;

export const Datas = styled.div``;

export const ItemDatas = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 1.6rem;

    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      width: 100%;
      max-width: 130px;
      margin-right: 24px;
      color: ${theme.colors.primaryBlack00};
    }

    > p {
      width: 100%;
      font-weight: 500;

      color: ${theme.colors.primaryBlack40};
    }
  `}
`;

export const Divider = styled.div`
  margin: 40px 44px 0;
  ${({ theme }) => css`
    border-left: 1px dashed ${theme.colors.primaryBlack20};
  `}
`;

export const ContentRight = styled.div`
  width: 100%;
  max-width: 864px;
`;

export const BoxImages = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const Box = styled.div`
  width: 100%;
  max-width: 272px;

  ${({ theme }) => css`
    > p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 600;

      margin-bottom: 24px;

      color: ${theme.colors.primaryBlack00};
    }
  `}

  button {
    min-width: unset;
  }
`;
export const Image = styled.div`
  ${({ theme }) => css`
  position: relative;
  border-radius: 4px;
  background-color: black;
  height: 156px;


    > img {
      border-radius: 4px;
      width: 100%;
      /* max-width: 156px; */
      height: 156px;

      object-fit: contain;
      object-position: center;
      /* filter: blur(0.7px); */
     
    }

    > button {
      position: absolute;
      bottom: 24px;
      right: 24px;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      height: 38px;
      /* padding: 10px 16px; */

      font-size: 1.2rem;

      > svg {
        font-size: 1.4rem;
      }
    }
  `}
`;

export const Status = styled.section`
  display: flex;
  justify-content: space-between;

  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
`;

export const LastStatus = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlack50};
  `}
`;

export const LastUpdate = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlack00};
  `}
`;
