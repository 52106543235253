import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const RequireRole: React.FC<React.PropsWithChildren & { roleName: string | string[] | null, redirect?: string, state?: any }> = ({
  children,
  roleName,
  redirect,
  state,
}) => {
  const { role } = useAuth();
  const alternative = redirect ? <Navigate replace to={redirect} state={state} /> : <></>;
  if (roleName instanceof Array) {
    return role && roleName.includes(role) ? <>{children}</> : alternative;
  }
  return roleName === role ? <>{children}</> : alternative;
};

RequireRole.defaultProps = {
  redirect: undefined,
};

export default RequireRole;
