import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { RiAlertFill, RiInformationLine } from 'react-icons/ri';

import { toast } from 'react-toastify';
import Button from '../../Button';

import SecuredPayment from '../../../assets/SecuredPayment.svg';

import {
  Step,
  TaxInformationsBox,
  RowItems,
  RowItem,
  PaymentInformations,
  WarningBox,
  TaxModal,
  NavigationButtons,
} from './styles';

import { InputControl } from '../../../styles/components';
import { OrderRequestContext } from '..';
import { formatDate, formatDigitable, formatMoney } from '../../../utils/formatters';
import { Simulator } from '../../../types/simulator';
import { billPaymentService } from '../../../services';
import installmentOptions from '../../SimulatorModal/utils/installmentOptions';
import { getErrorMessage } from '../../../utils/errorCodes';
import { BarcodeResponse } from '../../../types/billPayment';

export const TaxInformations = (): ReactElement => {
  const {
    next,
    prev,
    jump,
    barcodeResponse: {
      digitable,
      settleDate,
      registerData,
      value: barcodeValue,
      assignor,
      dueDate,
    },
    paymentValue,
    setPaymentValue,
    totalValue,
    setTotalValue,
    numberOfInstallments,
    setNumberOfInstallments,
    installmentValue,
    setInstallmentValue,
    description,
    setDescription,
    serviceFee,
    setServiceFee,
  } = useContext(OrderRequestContext);

  const { totalUpdated: boletoValue = barcodeValue || 0 } = registerData || {};
  const [isVisibleTaxModal, setIsVisibleTaxModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (): void => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      next?.();
    }, 1500);
  };

  useEffect(() => {
    async function fetchInstallments(): Promise<void> {
      try {
        setInstallmentValue(0);
        setServiceFee(0);
        const payload: Simulator = {
          paymentValue: boletoValue,
          numberOfInstallments,
        };
        const response = await billPaymentService.simulate(payload);
        if (boletoValue === response.paymentValue) {
          setPaymentValue(response.paymentValue);
          setTotalValue(response.totalValue);
          setNumberOfInstallments(response.numberOfInstallments);
          setInstallmentValue(response.installmentValue);
          setServiceFee(response.serviceFee);
        }
      } catch (err: any) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível consultar o valor da parcela.');
      }
    }
    fetchInstallments();
  }, [numberOfInstallments, registerData?.totalUpdated]);

  return (
    <Step noScroll={isVisibleTaxModal}>
      <TaxInformationsBox>
        <h3>Informações do boleto:</h3>
        <RowItems>
          <RowItem>
            Nº do documento
            <small>{0}</small>
          </RowItem>
          {dueDate && (
            <RowItem>
              Data de vencimento:
              <small>
                {formatDate(dueDate)}
              </small>
            </RowItem>
          )}
          <RowItem>
            Beneficiário:
            <small>{registerData?.recipient || assignor}</small>
          </RowItem>
          <RowItem>
            Juros:
            <small>{formatMoney(registerData?.interestValueCalculated)}</small>
          </RowItem>
          <RowItem>
            Multas:
            <small>{formatMoney(registerData?.fineValueCalculated)}</small>
          </RowItem>
          <RowItem>
            Descontos
            <small>{formatMoney(registerData?.discountValue)}</small>
          </RowItem>
          <RowItem>
            Valor total da conta/boleto:
            <small>{formatMoney(boletoValue)}</small>
          </RowItem>
          <RowItem>
            Código de barras:
            <small>{formatDigitable(digitable)}</small>
          </RowItem>
        </RowItems>
      </TaxInformationsBox>
      <PaymentInformations>
        <h3>Pagamento:</h3>
        <RowItems>
          <RowItem>
            Parcelamento:
            <strong>
              <small>
                <RiInformationLine
                  onClick={() => {
                    setIsVisibleTaxModal(true);
                  }}
                />
              </small>
              <b>
                {' '}
                {numberOfInstallments}
                x de
                {' '}
                {formatMoney(installmentValue)}
              </b>
            </strong>
          </RowItem>
          <RowItem>
            Valor final:
            <span>{formatMoney(totalValue)}</span>
          </RowItem>
        </RowItems>
      </PaymentInformations>

      <InputControl>
        <small>Número de parcelas:</small>

        <select
          name="numberOfInstallments"
          value={numberOfInstallments}
          onChange={({ target: { value } }) => setNumberOfInstallments(+value)}
        >
          <option value="">Selecione</option>
          {installmentOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </InputControl>

      <InputControl>
        <small>Descrição (opcional):</small>
        <input
          type="text"
          placeholder="Ex.: Pagamento de financiamento"
          name="description"
          value={description}
          onChange={({ target: { value } }) => setDescription(value)}
        />
      </InputControl>

      <WarningBox>
        <RiAlertFill />
        <p>
          <b>Atenção:</b>
          {' '}
          Confira a fonte emissora da conta/boleto. Caso não a
          reconheça, não continue o pagamento.
        </p>
      </WarningBox>
      <NavigationButtons>
        <Button message="Voltar" type="button" onClick={() => jump(1)} />
        <Button message="Confirmar dados" type="button" onClick={onSubmit} isLoading={isLoading} />
      </NavigationButtons>

      {isVisibleTaxModal && (
        <TaxModal>
          <h3>Entenda a nossa tarifa</h3>
          <p>
            Para que possamos fornecer um serviço financeiro que caiba no seu
            bolso, temos algumas diretrizes financeiras que são agregadas ao
            valor final da sua transação, conforme consta abaixo:
          </p>
          <img src={SecuredPayment} alt="SecuredPayment" />
          <p>
            Para o valor do seu boleto, acrescido das parcelas selecionadas, sua
            tarifa será de
            {' '}
            <b>{formatMoney(serviceFee)}</b>
            .
          </p>

          {/* <WarningBox>
            <RiAlertFill />
            <p>
              <b>Atenção:</b>
              {' '}
              O valor da tarifa acrescido é um valor único,
              independente da quantidade de parcelas selecionadas.
            </p>
          </WarningBox> */}

          <button type="button" onClick={() => setIsVisibleTaxModal(false)}>
            Voltar
          </button>
        </TaxModal>
      )}
    </Step>
  );
};

export default TaxInformations;
