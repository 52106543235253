import styled, { css, keyframes } from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

// Animation to sidebar when open/closes
const opacityAnimation = keyframes`
  0% { 
    display: none;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
`;
interface SidebarProps {
  toggled?: boolean;
}

export const Container = styled.div<SidebarProps>`
  ${({ theme, toggled }) => css`
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    max-width: 93px;
    width: 100%;
    background-color: ${theme.colors.white};
    flex: 1;
    padding: 32px;
    z-index: 1000;
    box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.05);

    display: flex;
    flex-direction: column;
    align-items: center;

    transition: ${theme.transitions.transform};

    ${DropdownItem}, ${Item}, ${ItemUrl} {
      > span {
        display: none;
        white-space: nowrap;

        animation: ${opacityAnimation} 1s ease-in-out;
      }


      > svg {
        margin-right: 0;
        font-size: 1.6rem;
      }
    }

    // Toggle animation when Sidebar is opened
    ${toggled
    && css`
      max-width: 249px;
      align-items: unset;

      ${DropdownItem}, ${Item}, ${ItemUrl} {
        > span {
          display: flex;
          opacity: 1;
        }

        > svg {
          margin-right: 14px;
        }
      }

      ${ToggleButton} {
        > svg {
          transform: rotate(-180deg);
        }
      }
    `};

    @media (max-width: 480px) {
      display: none;
    }
  `};
`;

export const Brand = styled.img`
  width: 36px;
  margin: 0 0 54px 0;
`;

export const MenuItems = styled.ul`
  flex: 1;

  display: flex;
  flex-direction: column;
`;

interface ItemProps {
  active?: boolean;
}

export const Item = styled(Link)`
  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: ${theme.colors.black};
    font-weight: 600;
    opacity: 0.3;
    margin-bottom: 30px;
    cursor: pointer;

    display: flex;
    align-items: center;

    transition: ${theme.transitions.default};

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }

    &:last-child {
      margin: 0;
    }
  `};
`;

export const ItemUrl = styled.a`
  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: ${theme.colors.black};
    font-weight: 600;
    opacity: 0.3;
    margin-bottom: 30px;
    cursor: pointer;

    display: flex;
    align-items: center;

    transition: ${theme.transitions.default};

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }

    &:last-child {
      margin: 0;
    }
  `};
`;

export const ToggleButton = styled.button`
  ${({ theme }) => css`
    width: 27px;
    height: 27px;
    border-radius: 3px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 53px;
    right: -17px;

    > svg {
      transition: ${theme.transitions.default};
    }
  `};
`;

export const DropdownItem = styled.div`
  ${({ theme }) => css`
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: ${theme.colors.black};
    font-weight: 600;
    opacity: 0.3;
    margin-bottom: 30px;
    cursor: pointer;

    display: flex;
    align-items: center;

    transition: ${theme.transitions.default};

    &:hover {
      opacity: 1;
    }
  `};
`;

export const ToggleAction = styled.span`
  margin-left: auto;
`;

export const BottomItems = styled.div`
  margin-top: auto;

`;
