import styled, { css } from 'styled-components';

interface ColumnNameProps {
  width?: string,
  align?:string,
}

export const Container = styled.li<ColumnNameProps>`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;

  width: 100%;
  max-width: ${(props) => props.width};
  text-align: ${(props) => props.align};

  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};


  > svg {
    font-size: 1.6rem;
    margin-left: 6px;
  }
`;
