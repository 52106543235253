import { Field, Form, Formik } from 'formik';
import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import {
  Link, Navigate, useNavigate, useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import BaruLogo from '../../assets/BaruLogo.svg';
import FooterLogo from '../../assets/FooterLogo.svg';

import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import PasswordInput from '../../components/PasswordInput';
import { authService } from '../../services';
import { InputControl } from '../../styles/components';
import { ResetPasswordCore } from '../../types/auth';
import { getErrorMessage } from '../../utils/errorCodes';

import {
  Container,
  BackgroundImage,
  MainContent,
  Header,
  SignInForm,
  Footer,
} from './styles';

const NewPassword: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  if (!code) {
    return <Navigate to="/" replace />;
  }

  const initialValues: Omit<ResetPasswordCore, 'code'> = {
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'A senha deve conter ao menos 8 caracteres.')
      .required('O campo "senha" é obrigatório.'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'As senhas digitadas devem coincidir.',
    ),
  });

  async function handleReset({
    password, passwordConfirmation,
  }: Omit<ResetPasswordCore, 'code'>): Promise<void> {
    if (code && password && passwordConfirmation && password === passwordConfirmation) {
      try {
        await authService.resetPassword({
          code, password, passwordConfirmation,
        });
        toast.success('Senha alterada com sucesso!');
        navigate('/login');
      } catch (err: any) {
        toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação!');
      }
    }
  }
  return (
    <Container>
      <BackgroundImage>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(state) => {
            handleReset(state);
          }}
        >
          {({ errors }) => (
            <MainContent>
              <Header>
                <img src={BaruLogo} alt="" />
                <span>Login</span>
                <Link to="/">
                  Voltar ao site
                  {' '}
                  <FiArrowUpRight />
                </Link>
              </Header>
              <h1>
                Crie uma nova senha
              </h1>

              <Form>
                <SignInForm>

                  <Field
                    component={PasswordInput}
                    name="password"
                    label="Sua nova senha"
                    className={errors.password ? 'error' : ''}
                  />
                  <ErrorMessage message={errors.password} />

                  <Field
                    component={PasswordInput}
                    name="passwordConfirmation"
                    label="Repita sua nova senha"
                    className={errors.passwordConfirmation ? 'error' : ''}

                  />
                  <ErrorMessage message={errors.passwordConfirmation} />

                  <Button
                    type="submit"
                    message="Salvar"
                  />
                </SignInForm>
              </Form>

              <Link to="/login">
                <span>Voltar ao login</span>
              </Link>
              <Footer><img src={FooterLogo} alt="" /></Footer>
            </MainContent>
          )}
        </Formik>
      </BackgroundImage>
    </Container>
  );
};

export default NewPassword;
