import React, {
  ReactElement, useCallback, useContext, useEffect, useState,
} from 'react';
import _ from 'lodash';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../Button';
import { InputControl } from '../../../styles/components';

import {
  Step, StepForm, InputControlRow, NavigationButtons,
} from './styles';
import { cepMask } from '../../../utils/masks';
import InputMask from '../../MaskedInput';
import { UFList } from '../../../types/address';
import { addressService } from '../../../services';
import { OnboardingSteps } from '../../../types/onboarding';
import { OnboardingContext } from '..';
import ErrorMessage from '../../ErrorMessage';

export const AddressData = (): ReactElement => {
  const {
    next, prev, onboardingData, setOnboardingData,
  } = useContext(OnboardingContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    postalCode: Yup.string().matches(/\d{8}/, 'Formato inválido').required('Campo "CEP" é obrigatório'),
    street: Yup.string().required('Campo "Rua" é obrigatório'),
    number: Yup.string().required('Campo "Número" é obrigatório'),
    complement: Yup.string().optional(),
    neighborhood: Yup.string().required('Campo "Bairro" é obrigatório'),
    city: Yup.string().required('Campo "Cidade" é obrigatório'),
    state: Yup.string().required('Campo "UF" é obrigatório'),
  });

  const formik = useFormik<OnboardingSteps['address']>({
    initialValues: onboardingData.address || {
      postalCode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
    },
    onSubmit(values) {
      setIsLoading(true);
      setOnboardingData({
        ...onboardingData,
        address: values,
      });
      setTimeout(() => {
        next?.();
      }, 1500);
    },
    validationSchema,
  });

  const fetchCepV2 = useCallback(
    _.debounce(async (newPostalCode: string) => {
      setIsLoading(true);
      if (newPostalCode?.length === 8) {
        try {
          const {
            cep, state, city, neighborhood, street,
          } = await addressService.fetchCepV2(newPostalCode);
          formik.setFieldValue('postalCode', cep);
          formik.setFieldValue('state', state);
          formik.setFieldValue('city', city);
          formik.setFieldValue('neighborhood', neighborhood);
          formik.setFieldValue('street', street);
        } catch (error) {
          toast.warning('CEP inválido');
        }
      }
      setIsLoading(false);
    }, 1000),
    [],
  );

  const { postalCode } = formik.values;

  useEffect(() => {
    fetchCepV2(postalCode);
    return () => { fetchCepV2(''); };
  }, [postalCode, fetchCepV2]);

  const handleNumberChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => {
    formik.setFieldValue(
      name,
      value?.replace(/\D/g, ''),
    );
  };

  return (
    <Step>
      <StepForm onSubmit={formik.handleSubmit}>
        <InputControl>
          <small>CEP:</small>
          <InputMask
            name="postalCode"
            value={postalCode}
            onChange={handleNumberChange}
            placeholder="00000-000"
            mask={cepMask}
            guide={false}
            autoComplete="postal-code"
          />
          <ErrorMessage message={formik.errors.postalCode} />
        </InputControl>

        <InputControl>
          <small>Endereço:</small>
          <input
            name="street"
            value={formik.values.street}
            onChange={formik.handleChange}
            type="text"
            placeholder="Rua Brasil"
            autoComplete="address-line1"
            disabled={isLoading}
          />

        </InputControl>

        <InputControlRow>
          <InputControl>
            <small>Número:</small>
            <input
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              type="text"
              placeholder="10"
              disabled={isLoading}
            />

            <ErrorMessage message={formik.errors.number} />

          </InputControl>

          <InputControl>
            <small>Complemento:</small>
            <input
              name="complement"
              value={formik.values.complement}
              onChange={formik.handleChange}
              type="text"
              placeholder="Apto, bloco"
              autoComplete="address-line2"
              disabled={isLoading}
            />
            <ErrorMessage message={formik.errors.complement} />

          </InputControl>

          <InputControl>
            <small>Bairro:</small>
            <input
              name="neighborhood"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              type="text"
              placeholder="São João"
              autoComplete="address-level3"
              disabled={isLoading}
            />
            <ErrorMessage message={formik.errors.neighborhood} />

          </InputControl>
        </InputControlRow>

        <InputControlRow>
          <InputControl>
            <small>Cidade:</small>
            <input
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              type="text"
              placeholder="Selecione"
              autoComplete="address-level2"
              disabled
            />
          </InputControl>

          <InputControl>
            <small>Estado:</small>
            <select
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              autoComplete="address-level1"
              disabled
            >
              <option value="">Selecione</option>
              {UFList.map((value: string) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </InputControl>
        </InputControlRow>

        <NavigationButtons>
          <Button
            message="Voltar"
            type="button"
            onClick={() => {
              setOnboardingData({
                ...onboardingData,
                address: formik.values,
              });
              prev?.();
            }}
          />
          <Button message="Continuar" type="submit" isLoading={isLoading} />
        </NavigationButtons>

      </StepForm>
    </Step>
  );
};

export default AddressData;
