import React, { ReactElement, useContext, useState } from 'react';
import { RiAlertFill, RiCheckLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import { DashboardContext } from '../../pages/Dashboard';
import { RequestContext } from '../../pages/Dashboard/Operator/Request';
import { orderService } from '../../services';
import { OrderResponseFull } from '../../types/order';
import { getErrorMessage } from '../../utils/errorCodes';
import { formatMoney } from '../../utils/formatters';
import { Button } from '../Button';

import {
  Container,
  LiquidationModalContent,
  LiquidationModalCustomerInfos,
  LiquidationModalCustomerRow,
  WarningBox,
} from './styles';

interface LiquidationModalProps {
  handleClose: () => void;
  order?: OrderResponseFull;
}

export const LiquidationModal = ({
  handleClose,
  order,
}: LiquidationModalProps): ReactElement => {
  const { setIsVisibleValidationModal } = useContext(RequestContext);
  const { updateTable } = useContext(DashboardContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { role } = useAuth();

  const id = order ? order?.id : 0;
  const name = order?.attributes?.customer?.data?.attributes?.user?.data?.attributes?.name;
  const finalValue = order
    ? order?.attributes?.paymentValue + order?.attributes?.serviceFee
    : 0;
  const installmentValue = order
    ? finalValue / order?.attributes?.numberOfInstallments
    : 0;
  const numberOfInstallments = order
    ? order?.attributes?.numberOfInstallments
    : 0;

  const submit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (role === 'manager') {
        await orderService.liquidate(id);
        toast.success('Solicitação liquidada com sucesso!');
      } else {
        await orderService.approve(id);
        toast.success('Solicitação aprovada com sucesso!');
      }
      setIsVisibleValidationModal(false);
      handleClose();
      updateTable();
    } catch (err: any) {
      toast.warning(
        getErrorMessage(err.response?.data?.error?.details?.serverCode)
          || 'Não foi possível realizar operação',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <LiquidationModalContent>
        <RiAlertFill />
        <h4>
          {`Confirmar a ${
            role === 'manager' ? 'liquidação' : 'aprovação'
          } da solicitação?`}

        </h4>
        <LiquidationModalCustomerInfos>
          <LiquidationModalCustomerRow>
            <small>Cliente</small>
            <h5>{name}</h5>
          </LiquidationModalCustomerRow>
          <LiquidationModalCustomerRow>
            <small>Valor da solicitação:</small>
            <h6>
              {formatMoney(finalValue)}
              {' '}
              <span>
                (
                {formatMoney(installmentValue)}
                {' '}
                em
                {' '}
                {numberOfInstallments}
                x)
              </span>
            </h6>
          </LiquidationModalCustomerRow>
        </LiquidationModalCustomerInfos>
        {role !== 'manager' && (
          <WarningBox>
            <RiAlertFill />
            {' '}
            <p>
              <b>ATENÇÃO:</b>
              {' '}
              Lembre-se que após esta etapa de aprovação, há 30 minutos
              para que a solicitação seja liquidada, do contrário esta ação de
              aprovação terá que ser feita novamente.
            </p>
          </WarningBox>
        )}
        <Button
          message={`Confirmar ${
            role === 'manager' ? 'liquidação' : 'aprovação'
          } `}
          onClick={() => submit()}
          icon={<RiCheckLine />}
          isLoading={isLoading}
        />
        <Button message="Fechar" onClick={() => handleClose()} />
      </LiquidationModalContent>
    </Container>
  );
};

LiquidationModal.defaultProps = {
  order: {},
};

export default LiquidationModal;
