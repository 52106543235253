import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const InputControl = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    @media (max-width: 480px) {
      &:first-child {
        display: none;
      }
    }

    &:first-child {
        margin-right: 16px;
      }

    > svg {
      position: absolute;
      left: 19.5px;
      top: 22.5px;

      font-size: 15px;
    }

    > input {
      width: 100%;
      height: 60px;
      padding-left: 48px;

      font-size: 1.4rem;
      line-height: 2.4rem;

      border: 1px solid ${theme.colors.primaryBlack10};
      border-radius: 6px;

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.primaryBlack30};
        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: ${theme.colors.black};
      }
    }
  `}
`;
