import styled, { css } from 'styled-components';

export const Step = styled.div<{ noScroll?: boolean }>`
  ${({ theme, noScroll }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    /* position: relative; */
    overflow: auto;

    &::-webkit-scrollbar {
      visibility: hidden;
    }

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    ${noScroll
    && css`
      overflow: hidden;
    `};
  `};
`;

export const TaxInformationsBox = styled.div`
  ${({ theme }) => css`
    padding: 0 0 24px 0;
    border-bottom: 1px dashed ${theme.colors.primaryBlack20};

    > h3 {
      ${theme.texts.titleHeadingXXS};
      color: ${theme.colors.primaryBlack00};
    }
  `};
`;

export const RowItems = styled.ul`
  margin: 18px 0 0 0;
`;

export const RowItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    letter-spacing: -0.1px;
    ${theme.texts.buttonTextXS};
    color: ${theme.colors.primary};

    > span {
      ${theme.texts.buttonTextXS};
      color: ${theme.colors.primaryBlack50};
    }

    > small {
      display: flex;
      align-items: center;
      text-align: right;

      color: ${theme.colors.primaryBlack00};
      ${theme.texts.buttonTextSM};

      > svg {
        margin-right: 4px;
        color: ${theme.colors.primary};
        font-size: 1.8rem;
        cursor: pointer;
      }
    }

    > strong {
      color: ${theme.colors.primaryBlack00};
      display: flex;

      > small {
        display: flex;
        align-items: center;
        color: ${theme.colors.primaryBlack00};
        ${theme.texts.buttonTextSM};

        > svg {
          margin-right: 4px;
          color: ${theme.colors.primary};
          font-size: 1.8rem;
          cursor: pointer;
        }
      }

      > b {
        ${theme.texts.buttonTextMD};
      }
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  `};
`;

export const PaymentInformations = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;

    > h3 {
      ${theme.texts.titleHeadingXXS};
      color: ${theme.colors.primaryBlack00};
    }
  `};
`;

export const WarningBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.warning};
    border-radius: 6px;
    padding: 16px 70px 16px 16px;
    margin: 24px 0;

    display: flex;
    align-items: center;

    @media (max-width: 480px) {
      padding: 16px;
    }

    > p {
      ${theme.texts.buttonTextXS};
      font-weight: 400;
      line-height: 1.6rem;

      > b {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    > svg {
      color: ${theme.colors.yellow};
      margin-right: 10px;
      font-size: 2rem;

      @media (max-width: 480px) {
        width: 100%;
        max-width: 20px;
      }
    }
  `};
`;

export const TaxModal = styled.div`
  ${({ theme }) => css`
    min-height: -webkit-fill-available;
    width: 100%;
    max-width: 724px;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    padding: 36px;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    z-index: 1000;

    > h3 {
      ${theme.texts.buttonTextMD};
      letter-spacing: -0.2px;
      color: ${theme.colors.primaryBlack00};
      margin: 0 0 24px 0;
    }

    > p {
      ${theme.texts.bodyParagraphMD};
      line-height: 2.4rem;
    }

    > button {
      background-color: transparent;
      color: ${theme.colors.primary};
      ${theme.texts.buttonTextSM};
      margin: 0 auto;
      height: 60px;
      margin-top: auto;

      display: flex;
      align-items: center;
    }

    > img {
      margin: 24px auto;
    }
  `};
`;

export const TaxTable = styled.div`
  display: flex;
  margin: 24px 0 0 0;
`;

export const TaxTableRow = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primaryBlack10};
    border-radius: 6px 0 0 6px;
    flex: 1;

    &:last-child {
      border-left: 0;
      border-radius: 0 6px 6px 0;
    }
  `};
`;

export const TaxTableItem = styled.div`
  ${({ theme }) => css`
    min-height: 32px;
    ${theme.texts.buttonTextXS};
    color: ${theme.colors.primaryBlack00};

    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.primaryBlack10};
    }
  `};
`;

export const TaxTableHeader = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.primaryBlack10};
    min-height: 44px;
    ${theme.texts.buttonTextXS};
    color: ${theme.colors.primary};

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const NavigationButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    background-color: ${theme.colors.white};
    /* position: sticky;
    bottom: 0; */
    padding: 12px 0 0 0;

    > button {
      margin-top: auto;

      :first-child {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      :last-child {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        flex: 1;
        margin: 0 0 0 10px;
      }
    }
  `};
`;
