import { Entity, StrapiAttributes, StrapiEntity } from './core';

export const UFList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
] as const;

type UFValue = typeof UFList[number];

export const UF = UFList.reduce(
  (acc, cur) => ({ ...acc, [cur]: cur }),
  {} as {
    [state in UFValue]: state;
  },
);

export interface AddressCore {
  postalCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: UFValue;
}

export type Address = AddressCore & Entity

export type AddressResponse = StrapiEntity<StrapiAttributes<AddressCore>>

export interface CepV2 {
  cep: string;
  state: UFValue;
  city: string;
  neighborhood: string;
  street: string;
  service: string;
  location: {
    type: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
  };
}
