import React, { ReactElement } from 'react';
import { RiAlertFill } from 'react-icons/ri';

import {
  Container, Content, Tag, Switch, WarningBox,
} from './styles';

const Settings = ():ReactElement => {
  const text = '';

  return (
    <Container>
      <Content>
        <Tag>
          Novidade !
        </Tag>
        <h2>Boletos em seu nome (DDA):</h2>

        <p>
          Encontre boletos bancários já digitalizados vinculados ao seu CPF de forma fácil e parcele em até 16x.
        </p>

        <span>Função ativada</span>
        {/* <Switch>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider" />
          </label>
        </Switch> */}
        <WarningBox>
          {' '}
          <RiAlertFill />
          {' '}
          <p>
            <b>ATENÇÃO:</b>
            {' '}
            Para desativar essa função, entre em contato conosco
            {' '}
            <a href="mailto:suporte@barupay.com.br?subject=[Ajuda] Desativar DDA">via e-mail</a>
            .
          </p>
          {' '}

        </WarningBox>
      </Content>

    </Container>
  );
};

export default Settings;
