import _ from 'lodash';
import {
  StrapiResponse, StrapiResponseFindMany,
} from '../types/core';
import { OrderRequest, OrderResponse, OrderResponseFull } from '../types/order';
import api from './api';

interface PaginationProps {
  page: number,
  pageSize: number,
  sort?: string,
  searchQuery?: string,
  status?: string,
}

export default {
  async create(orderRequest: OrderRequest): Promise<OrderResponse> {
    return (await api.post<OrderResponse>('/orders/new', orderRequest))?.data;
  },

  async getList({
    page, pageSize, sort, searchQuery, status,
  }: PaginationProps): Promise<StrapiResponseFindMany<OrderResponseFull>> {
    return (await api.get('/orders', {
      params: _.omitBy({
        'populate[customer][populate]': 'user',
        'pagination[page]': page,
        'pagination[pageSize]': pageSize,
        'filters[status][$eq]': status,
        'filters[$or][0][customer][user][name][$containsi]': searchQuery,
        'filters[$or][1][customer][user][cpf][$containsi]': searchQuery,
        'filters[$or][2][customer][user][phone][$containsi]': searchQuery,
        'filters[$or][3][description][$containsi]': searchQuery,
        sort: 'createdAt:desc',
      }, (v) => !v),
    }))?.data;
  },

  async getOne(id: number): Promise<StrapiResponse<OrderResponseFull, any>> {
    return (await api.get(`/orders/${id}`, {
      params: {
        'populate[customer][populate]': 'user,address,customerReview',
        'populate[orderBillPayment][populate]': '',
        'populate[orderCreditCard][populate]': '',
        'populate[orderReview][populate]': 'operator',
      },
    }))?.data;
  },

  async approve(id: number): Promise<void> {
    await api.post(`/orders/${id}/approve`);
  },

  async liquidate(id: number): Promise<void> {
    await api.post(`/orders/${id}/liquidate`);
  },

  async reject(id: number, description: string): Promise<void> {
    await api.post(`/orders/${id}/reject`, {
      description,
    });
  },

  async rejectManager(id: number, description: string): Promise<void> {
    await api.post(`/orders/${id}/rejectApproved`, {
      description,
    });
  },

  async verifyHour(): Promise<void> {
    return (await api.get('/business-hours/online'))?.data;
  },

  async resendReceipt(id: number): Promise<void> {
    return (await api.post(`/orders/${id}/resendEmail`))?.data;
  },
};
