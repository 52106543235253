import styled, { css } from 'styled-components';

import BackgroundSignIn from '../../assets/BackgroundSignIn.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;

  background-color: white;
  padding: 35px;
`;

export const BackgroundImage = styled.div`
  background-image: url(${BackgroundSignIn});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const MainContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 537px;
    margin: 0 auto;
    padding: 44px;
    border-radius: 4px;

    background-color: ${theme.colors.white};
    box-shadow: 0px 82px 438px rgba(0, 0, 0, 0.06);

    > h1 {
      ${theme.texts.titleHeadingLG}
      color: ${theme.colors.black};
      opacity: 0.8;

      margin-bottom: 8px;
    }

    > small {
      ${theme.texts.bodyParagraphMD}

      color: ${theme.colors.primaryBlack00};
      opacity: 0.5;
    }

    > span {
      color: ${theme.colors.black};
      opacity: 0.5;
      ${theme.texts.bodyParagraphSM};
      cursor: pointer;
      margin-top: 24px;

      display: flex;
      justify-content: center;
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    > span {
      padding: 6px 10px;
      margin-left: 18px;

      border-radius: 4px;

      ${theme.texts.alternativeTextXXXS}
      background-color: ${theme.colors.primary10};
      color: ${theme.colors.primary};
    }

    > a {
      margin-left: auto;
      display: flex;
      align-items: center;

      ${theme.texts.alternativeTextXXXS}
      color: ${theme.colors.primary};
      background-color: transparent;

      > svg {
        font-size: 18px;
        margin-left: 6px;
      }
    }
  `};
`;

export const SignInForm = styled.div`
  ${({ theme }) => css`
    margin-top: 32px;
    
    > button {
      width: 100%;
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `}
`;

export const StayConnected = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;  

  ${({ theme }) => css`
    .checkbox {
      background: transparent;
      border: 1px ${theme.colors.primary} solid;
      margin-right: 12px;
    }

    .checkbox--active {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
    }

    > small {
      ${theme.texts.bodyParagraphSM};
      color: ${theme.colors.primaryBlack00}
    }
  `}
`;
