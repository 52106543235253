import React, { ReactElement, useContext, useEffect } from 'react';
import Quagga from '@ericblade/quagga2';

import { RiArrowLeftLine } from 'react-icons/ri';

import { Container, Viewport } from './styles';

import { BillScannerProps } from './BillScanner.spec';
import { OrderRequestContext } from '../RequestModal';

const BillScanner = ({
  onScan = () => undefined,
  onClose = () => undefined,
}: BillScannerProps): ReactElement => {
  const { setScanCode } = useContext(OrderRequestContext);
  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.querySelector('#viewport') as any,
          constraints: {
            facingMode: 'environment',
            width: 800,
            height: 600,
          },
        },
        decoder: {
          readers: ['i2of5_reader'],
        },
        locator: {
          patchSize: 'medium',
          halfSample: true,
        },
        numOfWorkers: 2,
        frequency: 5,
        locate: true,
      },
      (err) => {
        if (err) {
          return;
        }
        Quagga.start();
      },
    );

    Quagga.onDetected((result) => {
      const {
        code,
      } = result.codeResult as any;
      if (code?.length === 44) {
        setScanCode(code);
        onScan?.(code);
      }
    });
    return () => {
      Quagga.stop();
    };
  }, [onScan]);

  return (
    <Container>
      <RiArrowLeftLine onClick={onClose} />
      <Viewport id="viewport" />
      <h4>
        Posicione o código na marcação abaixo
      </h4>
      <button type="button" onClick={onClose}>
        Colar/digitar linha digitável
      </button>
    </Container>
  );
};

export default BillScanner;
