import { Field, Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import BaruLogo from '../../assets/BaruLogo.svg';
import FooterLogo from '../../assets/FooterLogo.svg';

import Button from '../../components/Button';
import { authService } from '../../services';
import { InputControl } from '../../styles/components';
import { ForgotPasswordCore } from '../../types/auth';
import { getErrorMessage } from '../../utils/errorCodes';

import {
  Container,
  BackgroundImage,
  MainContent,
  Header,
  SignInForm,
  Footer,
} from './styles';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const initialValues: ForgotPasswordCore = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  async function handleForgot({
    email,
  }: ForgotPasswordCore): Promise<void> {
    try {
      await authService.forgotPassword({
        email,
      });
      toast.success('E-mail enviado!');
      navigate('/login');
    } catch (err: any) {
      toast.error(getErrorMessage(err.response?.data?.error?.details?.serverCode) || 'Não foi possível realizar operação!');
    }
  }
  return (
    <Container>
      <BackgroundImage>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(state) => {
            handleForgot(state);
          }}
        >
          {() => (
            <MainContent>
              <Header>
                <img src={BaruLogo} alt="" />
                <span>Login</span>
                <Link to="/">
                  Voltar ao site
                  {' '}
                  <FiArrowUpRight />
                </Link>
              </Header>
              <h1>
                Recupere sua senha
              </h1>
              <Form>
                <SignInForm>
                  <InputControl>
                    <small>Seu e-mail</small>
                    <Field
                      name="email"
                      type="email"
                      placeholder="nome@empresa.com.br"
                      autoComplete="email"
                    />
                  </InputControl>
                  <Button
                    type="submit"
                    message="Enviar e-mail de recuperação"
                  />
                </SignInForm>
              </Form>

              <Link to="/login">
                <span>Voltar ao login</span>
              </Link>
              <Footer><img src={FooterLogo} alt="" /></Footer>
            </MainContent>
          )}
        </Formik>
      </BackgroundImage>
    </Container>
  );
};

export default ForgotPassword;
