import styled, { css } from 'styled-components';
import ArrowDownFill from '../../../../assets/ArrowDownFill.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  padding-bottom: 36px;
`;

export const Breadcrumb = styled.section`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      @media (max-width: 480px) {
        display: none;
      }
    }
  `}
`;

export const SelectControl = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    > small {
      color: ${theme.colors.primary};
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.6rem;

      position: absolute;
      left: 18px;
      top: 12px;
    }

    select {
      border: 1px solid ${theme.colors.grey};
      position: relative;
      height: 60px;
      border-radius: 6px;
      padding: 24px 24px 12px 18px;
      color: ${theme.colors.black};
      font-size: 1.4rem;
      width: 100%;
      font-weight: 600;

      background-color: transparent;
      transition: ${theme.transitions.default};
      background: url(${ArrowDownFill}) no-repeat right;
      -webkit-appearance: none;
      background-position-x: calc(100% - 18px);

      &::placeholder {
        color: ${theme.colors.primaryBlack30};
        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: ${theme.colors.black};
      }
    }
  `};
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    @media (max-width: 480px) {
      &:first-child {
        display: none;
      }
    }

    &:first-child {
        margin-right: 16px;
      }

    > svg {
      position: absolute;
      left: 19.5px;
      top: 22.5px;

      font-size: 15px;
    }

    > input {
      width: 100%;
      height: 60px;
      padding-left: 48px;

      font-size: 1.4rem;
      line-height: 2.4rem;

      border: 1px solid ${theme.colors.primaryBlack10};
      border-radius: 6px;

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.primaryBlack30};
        transition: ${theme.transitions.default};
      }

      &:focus {
        border-color: ${theme.colors.black};
      }
    }
  `}
`;

export const Table = styled.div`
  width: 100%;
`;

export const ActionsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    > button {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid ${theme.colors.primaryBlack10};
      background-color: ${theme.colors.white};
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      > svg {
        font-size: 1.2rem;
      }
    }
  `}
`;

export const BtnDelete = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.error} !important;

    > svg {
      color: ${theme.colors.white};
    }
  `}
`;
