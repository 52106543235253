import styled, { css } from 'styled-components';

export const Container = styled.section`
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  min-height: -webkit-fill-available;
  /* height: 100vh; */

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 724px;
  min-height: -webkit-fill-available;
  padding: 24px 32px;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 30px;
  bottom: 30px;

  overflow-y: auto;
  background-color: white;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 480px) {
    padding: 24px 32px 56px;
    top: 0;

    height: 100vh;
    min-height: -webkit-fill-available;
  }

  @media (min-width: 480px) {
    border-radius: 6px;
  }
`;

export const Top = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      font-size: 2.4rem;

      cursor: pointer;
    }

    > a {
      color: ${theme.colors.primaryBlack00};
      > svg {
        font-size: 2.4rem;
      }
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    margin-bottom: 24px;
    > h2 {
      ${theme.texts.titleHeadingLG};
      margin: 24px 0;
    }
  `}
`;

export const TableBody = styled.div`
  @media (max-width: 480px) {
    height: calc(100vh - 235px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const TableItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  cursor: pointer;

  @media (min-width: 481px) {
    justify-content: initial;
  }
`;

export const IconBoleto = styled.span<{ status?: string }>`
  ${({ theme, status }) => css`
    background-color: ${theme.colors.primary10};
    padding: 16px;

    border-radius: 12px;

    > svg {
      font-size: 2.4rem;

      color: ${theme.colors.primary};
    }

    ${status === 'overdue'
    && css`
      background-color: ${theme.colors.primaryBlack10};

      > svg {
        font-size: 2.4rem;

        color: ${theme.colors.primaryBlack00};
      }
    `};
  `}
`;

export const ItemContent = styled.div`
  ${({ theme }) => css`
    width: 54%;
    margin: 0 14px;
    > h2 {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      color: ${theme.colors.primaryBlack00};
    }
    > p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.6rem;

      color: #626262;
    }
  `}
`;
export const ValueBox = styled.div`
  ${({ theme }) => css`
    padding: 5px 10px;

    background-color: #f4f4f4;
    border-radius: 4px;

    @media (min-width: 481px) {
      margin-left: auto;
    }
  `}
`;
