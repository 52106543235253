import styled, { css, keyframes } from 'styled-components';

export const Step = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    position: relative;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }
  `};
`;

export const StepForm = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    }

    > small {
      ${theme.texts.bodyParagraphMD};
    }
  `};
`;

export const InstallmentValueBox = styled.div`
  ${({ theme }) => css`
    padding: 24px 0 24px 24px;
    background-color: ${theme.colors.primary05};
    max-height: 102px;
    height: 100%;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > img {
      border-radius: 0 6px 6px 0;

      @media (max-width: 375px) {
        display: none;
      }
    }
  `};
`;

export const BadgeIcon = styled.span`
  ${({ theme }) => css`
    width: 48px;
    height: 48px;
    background-color: ${theme.colors.primary20};
    margin-right: 18px;
    border-radius: 8px;
    font-size: 1.8rem;
    color: ${theme.colors.primary};

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const InstallmentValueTexts = styled.div`
  ${({ theme }) => css`
    margin-right: auto;
    color: ${theme.colors.primary};

    display: flex;
    flex-direction: column;
    justify-content: center;

    > small {
      ${theme.texts.bodyParagraphSM};
      margin: 0 0 2px 0;
    }

    > b {
      ${theme.texts.bodyParagraphXL};
    }
  `};
`;

export const VerificationLoginModalContent = styled.div<{ isVisible?: boolean }>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 32px;
    border-radius: 24px 24px 0px 0px;
    background-color: ${theme.colors.white};
    transform: translateY(100%);
    transition: transform 2s ease-in-out
    transition-delay: 1s;;
    animation: ${isTranform} 0.4s cubic-bezier(.17,.67,.83,.67) forwards;

    position: absolute;
    bottom: 30px;

    @media(max-width: 480px) {
      bottom: 0;
    }

    > svg {
      font-size: 2.5rem;
      color: ${theme.colors.primaryBlack00};
      cursor: pointer;
      margin-right: auto;
    }

    > h3 {
      color: #1e1e1e;
      letter-spacing: -0.2px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 16px 0;
    }

    > p {
      color: #1e1e1e;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 24px 0;
      max-width: 350px;
      text-align: center;
    }

    > img {
      max-width: 230px;
    }
  `};
`;

const isTranform = keyframes`
  0% { transform: translateY(100%) }
  100% { transform: translateY(0) }
`;

export const VerificationLoginModal = styled.div<{viewMode?: string}>`
  ${({ theme, viewMode }) => css`
    position: fixed;
    top: 30px;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 724px;
    transform: translateX(-50%);
    box-shadow: 0px 50px 50px rgba(86, 224, 78, 0.1);
    background-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    transition: ${theme.transitions.default};

    ${viewMode === 'iFrame'
    && css`
      max-width: 100%;
      top: 0;
      bottom: 0;
      border-radius: 0;

      ${VerificationLoginModalContent} {
        bottom: 0;
      }
    `};

    /* @media(max-width: 480px) {
      top: 0;
    } */
  `};
`;

export const VerificationLoginModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > img {
    max-width: 239px;
    width: 100%;
  }

  > button {
    width: 100%;
    background: #56e04e;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    height: 60px;

    > svg {
      margin: 0 0 0 6px;
    }
  }

  > a {
    margin: 14px 0 0 0;

    > button {
      width: 100%;
      border: 1px solid #d2d2d2;
      background-color: #fff;
      border-radius: 4px;
      color: #1e1e1e;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      height: 60px;

      > svg {
        margin: 0 0 0 6px;
      }
    }
  }
`;

export const IFrameButton = styled.button`
  width: 100%;
  border: 1px solid #d2d2d2 !important;
  background-color: #fff !important;
  border-radius: 4px;
  color: #1e1e1e !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  height: 60px;
  margin: 14px 0 0 0;

  > svg {
    margin: 0 0 0 6px;
  }
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    padding: 2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      ${theme.colors.primary} 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 21.5px;
    height: 21.5px;
    background-color: ${theme.colors.background};
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;
